import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const AdminITR = ({ userId }) => {
  const [itr, setItr] = useState(null);

  const CheckITR = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/admin/ITR`,
        { id: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;
      console.log("ITR>>>>>>>>>>>>>>>>>>>>", data);

      toast.success("Successfully Updated", { theme: "colored" });
    } catch (error) {
      toast.error("User Don't have ITR details", { theme: "colored" });
      console.error("Error fetching verifyAllID details:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");

      try {
        const ITRData = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v1/admin/get-itr`,
          { id: userId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("ITR Data>>>>> ", ITRData.data);

        setItr(ITRData.data);
      } catch (error) {
        console.error("Error fetching verifyAllID details:", error);
      }
    };

    fetchData();
  }, [userId]);

  return (
    <div>
      <div className="mt-2 max-w-2xl bg-white rounded-lg shadow-md border-[#5ada40] border-b-[10px]">
        <div className="p-5 flex flex-col gap-4  bg-slate-900 rounded-lg">
          <div className="flex flex-row gap-2 items-center">
            <div>
              <button
                className="bg-purple-500 hover:bg-purple-600 py-3 px-4 text-white"
                onClick={CheckITR}
              >
                Check ITR
              </button>
            </div>
            <h2 className="font-semibold text-white text-xl">ITR Data</h2>
          </div>

          <div>
            <div className="text-white opacity-0 text-sm">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident
              rem quo asperiores
            </div>
          </div>
        </div>
        <div className="p-4">
          <p className="mt-2 mb-2">
            <strong>ITR Data:</strong>
          </p>
          <div className="p-2 mt-2 overflow-x-auto overflow-y-auto h-[40vh] ">
            <pre>{itr !== null ? JSON.stringify(itr, null, 2) : "No data"}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminITR;
