import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import regStyle from "../core/css/register.module.css";
import Header from "../components/Header";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import logoIC from "../core/images/logoPC.png";
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { Player } from "@lottiefiles/react-lottie-player";
import Footer from "../components/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import DisclaimerModal from "../extras/DisclaimerModel";
import VerifyMobile from "../components/Props/VerifyMobile";

function generateRandomString() {
  const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
  const numbers = "0123456789";
  const specialCharacters = "!@#$%^&*()_-+=<>?";

  const getRandomChar = (characters) =>
    characters[Math.floor(Math.random() * characters.length)];

  // Ensure at least one character from each set
  const randomUppercase = getRandomChar(uppercaseLetters);
  const randomLowercase = getRandomChar(lowercaseLetters);
  const randomNumber = getRandomChar(numbers);
  const randomSpecialChar = getRandomChar(specialCharacters);

  // Generate the remaining characters
  let remainingChars = "";
  for (let i = 0; i < 8; i++) {
    const charSet =
      i % 4 === 0
        ? uppercaseLetters
        : i % 4 === 1
        ? lowercaseLetters
        : i % 4 === 2
        ? numbers
        : specialCharacters;
    remainingChars += getRandomChar(charSet);
  }

  // Concatenate all parts and shuffle the string
  const randomString =
    randomUppercase +
    randomLowercase +
    randomNumber +
    randomSpecialChar +
    remainingChars;

  const shuffledString = randomString
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");

  return shuffledString;
}

const Register = ({ changeView }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [gender, setGender] = useState("m");
  const [captchaToken, setCaptchaToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const generatedString = generateRandomString();
  console.log("randomID>>>> ", generatedString);
  const [data, setData] = useState({
    email: "",
    mobileNumber: "",
    dob: "",
    panCardNo: "KKKKK0000K",
    password: "",
    ip_address: "",
    device: "mobile",
  });
  console.log("here is data", data);

  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token"));

  const navigate = useNavigate();

  useEffect(() => {
    const handleTokenChange = () => {
      setIsLoggedIn(!!localStorage.getItem("token"));
    };
    window.addEventListener("storage", handleTokenChange);
    return () => {
      window.removeEventListener("storage", handleTokenChange);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  }, [isLoggedIn]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    // Validate if the input contains only letters for first name, middle name, and last name
    if (["firstName", "middleName", "lastName"].includes(name)) {
      newValue = value.replace(/[^A-Za-z]/gi, ""); // Replace non-letter characters with empty string
    }

    if (name === "firstName") {
      setFirstName(newValue);
    } else if (name === "middleName") {
      setMiddleName(newValue);
    } else if (name === "lastName") {
      setLastName(newValue);
    } else if (name === "mobileNumber") {
      // Limiting input to 10 characters and allowing only digits
      if (value.length <= 10 && /^\d*$/.test(value)) {
        setData({ ...data, [name]: value });
      }
    } else {
      setData({ ...data, [name]: value });
    }
  };

  //mobileModel
  const [showMobileModal, setShowMobileModal] = useState(false);

  //Disclaimer model
  const [showModal, setShowModal] = useState(false);
  const handleCheckboxChange = (e) => {
    setShowModal(e.target.checked);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onChangeCap = (token) => {
    setCaptchaToken(token);
  };

  const fetchIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when form is submitted

    const fullName = `${firstName} ${middleName} ${lastName}`;
    console.log(fullName);

    if (!captchaToken) {
      toast.error("Please complete the reCAPTCHA");
      setLoading(false); // Stop loading if captcha is not completed
      return;
    }

    let ipAddress = data.ip_address;

    // Fetch IP address if not available
    if (!ipAddress) {
      ipAddress = await fetchIpAddress();
    }

    if (!ipAddress) {
      toast.error("Unable to fetch IP address");
      setLoading(false); // Stop loading if unable to fetch IP address
      return;
    }

    const formattedDob = new Date(data.dob).toISOString().split("T")[0];

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/auth/register`,
        {
          fullName: fullName,
          email: data.email,
          mobileNumber: data.mobileNumber,
          dob: formattedDob,
          gender: gender,
          panCardNo: data.panCardNo,
          userSecret: generatedString,
          password: data.password,
          ip_address: ipAddress,
          device: data.device,
        }
      );
      console.log("Registration response:", response.data);

      // Update state and notify user
      setData({
        email: "",
        mobileNumber: "",
        dob: "",
        panCardNo: "",
        password: "",
        ip_address: ipAddress, // Update the state with the fetched IP address
        device: "",
      });
      setFirstName("");
      setMiddleName("");
      setLastName("");
      toast.success("Signup Successful!");
    } catch (error) {
      console.error("Error Registering User:", error);
      toast.error(`${error.response?.data?.message || "Registration failed"}`);
    } finally {
      setLoading(false); // Stop loading after try/catch
    }
  };

  return (
    <div id="rtlanimation">
      <br />
      <br />
      <br />
      <br />
      <div className="xl:hidden">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <div
        className="
        justify-center relative min-h-screen  xl:bg-transparent
      flex flex-col xl:flex-row items-center xl:min-h-screen"
      >
        <div>
          <ToastContainer theme="colored" toastClassName="#big-toast" />
          <section className="bg-transparent flex flex-row ">
            <div className="flex flex-col  items-center justify-center md:px-6 md:py-4  xl:mx-auto md:h-screen xl:py-0">
              <div
                className="w-full  shadow border md:mt-0 max-w-xl xl:p-0"
                id="glassMorph"
              >
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight  md:text-2xl text-white">
                    Register Your Account
                  </h1>
                  <form
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                    className="space-y-4 md:space-y-6"
                  >
                    {/* FirstName and last and middle */}
                    <div className="flex flex-col md:flex-row justify-between gap-5">
                      <div>
                        <label
                          htmlFor="firstName"
                          className="block mb-2 text-sm font-medium  text-white"
                        >
                          First Name*
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          maxLength={18}
                          placeholder="First Name"
                          value={firstName}
                          onChange={handleChange}
                          className=" border  sm:text-sm rounded-lg  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                          id="glassMorph"
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="middleName"
                          className="block mb-2 text-sm font-medium  text-white"
                        >
                          Middle Name*
                        </label>
                        <input
                          type="text"
                          name="middleName"
                          maxLength={18}
                          placeholder="Middle Name"
                          value={middleName}
                          onChange={handleChange}
                          className=" border  sm:text-sm rounded-lg  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                          id="glassMorph"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="lastName"
                          className="block mb-2 text-sm font-medium  text-white"
                        >
                          Last Name*
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          maxLength={18}
                          placeholder="Last Name"
                          value={lastName}
                          onChange={handleChange}
                          className=" border  sm:text-sm rounded-lg  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                          id="glassMorph"
                        />
                      </div>
                    </div>
                    {/* FirstName and last and middle and Mobile Number */}
                    {/* Email and Password */}
                    <div className="flex flex-row w-full  gap-5">
                      <div className="flex-[1]">
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium  text-white"
                        >
                          Your email*
                        </label>
                        <input
                          type="email"
                          name="email"
                          placeholder="someone@something.com"
                          value={data.email}
                          maxLength={28}
                          onChange={handleChange}
                          className=" border  sm:text-sm rounded-lg  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                          id="glassMorph"
                        />
                      </div>
                      <div className="flex-[1]">
                        <label
                          htmlFor="password"
                          className="block mb-2 text-sm font-medium  text-white"
                        >
                          Password*
                        </label>
                        <input
                          type="password"
                          name="password"
                          placeholder="**************"
                          value={data.password}
                          onChange={handleChange}
                          maxLength={16}
                          className=" border  sm:text-sm rounded-lg  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                          id="glassMorph"
                        />
                      </div>
                      <div className="hidden xl:block flex-[1]">
                        <label
                          htmlhtmlFor="gender" // changed 'for' to 'htmlFor' for accessibility
                          className="block mb-2 text-sm font-medium text-white"
                        >
                          Gender*
                        </label>
                        <select
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                          className="bg-slate-700 text-white border-none shadow-md"
                          id="glassMorph"
                        >
                          <option value="m">Male</option>
                          <option value="f">Female</option>
                        </select>
                      </div>
                    </div>
                    {/* Email and Password */}
                    {/* DOB and Gender and MobileNumber */}
                    <div className="flex flex-col md:flex-row  justify-evenly gap-5">
                      <div className=" xl:hidden">
                        <label
                          htmlFor="gender"
                          className="block mb-2 text-sm font-medium  text-white"
                        >
                          Gender*
                        </label>
                        <select
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                          placeholder="Choose one…"
                          className="bg-slate-700 text-white border-none shadow-md"
                          id="glassMorph"
                        >
                          <option value="m">Male</option>
                          <option value="f">Female</option>
                        </select>
                      </div>
                      <div>
                        <label
                          htmlhtmlFor="mobileNumber"
                          className="block mb-2 text-sm font-medium text-white"
                        >
                          Mobile Number*
                        </label>
                        <div className="flex flex-row gap-2 items-center">
                          <div className=" flex gap-1 flex-row items-center  border rounded-lg  bg-slate-900 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500 py-2 px-3">
                            <p className="text-lg  text-white font-medium">
                              +91
                            </p>
                            <img
                              className="mr-5"
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_India.svg/1280px-Flag_of_India.svg.png?20240117202436"
                              alt="Indian Flag"
                              width={30}
                              height={30}
                            />
                          </div>
                          <input
                            type="text" // Changed to text type to handle numbers and maxLength together
                            name="mobileNumber"
                            placeholder="ex : 7712771277"
                            value={data.mobileNumber}
                            onChange={handleChange}
                            maxLength={10} // Adding maxLength attribute
                            className="border rounded-lg w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                            id="glassMorph"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="dob"
                          className="block mb-2 text-sm font-medium  text-white"
                        >
                          DOB*
                        </label>
                        <input
                          type="date"
                          name="dob"
                          value={data.dob}
                          onChange={handleChange}
                          className=" border  sm:text-sm rounded-lg  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                          id="glassMorph"
                        />
                      </div>
                    </div>
                    {/* DOB and MobileNumber and terms */}
                    {/* terms */}
                    <div className="flex-col flex gap-3">
                      <div className="ml-3 flex flex-row gap-3 text-sm">
                        <div className="flex items-center h-5">
                          <input
                            id="terms glassMorph"
                            aria-describedby="terms"
                            type="checkbox"
                            onChange={handleCheckboxChange}
                            className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                            required
                          />
                        </div>
                        <label
                          htmlFor="terms"
                          className="font-light  text-gray-300"
                        >
                          By continuing, you agree to our
                          <NavLink
                            to="/privacypolicy"
                            className="font-medium text-blue-600  hover:underline text-sm text-primary-500"
                          >
                            {" "}
                            Privacy Policy{" "}
                          </NavLink>
                          and
                          <NavLink
                            to="/termsandconditions"
                            className="font-medium text-blue-600 hover:underline text-sm text-primary-500"
                          >
                            {" "}
                            Terms & Conditions
                          </NavLink>
                          <span>, </span>
                          <NavLink
                            to="/disclaimer"
                            className="font-medium text-blue-600  hover:underline text-sm text-primary-500"
                          >
                            disclaimer{" "}
                          </NavLink>
                          & communicate with you via phone, e-mails, WhatsApp,
                          etc.
                          <div className="flex items-center"></div>
                        </label>
                      </div>
                    </div>

                    {/* terms */}
                    <div className="flex flex-col gap-2">
                      <div className="">
                        <ReCAPTCHA
                          sitekey="6LfxfmYpAAAAAC7uT0TIwkbNDWNiaswXEppsEKpo"
                          onChange={onChangeCap}
                        />
                      </div>
                      <div>
                        {/* <Button
                          variant="contained"
                          onClick={handleMobileOpen}
                          className="w-[100%]"
                        >
                          Verify Mobile
                        </Button> */}
                        <Button
                          variant="contained"
                          type="submit"
                          className={`w-[100%] ${
                            loading
                              ? "bg-gray-400 cursor-not-allowed"
                              : "bg-blue-500 hover:bg-blue-600"
                          }`}
                          disabled={loading} 
                        >
                          {loading
                            ? "Creating Your Account..."
                            : "Create Your Account"}
                        </Button>
                      </div>
                    </div>
                    <div className="text-sm flex flex-row gap-2 font-light text-gray-500 dark:text-gray-400">
                      Already have an account?{" "}
                      <p
                        onClick={() => {
                          changeView(10);
                        }}
                        className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                      >
                        Login here
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div>
              <div className=" flex items-center justify-center h-screen">
                <div className="">
                  <DisclaimerModal
                    showModal={showModal}
                    onClose={handleCloseModal}
                  />
                  {/* <VerifyMobile
                    showModal={showMobileModal}
                    onClose={handleMobileCloseModal}
                  /> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <svg
        className="z-[1]   bottom-[0] left-0 "
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 200"
      >
        <path
          fill="#030712"
          fillOpacity="1"
          d="M0,0L40,42.7C80,85,160,171,240,197.3C320,224,400,192,480,154.7C560,117,640,75,720,74.7C800,75,880,117,960,154.7C1040,192,1120,224,1200,213.3C1280,203,1360,149,1400,122.7L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        ></path>
      </svg>
      <Footer />
    </div>
  );
};

export default Register;
