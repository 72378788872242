import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsEyeglasses } from "react-icons/bs";
import { NavLink } from "react-router-dom";

const TransactionPayOut = ({ data }) => {
  let rows = [];

  if (data) {
    rows = data.map((item, index) => {
      // Added index parameter
      return {
        id: index,
        userID: item.userId,
        transactionId: item.tid,
        amount: item.amount,
        paymentStatus: item.txStatus,
      };
    });
  }

  const columns = [
    {
      field: "User id",
      headerName: "User ID",
      width: 120,
      renderCell: (params) => {
        return (
          <div className="font-regular text-slate-600">{params.row.userID}</div>
        );
      },
    },
    {
      field: "Transaction Id",
      headerName: "Transaction Id",
      width: 260,
      renderCell: (params) => {
        return (
          <div className="font-regular text-slate-800">
            {params.row.transactionId}
          </div>
        );
      },
    },
    {
      field: "Amount",
      headerName: "Amount",
      width: 180,
      renderCell: (params) => {
        return (
          <div className="font-regular text-slate-800">{params.row.amount}</div>
        );
      },
    },
    {
      field: "Payment Status",
      headerName: "Payment Status",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="font-regular text-slate-800">
            {params.row.paymentStatus}
          </div>
        );
      },
    },
  ];

  return (
    <div className="shadow-lg mt-2 overflow-hidden p-2 rounded-lg">
      <div className="">
        <DataGrid
          rows={rows}
          columns={columns}
          rowKey={(row) => row.id} // Adding unique key for each row
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 7 },
            },
          }}
          pageSizeOptions={[5, 20]}
          disableColumnSelector
          disableRowSelectionOnClick
        />
      </div>
    </div>
  );
};

export default TransactionPayOut;
