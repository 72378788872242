// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#animation_mainNavbar__yA-es{
    animation: animation_animateBg__HJF\\+h 14s linear infinite;
    background-image: linear-gradient(90deg,#00f3ff,#0089f7,#00326b,#125eb6);
    background-size: 300% 100%;
}
  @keyframes animation_animateBg__HJF\\+h {
    0% { background-position: 0% 0%; }
    100% { background-position: 100% 0%; }
  }`, "",{"version":3,"sources":["webpack://./src/core/css/animation.module.css"],"names":[],"mappings":"AAAA;IACI,0DAAwC;IACxC,wEAAwE;IACxE,0BAA0B;AAC9B;EACE;IACE,KAAK,0BAA0B,EAAE;IACjC,OAAO,4BAA4B,EAAE;EACvC","sourcesContent":["#mainNavbar{\n    animation: animateBg 14s linear infinite;\n    background-image: linear-gradient(90deg,#00f3ff,#0089f7,#00326b,#125eb6);\n    background-size: 300% 100%;\n}\n  @keyframes animateBg {\n    0% { background-position: 0% 0%; }\n    100% { background-position: 100% 0%; }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainNavbar": `animation_mainNavbar__yA-es`,
	"animateBg": `animation_animateBg__HJF+h`
};
export default ___CSS_LOADER_EXPORT___;
