import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Assuming you're using React Router
import { jwtDecode } from "jwt-decode";
import axios from "axios"; // Assuming correct file path
import ChartsOverviewDemo from "./AdminCart";
import UserProfileImage from "../../../core/images/User.gif";
import styles from "../../../core/css/animation.module.css";

const AdminPage = () => {
  const [userId, setUserId] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return;
    }

    const token = localStorage.getItem("token");
    const decodeToken = jwtDecode(token);
    const userId = decodeToken.id || decodeToken.sub;
    setUserId(userId);

    axios
      .get(`${process.env.REACT_APP_API_KEY}/v1/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUserDetails(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      {/* Gradient  */}
      <div
        className="z-[-1] absolute top-0 left-0 w-[100%] h-[200px]"
        id={styles.mainNavbar}
      ></div>
      {/* End Of Gradient */}
      <br />
      <div className="mt-10 max-w-5xl mx-auto bg-white shadow-md rounded-lg ">
        <div className="flex flex-col items-center justify-center lg:flex-row">
          <div className="p-8">
            <div className="uppercase text-2xl tracking-wide text-indigo-500 font-semibold">
              Hi {userDetails?.fullName}
            </div>
            <p className="">
              {userDetails?.gender === "m" && "Gender : Male"}
              {userDetails?.gender === "f" && "Gender : Female"}
              {!userDetails?.gender && "Gender not specified"}
            </p>
            <p className="text-gray-700">Email: {userDetails?.email}</p>
            <p className="text-gray-700">Mobile: {userDetails?.mobileNumber}</p>
          </div>
          <div className="">
            <img
              className="w-full object-cover md:w-64"
              src={UserProfileImage}
              alt="User Avatar"
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div>
        {/* ChartsOverviewDemo Component */}
        {/* <ChartsOverviewDemo /> */}
      </div>
    </div>
  );
};

export default AdminPage;
