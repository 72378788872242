import React, { useState, useEffect, useRef } from "react";
import "../../core/css/home.module.css";
import Header from "../Header";
import homeStyle from "../../core/css/home.module.css";
import Footer from "../Footer";
import Home3 from "./Home3";
import SignUpRoute from "../SignUpRoute";
import Home2 from "./Home2";
import { LoanCalculator } from "../LoanCal";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Home4 from "./Home4";
const MainHome = () => {
  const texts = ["Personal Credit", "Instant Credit", "PlentyCred EMIS"];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 1500);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [texts.length]);

  useEffect(() => {
    document.title = "Welcome To Plenty-Cred | Finance Anywhere, Anytime";
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const formattedDate = `${day}-${month}-${year}`;
        setCurrentDate(formattedDate);

        const response = await axios.get("https://api.ipify.org?format=json");
        const ipAddress1 = response.data.ip;


        // TODO Setup counter properly

        // const counterSend = await axios.post(
        //   `${process.env.REACT_APP_API_KEY}/v1/counter/counter`,
        //   {
        //     ip_address: ipAddress1,
        //     date: formattedDate,
        //   }
        // );

        // const counterSendData = counterSend.data;
      } catch (error) {
        console.log("Error while counting data>> ", error);
      }
    };

    fetchData();
  }, []);

  const divVariants = {
    hidden: { opacity: 0, y: -100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  return (
    <>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={divVariants}
        className="mb-[100px] bg-white"
        id="wavyeffect"
      >
        <div className="pt-[200px] pb-[250px] justify-center items-center flex flex-col gap-6 bg-[#040826]">
          <center>
            <h1 className="text-4xl xl:text-6xl font-semibold text-white">
              Lightning-fast upgrades with
            </h1>
          </center>
          <center>
            <h1 className="text-[#0083ec] text-3xl xl:text-6xl font-semibold">
              {texts[currentTextIndex]}
            </h1>
          </center>
          <center>
            <p className="text-base xl:text-2xl text-white">
              PlentyCred - finance simplified unlike ever before!
            </p>
          </center>
          <div className="p-4 text-center flex-col hidden xl:flex gap-2 text-base xl:justify-around items-center lg:flex-row">
            <p className="text-xl font-semibold text-[#11c4be]">
              Cash-in-bank in minutes
            </p>
            <span
              className="font-light text-[20px] text-gray-300
            hidden lg:inline-block"
            >
              |
            </span>
            <p className="text-xl font-semibold text-[#e69138]">
              Fastest credits processing
            </p>
            <span className="font-light text-[20px] text-gray-300 hidden lg:inline-block">
              |
            </span>
            <p className="text-xl font-semibold text-[#ff5c5c]">
              Borrow and repay at your convenience
            </p>
            <span className="font-light text-[20px] text-gray-300 hidden lg:inline-block">
              |
            </span>
            <p className="text-xl font-semibold text-[#11c4be]">
              One-time application for multiple credits
            </p>
          </div>
          <div className=" p-4 flex-col flex text-center text-lg xl:hidden gap-2 items-center justify-center">
            <p className=" font-semibold text-[#11c4be]">
              Cash-in-bank in minutes
            </p>
            <p className=" font-semibold text-[#e69138]">
              Fastest credits processing
            </p>
            <p className=" font-semibold text-[#ff5c5c]">
              Borrow and repay at your convenience
            </p>
            <p className="font-semibold text-[#11c4be]">
              One-time application for multiple credits
            </p>
          </div>
          <form className="flex p-2 flex-col xl:flex-row gap-5 xl:gap-2">
            <div>
              <div className=" hidden xl:flex gap-1 flex-row items-center  border rounded-lg  bg-slate-950 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500 py-4 px-2">
                <p className="text-lg  text-white font-medium">+91</p>
                <img
                  className="mr-5"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_India.svg/1280px-Flag_of_India.svg.png?20240117202436"
                  alt="Indian Flag"
                  width={30}
                  height={30}
                />
              </div>
            </div>
            <div className="flex flex-row gap-2">
              <div className=" xl:hidden flex gap-1 flex-row items-center  border rounded-lg  bg-slate-950 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500 py-4 px-2">
                <p className="text-lg  text-white font-medium">+91</p>
                <img
                  className="mr-5"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_India.svg/1280px-Flag_of_India.svg.png?20240117202436"
                  alt="Indian Flag"
                  width={30}
                  height={30}
                />
              </div>
              <input
                placeholder="Enter your mobile number"
                name=""
                maxLength={10}
                className="transition p-4 rounded-lg w-full border-solid border-[2px] border-gray-800 bg-gray-950 focus:bg-gray-800 text-white outline-none"
              />
            </div>

            <NavLink
              to={"/signup"}
              className="p-4 bg-[#0083ec] text-white rounded-lg"
            >
              Apply Now!
            </NavLink>
          </form>
        </div>
      </motion.div>
      <div>
        <Home2 />
      </div>
      <br />
      <br />
      <div>
        <LoanCalculator />
      </div>
      <div id={homeStyle.wrapper}>
        <br />
        <div></div>
        <br />
        <div>
          <Home3 />
        </div>
        <div>
          <Home4 />
        </div>
      </div>
      <SignUpRoute />
      <Footer />
    </>
  );
};
export default MainHome;
