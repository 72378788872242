import React, { useState } from "react";

function TestLab() {
  const [amount, setAmount] = useState('');
  const [percentage] = useState(10);
  const [NACH] = useState(10);

  const calculateTotalPayback = (month) => {
    const totalInterest = (amount * percentage * month) / 100;
    const totalCost = parseFloat(amount) + totalInterest + NACH;
    return {
      totalInterest: totalInterest.toFixed(2),
      totalCost: totalCost.toFixed(2)
    };
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  return (
    <div className="container mx-auto flex justify-center items-center h-screen">
      <div className="w-full sm:w-96">
        <label className="block mb-4">
          Enter Amount:
          <input type="number" value={amount} onChange={handleAmountChange} className="block mt-1 w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
        </label>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  EMI - Plan
                </th>
                <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Interest
                </th>
                <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total Cost
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {[1, 2, 3, 4, 5, 6, 7].map(month => {
                const { totalInterest, totalCost } = calculateTotalPayback(month);
                return (
                  <tr key={month}>
                    <td className="px-4 py-2 whitespace-nowrap">
                      { `₹${amount} - ( ${month} months)`}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      {totalInterest}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      {totalCost}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
      </div>
    </div>
  );
}

export default TestLab;
