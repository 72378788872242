import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import bbpsi from "../../core/images/bbps.gif";
import icon1 from "../../core/images/1icon.png";
import icon2 from "../../core/images/2icon.png";
import icon3 from "../../core/images/3icon.png";
import icon4 from "../../core/images/4icon.png";
import icon5 from "../../core/images/5icon.png";
import icon6 from "../../core/images/6icon.png";
import sectionArea from "../../core/images/sectionArea.png";
import stockArea from "../../core/images/calculate.png";

export default function BBPS() {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div>
      <br />
      <div id="wrapper">
        <br />
        <div className="mt-12 rounded-[20px] bg-[rgb(220,255,245)] bg-[linear-gradient(90deg,_rgba(220,255,245,1)_0%,_rgba(255,255,255,1)_24%,_rgba(255,254,189,1)_48%,_rgba(255,232,172,1)_73%,_rgba(255,188,188,1)_100%)] p-10 flex flex-wrap justify-end flex-col gap-10 lg:flex-row items-center">
          <motion.div
            ref={ref}
            initial="hidden"
            animate="visible"
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: -100 },
            }}
            transition={{ duration: 1.3 }}
            className="flex-[1] flex flex-col"
          >
            <h1 className="text-4xl lg:text-6xl font-semibold">
              Bharat Bill Payment System
            </h1>
            <p className="mt-5 text-sm lg:text-2xl">
              BBPS stands for Bharat Bill Payment System. The Bharat bill
              payment system is conceptualized system driven by National
              Payments Corporation of India (NPCI).
            </p>
            <br />
            <br />
            <Link
              to="https://recharge.plentycred.co.in/"
              className="transition bg-blue-600 text-white text-2xl font-semibold p-2  xl:py-4 xl:px-8 rounded-lg mb-2 max-w-[390px] hover:bg-blue-800"
            >
              Pay Your Bills Now!
            </Link>
            <br />
            <div to="/" className="font-semibold block mt-10">
              Find Out More <FontAwesomeIcon icon={faArrowDown} />
            </div>
          </motion.div>
          <motion.div
            ref={ref}
            initial="hidden"
            animate="visible"
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: -100 },
            }}
            transition={{ duration: 1.3 }}
            className="flex-[1]"
          >
            <center>
              <img src={bbpsi} alt="BBPS" />
            </center>
          </motion.div>
        </div>
        <div className="flex gap-10 justify-between items-center mt-4 flex-col lg:flex-row">
          <div className="w-full lg:flex-[1] lg:w-0 bg-white rounded-lg shadow-lg p-4 border-b-[4px] border-b-blue-600">
            <center>
              <img src={icon1} alt="Icon" className="w-[100px]" />
              <br />
              <p className="font-semibold">Landline Bills</p>
            </center>
          </div>
          <div className="w-full lg:flex-[1] lg:w-0 bg-white rounded-lg shadow-lg p-4 border-b-[4px] border-b-orange-500">
            <center>
              <img src={icon2} alt="Icon" className="w-[100px]" />
              <br />
              <p className="font-semibold">Electricity Bills</p>
            </center>
          </div>
          <div className="w-full lg:flex-[1] lg:w-0 bg-white rounded-lg shadow-lg p-4 border-b-[4px] border-b-red-500">
            <center>
              <img src={icon3} alt="Icon" className="w-[100px]" />
              <br />
              <p className="font-semibold">Gas Bills</p>
            </center>
          </div>
          <div className="w-full lg:flex-[1] lg:w-0 bg-white rounded-lg shadow-lg p-4 border-b-[4px] border-b-blue-400">
            <center>
              <img src={icon4} alt="Icon" className="w-[100px]" />
              <br />
              <p className="font-semibold">Water Bills</p>
            </center>
          </div>
          <div className="w-full lg:flex-[1] lg:w-0 bg-white rounded-lg shadow-lg p-4 border-b-[4px] border-b-blue-400">
            <center>
              <img src={icon5} alt="Icon" className="w-[100px]" />
              <br />
              <p className="font-semibold">Mobile Postpaid</p>
            </center>
          </div>
          <div className="w-full lg:flex-[1] lg:w-0 bg-white rounded-lg shadow-lg p-4 border-b-[4px] border-b-blue-950">
            <center>
              <img src={icon6} alt="Icon" className="w-[100px]" />
              <br />
              <p className="font-semibold">Datacard</p>
            </center>
          </div>
        </div>
        <section className="mt-20 mb-10">
          <div className="flex flex-col  xl:flex-row gap-4">
            <motion.div
              className="flex-[1.5] bg-gray-200 p-6 rounded-xl bg-right-bottom bg-no-repeat"
              style={{
                backgroundImage: `url(${sectionArea})`,
                backgroundSize: `180px`,
              }}
            >
              <h1 className="text-lg font-semibold">
                Benefits Of (BBPS) Bharat Bill Payment System
              </h1>
              <br />
              <p className="text-[12.5px] font-semibold leading-6">
                &bull; BBPS system accept all kind of bill payment across India.
                <br />
                &bull; Utility service suppliers would be ready to get payments
                instantly and replicate instantly.
                <br />
                &bull; Payments would be created firmly through the NPCI network
                with instant receipts obtaining generated.
                <br />
                &bull; Agents or client will check their bill detail by
                submitting bill variety in BBPS system.
                <br />
                &bull; Customer receive instant confirmation over register
                variety or email.
              </p>
              <br />
            </motion.div>
            <motion.div
              className="flex-[1] text bg-gray-200 p-4 rounded-xl pr-40 bg-right-bottom bg-no-repeat"
              style={{
                backgroundImage: `url(${stockArea})`,
                backgroundSize: `140px`,
              }}
            >
              <div className="p-2">
                <h1 className="text-lg font-semibold">
                  Bharat Bill Payment System may be a unified bill payment
                  system for India.
                </h1>
                <br />
                <p className="text-justify text-gray-800 text-sm font-semibold">
                  The BBPS intends to supply all kinds of bill categories and
                  accessible bill payment services to customers through a
                  network of agents, and enabling multiple payment modes, and
                  providing instant confirmation of payment. Bharat Bill
                  Payments System is associate integrated on-line platform under
                  the National Payments Corporation of India (NPCI) for every
                  kind of bill payments. This BBPS service can run through a
                  network of agents and on-line modes to modify payment of bills
                  via multiple payment modes beside instant generation of
                  receipts of payments. To begin with, the scope of BBPS can
                  cover the bills of utility service corporations.
                </p>
              </div>
            </motion.div>
          </div>
        </section>
      </div>
    </div>
  );
}
