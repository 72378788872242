import React, { useEffect, useState } from "react";
import axios from "axios";

const AdminAA = ({ userId }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Get token from localStorage
  const token = typeof window !== "undefined" ? localStorage.getItem("token") : null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_KEY}/v1/admin/get-aa-data/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setData(response.data.data.AAData);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load data");
      } finally {
        setLoading(false);
      }
    };

    if (userId && token) {
      fetchData();
    }
  }, [userId, token]);

  // Render the component
  return (
    <div className="mt-2 max-w-2xl bg-white rounded-lg shadow-md border-[#5ada40] border-b-[10px] p-4 overflow-auto">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div>
          <h2 className="text-xl font-semibold">Admin AA Data</h2>
          <pre className="mt-2">{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default AdminAA;
