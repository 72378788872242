import React from "react";
import policyStyle from "../core/css/tandc.module.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import {
  faFacebook,
  faInstagram,
  faThreads,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Disclaimer = () => {
  return (
    <>
      <Header />
      <div className={policyStyle.container}>
        <div className={policyStyle.upperContainerLink}>
          <NavLink
            onClick={() => window.location.reload()}
            className={policyStyle.linkStyle}
          >
            Home &gt;{" "}
          </NavLink>
          <h3>Disclaimer</h3>
        </div>
        <div className={policyStyle.upperContainer}>
          <h1>Plentycred Disclaimer</h1>
        </div>

        <div id="wrapper">
          <div className="flex flex-row items-center">
            <NavLink to="#" className="transition hover:text-gray-600">
              <FontAwesomeIcon
                icon={faFacebook}
                size={30}
                color="blue"
                className="pr-2"
              />
            </NavLink>
            <NavLink
              to="https://www.instagram.com/plenty_cred/"
              className="transition hover:text-gray-600"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                size={30}
                color="purple"
                className="pr-2"
              />
            </NavLink>
            <NavLink
              to="https://www.threads.net/@plenty_cred"
              className="transition hover:text-gray-600"
            >
              <FontAwesomeIcon icon={faThreads} size={30} className="pr-2" />
            </NavLink>
            <NavLink
              to="https://www.youtube.com/channel/UCYqaLhxr4j7C-_dCe49Hfiw"
              className="transition hover:text-gray-600"
            >
              <FontAwesomeIcon
                icon={faYoutube}
                size={30}
                color="red"
                className="pr-2"
              />
            </NavLink>
          </div>
          <br />
          <br />
          <div className={policyStyle.middleContainer}>
            <h2>
              Owner for plentycred is a registered money lender with the state
              government of Maharashtra. WE ARE STRICTLY LIMITED ONLY TO NAGPUR
              (URBAN) JURISDICATION FOR ALL OUR BUSINESS ACTIVITIES. We are
              strictly abided to the laws of Maharashtra money lending act 2014
              for all lending activities.
            </h2>
            <p>
              Client can request and ask us to cancel the services as well as
              delete all the data colllected at any point of time by sending us
              a request through email 24/7. Request will be responded within 24
              HRS.
            </p>
            <h3>
              No information on this Website/Application should be construed as
              an offer, advertisement, prospectus, inducement, invitation or
              solicitation of an offer for purchase or sale of any
              products/services/securities7/instruments etc. of plentycred You
              are advised to independently assess and take your own independent
              decisions without being influenced by the views and opinions of
              the authors. Investments in financial
              products/services/securities/instruments inherently involve risks
              and you may consult your legal, tax and financial advisors before
              investing. Past performance is not a guide/ indicative of future
              returns and may not necessarily provide a basis for comparison
              with other investments. The value of investments and income from
              them may go down as well as up and an investor may not get back
              the amount invested. Reference to individual companies or other
              investments is included solely for the purpose of illustration and
              should not be taken as a recommendation.
            </h3>
            <h3>
              No representations or warranties are made (express or implied) as
              to the reliability, accuracy or completeness of information
              appearing on this Website/Application; PlentyCred cannot be held
              liable for any loss arising directly or indirectly from the use
              of, or any action taken in or on, any information appearing on
              this Website/Application. Plentycred does not testify/confirm the
              authenticity of the data mentioned in this Website/Application and
              you are strictly advised to rely on your own independent
              investigations and take your own professional advice.
            </h3>
            <h3>
              RBI Disclaimer The Reserve Bank of India does not accept any
              responsibility or guarantee about the present position as to the
              financial soundness of plentycred or for the correctness of any
              statements or representations made or opinions expressed by
              plentycred and for discharge of its liability.
            </h3>
            <h2>
              To the fullest extent permitted by law, Plentycred, its
              affiliates, and each of their respective officers, directors,
              members, employees, and agents (“Plentycred”) NEVER ask you or any
              individual to share their personal or financial details for the
              purpose of any investments, trading, employment or any other
              related or incidental activity. It has recently come to our
              attention that some unknown individuals are reaching out to
              gullible people through WhatsApp, Telegram & other platforms
              representing to be authorised personals of Plentycred and
              deceitfully offering unauthorized investment/employment
              opportunities. Kindly note that these individuals are merely
              imposters & not authorised by Plentycred to do any such act.
              Please do not share your login credentials, debit/credit card
              number, CVV number, OTP, UPI, ATM pin, bank account details or
              transfer any monies to these individuals.
            </h2>
            <h3>
              While we are pursuing legal recourse against these individuals,
              however, we advise you to exercise caution in sharing any
              information online and file a complaint with the authorities, if
              you come across any such instance. Plentycred disclaims
              responsibility for losses resulting from unauthorized information
              sharing or disclosure on platforms like WhatsApp or Telegram, and
              is not liable for any decisions or actions taken based on such
              content.
            </h3>
            <h5>
              For assistance, please go on contact us page and write to us at
              assist@plentycred.co.in Kindly note that here is no other help
              channel available
            </h5>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Disclaimer;
