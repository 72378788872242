import React, { useEffect } from "react";
import aStyle from "../core/css/about.module.css";
import { NavLink, useLocation } from "react-router-dom";
import Header from "../components/Header";
import { Player } from "@lottiefiles/react-lottie-player";
import AboutAll from "../components/About/About2";
import Footer from "../components/Footer";
import SignUpRoute from "../components/SignUpRoute";
import rocketImg from "../core/images/mission2.png";
import visionImg from "../core/images/vision1.png";
import About3 from "../components/About/About3";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import About3Mobile from "../components/About/About3Mobile";
import AboutTable from "../components/About/ElTable";
import CardSlider from "../components/Extracom/CardSlider";
import CardAutoSlider from "../components/Extracom/CardAutoSlider";

const About = () => {
  const controls = useAnimation();
  const location = useLocation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <>
      {location.pathname === "/about" ? (
        <>
          <Header />
        </>
      ) : (
        <></>
      )}
      <br />
      <br />
      <div id="wrapper">
        <div className="mt-12 rounded-[20px] bg-[rgb(220,255,245)] bg-[linear-gradient(90deg,_rgba(220,255,245,1)_0%,_rgba(255,255,255,1)_24%,_rgba(255,254,189,1)_48%,_rgba(255,232,172,1)_73%,_rgba(255,188,188,1)_100%)] p-10 flex flex-wrap justify-end flex-col gap-10 lg:flex-row items-center">
          <motion.div
            ref={ref}
            initial="hidden"
            animate="visible"
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: -100 },
            }}
            transition={{ duration: 1.3 }}
            className="flex-[1]"
          >
            <h1 className="text-4xl font-semibold">
              Our Five founding principles are:
            </h1>
            <p className="mt-5 text-xl lg:text-2xl">
              Empathy-driven startup offering solutions for financial gaps,
              dine-out struggles, affordable gifting, and small loans to ensure
              professionals avoid that sinking feeling.
            </p>
            <br />
            <NavLink
              to="/"
              className="transition bg-gray-800 px-4 py-3 rounded-lg text-white font-semibold inline-block mt-2 hover:bg-white hover:text-gray-950"
            >
              Check FAQ
            </NavLink>
            <NavLink to="/" className="font-semibold block mt-10">
              Find Out More <FontAwesomeIcon icon={faArrowDown} />
            </NavLink>
          </motion.div>
          <motion.div
            ref={ref}
            initial="hidden"
            animate="visible"
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: -100 },
            }}
            transition={{ duration: 1.3 }}
            className="flex-[1]"
          >
            <Player
              autoplay
              loop
              src="https://lottie.host/d8869472-c1aa-408f-ad8a-8e39d2a413b0/GBMey4ekz3.json"
              style={{ height: "360px", width: "100%" }}
            ></Player>
          </motion.div>
        </div>
      </div>
      <motion.div
        ref={ref}
        initial="hidden"
        animate="visible"
        className=""
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1.3 }}
      >
        <AboutAll />
      </motion.div>
      <div className="hidden lg:block">
        <About3 />
      </div>
      <div className="lg:hidden">
        <About3Mobile />
      </div>
      {/* <div className="mt-14 flex items-center justify-center">
        <div className=" max-w-[1300px]">
        <AboutTable />
        </div>
      </div> */}
      <div className="mt-10">
        <CardSlider />
      </div>
      <div className="mt-10">
        <CardAutoSlider />
      </div>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 150 },
        }}
        transition={{ duration: 1.3 }}
        className="bg-blue-100 pt-[10px] pb-1 mt-10"
      >
        <div className="mt-10 mb-10 justify-center flex flex-col lg:flex-row gap-16 items-center">
          <div className="max-w-[500px] ">
            <div className="flex p-4 flex-col justify-center items-center gap-10">
              <div className="flex p-4 flex-col text-center justify-center items-center">
                <img
                  src={visionImg}
                  className="w-60 mb-20 rounded-lg drop-shadow-2xl"
                  alt="vision"
                />
                <h1 className="font-semibold">Our Vision</h1>
                <p>
                  Driving global financial transformation, breaking barriers,
                  empowering individuals, fostering a community of financial
                  independence.
                </p>
              </div>
            </div>
          </div>
          <div className="max-w-[500px]">
            <div className="flex p-2 lg:p-4 flex-col justify-center items-center gap-10">
              <img
                src={rocketImg}
                className="w-60 mb-8 mt-8 h-[11.5em] rounded-lg drop-shadow-2xl"
                alt="rocket"
              />
              <div className="flex p-4 flex-col text-center justify-center items-center">
                <h1 className="font-semibold">Our Mission</h1>
                <p>
                  Democratizing lending with a simplified, transparent process
                  for all, providing competitive and affordable solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      <SignUpRoute />
      <Footer />
    </>
  );
};

export default About;
