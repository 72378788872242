import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { motion } from "framer-motion";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useInView } from "react-intersection-observer";
import educateImg from "../core/images/contact.png";
import ContactForm from "../components/ContactForm";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";

const Contact = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <>
      <br /><br />
      <div id="wrapper">
      <div className="mt-12 rounded-[20px] bg-[rgb(220,255,245)] bg-[linear-gradient(90deg,_rgba(220,255,245,1)_0%,_rgba(255,255,255,1)_24%,_rgba(255,254,189,1)_48%,_rgba(255,232,172,1)_73%,_rgba(255,188,188,1)_100%)] p-10 flex flex-wrap justify-end flex-col gap-10 lg:flex-row items-center">
          <motion.div
            ref={ref}
            initial="hidden"
            animate="visible"
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: -100 },
            }}
            transition={{ duration: 1.3 }}
            className="flex-[1]"
          >
            <h1 className="text-4xl lg:text-6xl font-semibold">
            Got a question? We're here to help!
            </h1>
            <p className="mt-5 text-sm lg:text-2xl">
            Feel free to reach out with any inquiries or feedback. We look forward to hearing from you!
            </p>
            <br />
            <div to="/" className="font-semibold block mt-10">
              Find Out More <FontAwesomeIcon icon={faArrowDown} />
            </div>
          </motion.div>
          <motion.div
            ref={ref}
            initial="hidden"
            animate="visible"
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: -100 },
            }}
            transition={{ duration: 1.3 }}
            className="flex-[1]"
          >
            <iframe width="100%" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=350&amp;hl=en&amp;q=505%20near%20traffic%20park%20dharampeth%20extension%20nagpur%C2%A0440010+(PlentyCred)&amp;t=p&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" className="rounded-lg"></iframe>
          </motion.div>
        </div>
            <motion.div className="flex flex-col xl:flex-row p-3 lg:p-0 gap-10 mt-10">
                <div className="flex-[1]">
                <motion.div
        className="flex flex-col flex-wrap gap-5"
        initial="hidden"
        animate="visible"
      >
        <motion.div className="flex justify-center items-center flex-col bg-white p-10 shadow-lg rounded-lg
        border-[#f1c232] border-b-[10px]">
          <h1 className="text-4xl font-semibold">Mobile</h1>
          <p>+91-7058111852</p>
        </motion.div>
        <motion.div className="flex justify-center items-center flex-col bg-white p-10 shadow-lg rounded-lg
        border-[#ff5c5c] border-b-[10px]">
          <h1 className="text-4xl font-semibold">Email</h1>
          <p>assist@plentycred.co.in</p>
        </motion.div>
        <motion.div className="flex justify-center items-center flex-col bg-white p-10 shadow-lg rounded-lg
        border-[green] border-b-[10px]">
          <h1 className="text-4xl font-semibold">Address</h1>
          <NavLink
            to={"/faq"}
            className="text-black cursor-pointer font-semibold no-underline"
          >
            <center>505 near traffic park dharampeth extension nagpur 440010.</center>
          </NavLink>
        </motion.div>
        <motion.div className="flex justify-center items-center flex-col bg-white p-10 shadow-lg rounded-lg
        border-[#11c4be] border-b-[10px]">
          <h1 className="text-4xl font-semibold">FAQ</h1>
          <NavLink
            to={"/faq"}
            className="text-blue-600 cursor-pointer font-semibold no-underline"
          >
            Visit FAQ's
          </NavLink>
        </motion.div>
      </motion.div>
                </div>
                <div className="flex-[1]">
                  <ContactForm />
                </div>
            </motion.div>
          </div>
          <svg
        className="z-[1] bottom-0 left-0 "
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 230"
      >
        <path
          fill="#030712"
          fillOpacity="1"
          d="M0,0L40,42.7C80,85,160,171,240,197.3C320,224,400,192,480,154.7C560,117,640,75,720,74.7C800,75,880,117,960,154.7C1040,192,1120,224,1200,213.3C1280,203,1360,149,1400,122.7L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        ></path>
      </svg>
      <Footer />
    </>
  );
};

export default Contact;
