// TransactionMain.js
import React, { useState } from "react";
import HeaderTransaction from "./TransactionHeader";
import TransactionPayIn from "./TransactionPayIn";
import TransactionPayOut from "./TransactionPayOut";
import TransactionDebit from "./TransactionDebit";
import { useEffect } from "react";
import axios from "axios";

const TransactionMain = () => {
  const [payinData, setPayinData] = useState(null);
  const [payOutData, setPayOutData] = useState(null);
  const [debitData, setDebitData] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };

        // Fetching payin data
        const payinResponse = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v1/payHistory/get-PayinHistory`,
          {},
          {
            headers: headers,
          }
        );

        setPayinData(payinResponse.data.PayInHistory);

        // Fetching payout data
        const payoutResponse = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v1/payHistory/get-PayoutHistory`,
          {},
          {
            headers: headers,
          }
        );
        setPayOutData(payoutResponse.data.PayoutHistory);

        // Fetching debit data
        const debitResponse = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v1/payHistory/get-DebitPresent`,
          {},
          {
            headers: headers,
          }
        );
        setDebitData(debitResponse.data.debitPresentationHistory);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  console.log("Data>>>>", {
    payinData,
    payOutData,
    debitData,
  });

  const TabContent1 = () => (
    <div className="p-4">
      <TransactionPayIn data={payinData} />
    </div>
  );
  const TabContent2 = () => (
    <div className="p-4">
      <TransactionPayOut data={payOutData} />
    </div>
  );
  const TabContent3 = () => (
    <div className="p-4">
      <TransactionDebit data={debitData} />
    </div>
  );

  const [activeTab, setActiveTab] = useState(1);

  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return <TabContent1 />;
      case 2:
        return <TabContent2 />;
      case 3:
        return <TabContent3 />;
      default:
        return null;
    }
  };

  return (
    <>
    <div className="flex justify-center items-center h-screen p-10">
      
      <div className="max-w-[1100px] w-full">
        <h1 className="font-semibold text-5xl text-white">Transactions</h1>
        <div className="bg-white rounded-lg shadow-lg p-8 mt-10">
        <HeaderTransaction setActiveTab={setActiveTab} />
        {renderTabContent()}
      </div>
      </div>
      <div className="fixed w-full h-full top-0 left-0 z-[-10]" id="DASHbackground5">
      </div>
    </div>
    </>
  );
};

export default TransactionMain;
