import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import admin2 from "../core/images/admin2.gif";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const Aadhar1 = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    aadharNo: "",
    uanNo: "",
    consumerId: "",
    drivingLicense: "",
    bankAccountNo: "",
    ifscCode: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const isNumberField =
      name === "aadharNo" || name === "uanNo" || name === "bankAccountNo";
    const isValidNumber = /^\d*$/.test(value);

    if (isNumberField && isValidNumber) {
      const maxLength = name === "bankAccountNo" ? 18 : 12;
      if (value.length <= maxLength) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  const kycConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const notify = (message, type = "success") =>
    toast(message, { theme: "colored", type });

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_API_KEY}/v2/kyc/add-kyc`,
        formData,
        kycConfig
      );

      notify("Posted Successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
      notify(`Enter valid KYC details: ${error.message}`, "error");
    } finally {
      setLoading(false);
    }
  };

  const renderInput = (
    id,
    label,
    type = "text",
    maxLength,
    validationLength
  ) => (
    <div className="flex-[1]">
      <span className="text-xs text-white">{label}</span>
      <br />
      <input
        id={id}
        name={id}
        type={type}
        value={formData[id]}
        onChange={handleChange}
        maxLength={maxLength}
        className={`py-2 px-4 w-full bg-gray-950 focus:bg-gray-900 text-sm border-gray-950 border-[1px] text-white rounded-sm ${
          formData[id].length >= validationLength
            ? "border-green-500 focus:border-green-500 border-[1px]"
            : formData[id].length === 0
            ? ""
            : "border-red-600 focus:border-red-500 border-[1px]"
        }`}
        placeholder={`${label}*`}
      />
    </div>
  );

  return (
    <div className="w-[80%] m-auto mt-8">
      <div className="flex flex-col justify-between gap-10 lg:flex-row lg:items-center">
        <div className="flex-[2]">
          <center>
            <img src={admin2} width={250} height={200} alt="Admin" />
          </center>
        </div>
        <div className="flex-[3] pt-10">
          <p className="font-semibold font-lg text-[#fff]">
            Verify Your KYC Information
          </p>
          <div className="flex flex-col gap-4 mt-4 lg:flex-row">
            {renderInput("aadharNo", "Aadhar Card No.", "text", 12, 12)}
            {renderInput("uanNo", "UAN No.", "text", 12, 12)}
          </div>
          <div className="flex flex-col mt-4 gap-4 lg:flex-row">
            {renderInput("drivingLicense", "Driving License.", "text", 18, 2)}
          </div>
          <div className="flex flex-col mt-4 gap-4 lg:flex-row">
            {renderInput("bankAccountNo", "Bank Account No.", "text", 18, 12)}
            {renderInput("ifscCode", "IFSC Code.", "text", 11, 11)}
          </div>
          <div className="flex flex-col gap-4 mt-4 lg:flex-row">
            {renderInput("consumerId", "Electric Consumer ID.", "text", 20, 5)}
          </div>
          <button
            className="w-full text-sm py-2 px-4 mt-4 bg-blue-500 text-white rounded-md focus:outline-none"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Verifying..." : "Verify"}
            <ArrowRightAltIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Aadhar1;
