import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Button from '@mui/material/Button'
import { NavLink } from 'react-router-dom'

const SignUpRoute = () => {
  return (
    <div className="bg-gray-800">
    <div className="max-w-[1000px] m-auto p-4">
    <div className="pt-2 pb-5 flex justify-around items-center flex-col lg:flex-row gap-4">
      <div className="flex-[8]">
        <p className="text-white"><b>Need Loan?</b> Get up to ₹10k Ready, Sign Up to Get Started Today!</p>
      </div>
      <NavLink to={"/signup"} className="flex-[2]">
        <Button variant='contained'>Apply Now
        <FontAwesomeIcon icon={faArrowRight} className="pl-1" />
        </Button>
      </NavLink>
    </div>
    </div>
    </div>
  )
}

export default SignUpRoute