import React, { useState, useEffect } from "react";
import { NavNavLink, useNavigate, NavLink } from "react-router-dom";
import {
  FaHome,
  FaUsers,
  FaCog,
  FaSignOutAlt,
  FaUserClock,
  FaUser,
  FaUserCog,
} from "react-icons/fa";
import Avatar from "@mui/material/Avatar";
import {
  CircleRounded,
  ExitToApp,
  Grid4x4,
  LockOpen,
  MailOutline,
} from "@mui/icons-material";
import { BsBox2, BsPersonFillExclamation } from "react-icons/bs";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import longLogo from "../../../core/images/logoPC.png";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";

const AdminSidebar = () => {
  return (
    <>
      <div className="w-[300px] fixed h-[100vh] shadow-lg bg-slate-950  float-left  top-0 left-0 p-5">
        <center>
          <img src={longLogo} className="w-[160px] mt-5" />
        </center>
        <nav className="mt-[40px] flex flex-col gap-4">
          <NavLink
            to={"/admin"}
            className="transition text-white flex flex-row gap-2 bg-slate-900 p-[15px] rounded-lg hover:bg-slate-700"
          >
            <AppRegistrationIcon className="text-[#00a4ff]" /> <p>Admin</p>
          </NavLink>
          <NavLink
            to="/admin/profiles"
            className="transition text-white flex flex-row gap-2 bg-slate-900 p-[15px] rounded-lg hover:bg-slate-700"
          >
            <ContentCopyIcon className="text-[#ff0059]" /> <p>Users</p>
          </NavLink>
          <NavLink
            to="/admin/contact"
            className="transition text-white flex flex-row gap-2 bg-slate-900 p-[15px] rounded-lg hover:bg-slate-700"
          >
            <LockOpen className="text-[#ff00f2]" /> <p>UserMobile</p>
          </NavLink>
          <NavLink
            to="/admin/forms"
            className="transition text-white flex flex-row gap-2 bg-slate-900 p-[15px] rounded-lg hover:bg-slate-700"
          >
            <MailOutline className="text-[#2ff169]" /> <p>Forms</p>
          </NavLink>
          <NavLink
            to="/"
            className="transition text-white flex flex-row gap-2 bg-slate-900 p-[15px] rounded-lg hover:bg-slate-700"
          >
            <ExitToApp className="text-[#ff5b00]" /> <p>Exit</p>
          </NavLink>
        </nav>

        <div></div>
      </div>
    </>
  );
};

export default AdminSidebar;

// <aside className=" text-white  h-[130vh] w-64 p-4">
//   <div className="flex p-2  flex-col gap-4 justify-between mb-4">
//     <div>
//       <img src={longLogo} width={130} height={130} alt="" />
//     </div>
//     <div className=" text-xl  font-semibold" style={{ color: "slate" }}>
//       Admin <span className="text-cyan-500">Panel</span>
//     </div>
//   </div>
//   <nav>
//     <ul className="flex flex-col gap-6">
//       <li className="py-4  cursor-pointer">
//         <NavNavLink
//           to="/admin"
//           activeClassName="bg-gray-300"
//           className="flex items-center px-4 rounded"
//         >
//           <BsBox2 className="mr-2" />
//           Dashboard
//         </NavNavLink>
//       </li>
//       <li className="py-4  cursor-pointer">
//         <NavNavLink
//           to="/admin/profiles"
//           activeClassName="bg-gray-300"
//           className="flex items-center px-4 rounded"
//         >
//           <FaUserCog className="mr-2" />
//           Users
//         </NavNavLink>
//       </li>
//       <li className="py-4  cursor-pointer">
//         <NavNavLink
//           to="/"
//           activeClassName="bg-gray-300"
//           className="flex items-center px-4 rounded"
//         >
//           <FaSignOutAlt className="mr-2" />
//           Exit
//         </NavNavLink>
//       </li>
//       {/* Add more menu items as needed */}
//     </ul>
//   </nav>
// </aside>
