import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminContactReply from "./AdminContactReply";

const GetAdminContact = () => {
  const [contactData, setContactData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchContactData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_KEY}/v2/contact/contact-data`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Assuming the auth token is stored in localStorage
            },
          }
        );
        setContactData(response.data.data);
      } catch (error) {
        setError("Failed to fetch contact data.");
      } finally {
        setLoading(false);
      }
    };

    fetchContactData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className=" bg-white shadow-lg mt-10 overflow-hidden p-10 rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Admin Contact Details</h2>
      {contactData.length > 0 ? (
        contactData.map((contact, index) => (
          <div key={index} className="mb-4 p-4 border-b border-gray-200">
            <div>
              <strong>Name:</strong> {contact.name}
            </div>
            <div>
              <strong>Email:</strong> {contact.email}
            </div>
            <div>
              <strong>Message:</strong> {contact.message}
            </div>
            <AdminContactReply />
          </div>
        ))
      ) : (
        <div>No contact data available.</div>
      )}
    </div>
  );
};

export default GetAdminContact;
