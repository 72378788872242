import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

const VerifyMobile = ({ showModal, onClose }) => {
  const modalClass = showModal ? "block" : "hidden";
  const [mobile, setMobile] = useState("");
  const [otp, setOTP] = useState("");
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");

  const handleSendOtp = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/auth/send-verification-mobile`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("OTP sent successfully to the device.");
      console.log("Response of sendOtp:", response.data);
    } catch (error) {
      toast.error("Error sending OTP: " + error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyNumber = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/auth/verify-mobile?otp=${otp}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Account verified successfully");
      console.log("Response of verifyOtp:", response.data);
    } catch (error) {
      toast.error("Wrong OTP entered.");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`fixed inset-0 overflow-y-auto ${modalClass}`}>
      <div className="flex items-center justify-center min-h-screen p-4">
        <div className="fixed inset-0 bg-black opacity-50"></div>

        <div className="relative bg-white rounded-lg p-8 max-w-md mx-auto shadow-lg">
          <div className="absolute top-0 right-0 p-4">
            <button onClick={onClose} className="text-black hover:text-gray-700">
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>

          <div>
            <h2 className="mt-6 text-center text-2xl font-bold text-black">
              Verify your number
            </h2>
            <input
              type="text"
              placeholder="Enter OTP"
              className="mt-4 p-2 w-full border border-gray-300 rounded-md text-black"
              value={otp}
              onChange={(e) => setOTP(e.target.value)}
            />
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleSendOtp}
                disabled={loading}
                className={`px-4 py-2 rounded-md mr-2 ${
                  loading
                    ? "bg-gray-300 text-gray-500"
                    : "bg-blue-500 text-white hover:bg-blue-700"
                }`}
              >
                {loading ? "Sending..." : "Send OTP"}
              </button>
              <button
                onClick={handleVerifyNumber}
                disabled={loading}
                className={`px-4 py-2 rounded-md ml-2 ${
                  loading
                    ? "bg-gray-300 text-gray-500"
                    : "bg-blue-500 text-white hover:bg-blue-700"
                }`}
              >
                {loading ? "Verifying..." : "Verify"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyMobile;
