import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const Payout = ({ userDetails }) => {
  const handlePayoutClick = async () => {
    try {
      // Retrieve the token from localStorage
      const token = localStorage.getItem("token");

      if (!token) {
        toast.error("Token not found, please log in again.", {
          theme: "colored",
        });
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/payout/pay-out`,
        {
          userMobileNumber: `${userDetails.mobileNumber}`,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        }
      );

      console.log("Data for handlePayoutClick>>>>", response.data);
      toast.success("PayOut Successfully completed", { theme: "colored" });
    } catch (error) {
      toast.error("Error while posting your data in Payout", {
        theme: "colored",
      });
      console.error("Error handlePayoutClick data:", error);
    }
  };

  return (
    <div>
      <button
        onClick={handlePayoutClick}
        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
      >
        Make Payout <ArrowRightAltIcon />
      </button>
    </div>
  );
};

export default Payout;
