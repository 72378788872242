import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const fakeData = [
  {
    id: 1,
    image:
      "https://cdn-icons-png.freepik.com/512/3511/3511307.png?ga=GA1.1.1036628085.1707976935&",
    title: "Need a Domino's credit?",
  },
  {
    id: 2,
    image:
      "https://cdn-icons-png.freepik.com/512/5963/5963438.png?ga=GA1.1.1036628085.1707976935&",
    title: "Need a gym membership credit?",
    color: "#008000", // green
  },
  {
    id: 3,
    image:
      "https://cdn-icons-png.freepik.com/512/11671/11671537.png?ga=GA1.1.1036628085.1707976935&",
    title: "Need a vacation credit?",
    color: "#800080", // purple
  },
  {
    id: 4,
    image:
      "https://cdn-icons-png.freepik.com/512/3146/3146563.png?ga=GA1.1.1036628085.1707976935&",
    title: "Need a birthday gift credit?",
    color: "#0000FF", // blue
  },
  {
    id: 5,
    image:
      "https://cdn-icons-png.freepik.com/512/167/167707.png?ga=GA1.1.1939409115.1709864126&",
    title: "Need a School Fee credit?",
    color: "#0000FF", // blue
  },
  {
    id: 6,
    image:
      "https://cdn-icons-png.freepik.com/512/741/741407.png?ga=GA1.1.1939409115.1709864126&",
    title: "Need a Car Maintenance Credit?",
    color: "#4682B4", // dark blue
  },
  {
    id: 7,
    image:
      "https://cdn-icons-png.freepik.com/512/1998/1998627.png?ga=GA1.1.1939409115.1709864126&",
    title: "Need a Pet Care Credit?",
    color: "#4B0082", // virtigo
  },
  {
    id: 7,
    image:
      "https://cdn-icons-png.freepik.com/512/590/590836.png?ga=GA1.1.1939409115.1709864126&",
    title: "Need a Coffee Shop Credit?",
    color: "#A52A2A ", // brown
  },
];

const ProductCardAutoSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const button = document.getElementById("myButton");
      button.click();
    }, 3000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const getNextIndex = (currentIndex) => (currentIndex + 1) % fakeData.length;
  const getNext2Index = (currentIndex) => (currentIndex + 2) % fakeData.length;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? fakeData.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === fakeData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const nextIndex = getNextIndex(currentIndex);
  const next2Index = getNext2Index(currentIndex);

  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div className="flex flex-col items-center">
      <motion.section
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1.3 }}
        className="flex flex-col lg:flex-row items-center gap-4"
      >
        <div>
          <h1 className="text-3xl text-center ">
            We cater to all your unique needs in seconds!
          </h1>
        </div>
        <div className="hidden  flex-row gap-2 ">
          <button
            onClick={handlePrev}
            className="px-4 bg-blue-600 py-2 text-white rounded-full  focus:outline-none"
          >
            <ArrowLeftOutlined />
          </button>
          <button
            onClick={handleNext}
            className="px-4 py-2 bg-blue-600 text-white rounded-full  focus:outline-none"
            id="myButton"
          >
            <ArrowRightOutlined />
          </button>
        </div>
      </motion.section>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1.3 }}
        className="mb-4 p-4 mt-12 flex flex-col lg:flex-row items-center gap-5"
      >
        <div className="w-[300px] h-[220px]  bg-white border-solid border-b-4 border-purple-600 rounded-lg shadow-lg">
          <div className="p-[20px]">
            <div className="w-[100px] h-[100px] m-auto">
              <img
                className="w-[100px] h-[100px] m-auto object-cover"
                src={fakeData[currentIndex].image}
              />
            </div>
            <div className="p-5">
              <center>
                <h5 className="font-semibold text-xl text-[#0083ec] text-center">
                  {fakeData[currentIndex].title}
                </h5>
              </center>
            </div>
          </div>
        </div>
        <div className="w-[300px] h-[220px] bg-white border-solid border-b-4 border-green-600 rounded-lg shadow-lg">
          <div className="p-[20px]">
            <div className="w-[100px] h-[100px] m-auto">
              <img
                className="rounded-t-lg"
                src={fakeData[nextIndex].image}
                width={300}
                height={300}
              />
            </div>
            <div className="p-5">
              <center>
                <h5 className="font-semibold text-xl text-[#0083ec]">
                  {fakeData[nextIndex].title}
                </h5>
              </center>
            </div>
          </div>
        </div>
        <div className="w-[300px] h-[220px] border-solid border-b-4 border-blue-600 bg-white  rounded-lg shadow-lg">
          <div className="p-[20px]">
            <div className="w-[100px] h-[100px] m-auto">
              <img
                className="rounded-t-lg"
                src={fakeData[next2Index].image}
                width={300}
                height={300}
              />
            </div>
            <div className="p-5">
              <center>
                <h5 className="font-semibold text-xl text-[#0083ec]">
                  {fakeData[next2Index].title}
                </h5>
              </center>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ProductCardAutoSlider;
