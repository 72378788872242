import React from "react";
import fairPractCode from "../core/css/tandc.module.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import {
    faFacebook,
    faInstagram,
    faThreads,
    faYoutube,
  } from "@fortawesome/free-brands-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FPracticesCode = () => {
    return (
        <>
            <Header />
            <div className={fairPractCode.container}>
                <div className={fairPractCode.upperContainerLink}>
                    <NavLink onClick={()=>window.location.reload()} className={fairPractCode.linkStyle}>Home &gt;{" "}</NavLink>
                    <h3>Fair Practice Code</h3>
                </div>
                <div className={fairPractCode.upperContainer}>
                    <h1>
                        PLENTYCRED'S Fair Practices Code
                    </h1>
                </div>
                <div id="wrapper">
                <div className="flex flex-row items-center">
        <NavLink to="#" className="transition hover:text-gray-600">
          <FontAwesomeIcon
            icon={faFacebook}
            size={30}
            color="blue"
            className="pr-2"
          />
        </NavLink>
        <NavLink
          to="https://www.instagram.com/plenty_cred/"
          className="transition hover:text-gray-600"
        >
          <FontAwesomeIcon
            icon={faInstagram}
            size={30}
            color="purple"
            className="pr-2"
          />
        </NavLink>
        <NavLink
          to="https://www.threads.net/@plenty_cred"
          className="transition hover:text-gray-600"
        >
          <FontAwesomeIcon icon={faThreads} size={30} className="pr-2" />
        </NavLink>
        <NavLink
          to="https://www.youtube.com/channel/UCYqaLhxr4j7C-_dCe49Hfiw"
          className="transition hover:text-gray-600"
        >
          <FontAwesomeIcon
            icon={faYoutube}
            size={30}
            color="red"
            className="pr-2"
          />
        </NavLink>
      </div>
                <div className={fairPractCode.middleContainer}>
                    <h3>
                        The Company shall adopt all the best practices prescribed by RBI from time to time and shall make appropriate modifications if any necessary to this Code to conform to the standards so prescribed. The Company’s policy is to treat all the customers consistently and fairly.
                    </h3>
                    <h3>
                        The employees of the Company will help, encouragement and service in a fair, equitable and consistent manner. The Company will ensure that the implementation of the Code is the responsibility of the entire organization. The Company's fair lending practices shall apply across all aspects of its operations including marketing, loan origination, processing, and servicing and collection activities.
                    </h3>
                </div>
                <div className={fairPractCode.lowerContainer}>

                    <ul className={fairPractCode.innerLowerContainer}>
                        <li>
                            <h2>
                                OBJECTIVES OF THE FAIR PRACTICES CODE The Code has been adopted:
                            </h2>
                            <ul>
                                <li>
                                    To promote good and fair practices by setting minimum standards in dealing with customers
                                </li>
                                <li>To increase transparency so that the customers can have better understanding of what they can reasonably expect of the services; </li>
                                <li>To encourage market forces through competition, to achieve higher operating standards; </li>
                                <li>To promote a fair and cordial relationship between customers and the Company; </li>
                            </ul>
                        </li>
                        <li>
                            <h2>
                                APPLICABILITY The Code shall apply to all employees, its agents/ representatives /third-party vendors/service providers of the Company and other persons authorized to represent it during its business.
                            </h2>

                        </li>
                        <li>
                            <h2>
                                NON-DISCRIMINATION POLICY
                            </h2>
                            <p>
                                The Company will not discriminate between its customers based on gender, physical ability, race or religion. The Company will also not discriminate visually impaired or physically challenged applicants on the ground of disability in extending products, services, facilities, etc. However, this does not preclude the Company from instituting or participating in schemes framed for different sections of the society.
                            </p>
                        </li>
                        <li>
                            <h2>
                                LANGUAGE OF THE COMMUNICATION
                            </h2>
                            <p>
                                All required communications by the Company to the borrower will be in the language as understood by the borrower (English or Vernacular language).
                            </p>
                        </li>
                        <li>
                            <h2>
                                APPLICATIONS FOR LOANS AND THEIR PROCESSING
                            </h2>

                            <ul style={{ listStyleType: "disc", paddingLeft: "2em" }}>
                                <li>
                                    Loan application forms shall include the relevant information which affects the interest of the borrower so that informed decision could be taken by the borrower. The loan application form shall also indicate the list of documents required to be submitted with Plentycred Page 3 the application form.
                                </li>
                                <li>
                                    Receipt of completed application forms will be duly acknowledged by the Company and will also indicate the approximate time frame within which the customer can expect to hear from the Company regarding his/ her loan application.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h2>
                                LOAN APPRAISAL AND TERMS AND CONDITIONS
                            </h2>
                            <ul style={{ paddingLeft: "2em" }}>
                                <li>
                                    All loan applications will be assessed as per the Company’s internal credit policies and appraisal process.
                                </li>
                                <li>
                                    Upon approval of the loan a sanction letter indicating the amount of loan sanctioned, the applicable annualized rate of interest, method of application of the same along with other important terms and conditions will be conveyed in writing to the borrower.
                                </li>
                                <li>
                                    The Company will mention the penal charges for the late payment in bold in the loan agreement. A suitable provision in this regard has been incorporated in the loan agreement.
                                </li>
                                <li>
                                    After the execution of the loan agreement, all the borrowers will be furnished a copy of the loan agreement along with a copy each of all enclosures quoted in the loan agreement.
                                </li>
                            </ul>
                        </li>

                        <li>
                            <h2>
                                DISBURSEMENT OF LOANS INCLUDING CHANGES IN TERMS AND CONDITIONS
                            </h2>
                            <ul style={{ paddingLeft: "2em" }}>
                                <li>
                                    Any changes in the terms and conditions (which are impacting the borrower) including rate of interest rate, service charges, prepayment charges, repayment/ disbursement schedule etc. shall be informed to the borrower in writing.
                                </li>
                                <li>
                                    Changes in interest rates and other charges (which are averse to the borrower) shall be with prospective effect only.
                                </li>
                                <li>
                                    Decision to recall any loan / accelerate payment shall be done in accordance with the terms and conditions of the loan agreement.
                                </li>
                                <li>
                                    All underlying securities shall be released, as per the request of the borrower, after the full repayment of the loan along with all other charges as per the terms of the loan agreement and subject to exercise of any right of lien/set off, for which a notice shall be given to the borrower with full details.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h2>
                                PRIVACY AND CONFIDENTIALITY
                            </h2>
                            <ul style={{ paddingLeft: "2em" }}>
                                <li>
                                    <h3>
                                        Credit Reference Agencies/ Credit Information Companies (CICs)
                                    </h3>
                                    <ul>
                                        <li>
                                            The Company may share details of the loan and repayment track record of its borrowers to the Credit Information Companies as per the regulatory directions/ guidelines or the Company's internal policies.
                                        </li>
                                        <li>
                                            The Company may share information with the Credit Information Companies about the personal debts the customer owes it if:
                                            <ul style={{ listStyleType: "disc" }}>
                                                <li>
                                                    The customer has fallen behind his/her payments
                                                </li>
                                                <li>
                                                    The amount owed is not in dispute
                                                </li>
                                                <li>
                                                    The customer has not made payment following our formal demand for repayment of dues
                                                </li>

                                            </ul>
                                        </li>
                                        <li>
                                            At the same time, the Company representatives will explain to the customers the role of the CICs and the effect the information they provide can have on customer's credit score and ability to get credit.
                                        </li>
                                        <li>
                                            The Company will give information about the customer's account to the CICs if the customer has given his/her permission to do so OR the statutory/ regulatory requirements prescribe so.
                                        </li>
                                        <li>
                                            The Company will provide relevant information given to the CICs if demanded by the customer.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h3>
                                        Sharing of information
                                    </h3>
                                    <ul>
                                        <li>
                                            The Company may provide such information to its group/associate entities or companies for which it has obtained consent/ permission from its customer Plentycred Page 4 under loan application/ sanction letter/ most important terms & conditions/loan agreement.
                                        </li>
                                        <li>
                                            The Company will treat the personal information of customer even when the customer is no longer a customer as private and confidential. We will not reveal the data or information of customer to anyone except as provided above and in the following exceptional cases:
                                            <ul style={{ listStyleType: "disc" }}>
                                                <li>
                                                    Required by law;
                                                </li>
                                                <li>. Duty towards public to reveal information</li>
                                                <li>The Company's interest requires giving information</li>
                                                <li>The Company has consent/ permission from the customers</li>
                                            </ul>
                                        </li>
                                        <li>
                                            The Company’s representatives will inform the customer about his rights/ liabilities under the Laws of India for accessing the personal records that the Company holds about him/her;
                                        </li>
                                        <li>
                                            The Company will not use customer’s personal information for marketing purposes unless the customer specifically authorizes us to do so.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h2>
                                GENERAL PROVISIONS
                            </h2>
                            <ul>
                                <li>
                                    The Company shall refrain from interference with the business of the borrowers except under the terms and conditions of the loan agreement (unless new information, not disclosed earlier, by the borrower has come to the notice of the Company)
                                </li>
                                <li>
                                    Where the borrower insists on transferring the loan, the consent or otherwise the objection if any, would be conveyed within 21 days from the date of receipt of such request of the borrower. Such transfer will be as per the terms and conditions of the loan in consonance with law
                                </li>
                                <li>
                                    For recovery of loan, the Company will not resort to undue harassment and will follow procedures adopted internally by the Company within the applicable legal framework
                                </li>
                                <li>
                                    The Company shall not charge foreclosure charges/ pre-payment penalties on any floating rate term loan sanctioned for purposes other than business to individual borrowers
                                </li>
                                <li>
                                    The Company will ensure that the staffs are adequately trained to deal with the customers in appropriate manner.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h3>
                                GRIEVANCE REDRESSAL MECHANISM
                            </h3>
                            <p>
                                The Company has in place Board approved grievance redressal mechanism. Such a mechanism ensures that all the disputes arising out of the decisions of the lending business are heard and disposed of at least at the next higher level. The same is displayed on the website of they may be referred to for details pertaining to grievance redressal process and escalation matrix followed by the Company. The grievance redressal mechanism will also address the complaints pertaining to outsourced services provided by the agencies on behalf of the Company as required under the RBI Circular DNBR. PD.CC. No. 090/03.10.001/2017-18 dated November 09, 2017,
                            </p>
                            <p>
                                Plentycred The Board of Directors shall provide for periodical review of the compliance of the Fair Practices Code and the functioning of the grievance's redressal mechanism at various levels of management. A consolidated report of such reviews shall be submitted to the Board at regular intervals, as may be prescribed by it. Further in accordance with Ombudsman Scheme for Non –Banking Financial Companies, 2018 Company shall also appoint Nodal officer /Principal Nodal Officer in accordance with the direction prescribed thereunder and will display on its website and its branches or places where business is transacted the name and contact details of the Ombudsmen who can be approached for resolution of complaints against the Company.
                            </p>
                            <p>
                                Page 5 RBI vide its press release and notification Ref. CEPD. PRD. No .S873/13.01.001/2021-22 dated November integrated the existing three ombudsman schemes of RBI namely,
                                <ul>
                                    <li>the Banking Ombudsman Scheme, 2006; </li>
                                    <li>the Ombudsman Scheme for Non-Banking Financial Companies, 2018; and </li>
                                    <li>the Ombudsman Scheme for Digital Transactions, 2019 and launched The Reserve Bank - Integrated Ombudsman Scheme, 2021 with a view to provide cost free redress of customer complaints involving deficiency in services rendered by entities regulated by RBI, if not resolved to the satisfaction of the customers or not replied within a period of 30 days by the regulated entity. The Executive Director-in charge of Consumer Education and Protection Department of RBI would be the Appellate Authority under the Scheme. The copy of the scheme, Principal Nodal officer details and Salient features of the scheme are available on branches as well as website of the Company. The grievance redressal mechanism as displayed on the website contains the details of Principal Nodal Officer and details for lodging of complaint with RBI through various modes. Any customer having grievance/complaint/feedback with respect to the product and services offered by the Company, or its outsourced agencies may write to the Company’s Customer Service/Care Department through any of the following channels:</li>
                                </ul>
                            </p>
                            <p>Call at +91-7058111852 ✓ Email- assist@plentycred.co.in ✓ Writing a letter at the address-  505, near traffic park Dharam Peth extension Nagpur Maharashtra India 440010 ✓ Website of the Company: www.plentycred.co.in  How a complaint should be made: Customers are requested to provide loan details i.e. Loan Account Number, Details of Feedback/ Suggestion/ Complaint and valid Contact Information including phone no. & e-mail ID while lodging complaint with the Company. When to expect a reply to The Company shall endeavor to address/respond to all queries/grievances within a reasonable time and keep the customer informed about the status of their complaints.</p>
                            <p>Each customer query/complaint being unique in nature, may take up to thirty days for complete resolution after investigation. Whom to approach for redressal: Escalation matrix In case the customer does not receive a response within the number of days indicated below for each level or if the customer is dissatisfied with the response provided from the Company, the customer may escalate the complaint to the next level as indicated below: Primary Level Customers are requested to first raise their concerns through any of the channels mentioned above. And if the same is not resolved within five days or if the customer is not satisfied with the solution provided by the customer care service, then the customer may follow below escalations for resolving their grievances:  Contact: +91- 7058111852 Email assist@plentycred.co.in </p>
                            <p>
                                The name and contact details of the Grievance Redressal Officer is as follows: Grievance Redressal Officer/Principal Nodal Officer (PNO) Name: Mr Niraj Hemraj Shah  E-mail ID: assist@plentycred.co.in  Telephone no.: +91-7058111852 Address: Plentycred  505, near traffic park Dharampeth extension Nagpur Maharashtra India 440010.
                            </p>
                            <p>
                                If the customer is not satisfied with the resolution provided or if the customer does not hear from us in 30 days, then he/she may lodge their complaint on RBI CMS portal - https://cms.rbi.org.in or reach them on the dedicated e-mail id - crpc@rbi.org.in Or send your complaint form (format available on the website under Integrated Ombudsman scheme 2022) to the below mentioned address: The Officer In charge, CENTRAL OFFICE, CENTRE I,
                                WORLD TRADE CENTRE CUFFE PARADE, COLABA MUMBAI - 400 005.
                            </p>
                            <h4>Email: dnbsNagpur@rbi.org.in</h4>
                        </li>
                        <li>
                            <h2>INTEREST CHARGES </h2>
                            <ul>
                                <li>To ensure that the customers are not charged excessive interest rates and charges on loans and advances by the Company, the Board of Directors of the Company has adopted a Policy on Interest Rate Model and Policies & Procedures on Determining Interest Rates, which has been put up on the Company's website for the benefit of its customers. </li>
                                <li>The information regarding the Interest Rate Model of the Company will be also disclosed in the application form and sanction letter.</li>
                            </ul>
                        </li>
                        <li>
                            <h2>REPOSSESSION, IF VEHICLES ARE FINANCED BY THE COMPANY </h2>
                            <p>
                                The Company will have a re-possession clause in the loan agreement with the borrower that will be legally enforceable. To ensure transparency following terms and conditions will be provided in the loan agreement:
                                <ul>
                                    <li>Notice period before taking a repossession. </li>
                                    <li>Circumstances under which the notice period can be waived</li>
                                    <li>The procedure for taking possession of the security.</li>
                                    <li>A provision regarding final chance to be given to the borrower for repayment of the loan before the sale/ auction; </li>
                                    <li>The procedure for giving repossession to the borrower</li>
                                    <li>The procedure for sale / auction of the property. </li>
                                </ul>
                            </p>
                        </li>
                        <li>
                            <h2>PENAL CHARGES IN LOAN ACCOUNTS </h2>
                            <p>
                                Company shall adhere to RBI’s circular on Fair Lending Practice - Penal Charges in Loan Plentycred
                                7 Accounts dated August 18, 2023. 14. RESET OF FLOATING INTEREST RATE ON EQUATED MONTHLY INSTALMENTS (EMI) BASED PERSONAL LOANS Company shall adhere to RBI’s circular on Reset of floating interest rate on Equated Monthly Instalments (EMI) based personal loans dated August 18, 2023. 15. RESPONSIBLE LENDING CONDUCT – RELEASE OF MOVABLE/IMMOVABLE PROPERTY DOCUMENTS ON REPAYMENT/ SETTLEMENT OF PERSONAL LOANS Company shall adhere to RBI’s circular on Responsible Lending Conduct – Release of movable/immovable property documents on repayment/ settlement of personal loans dated September 13, 2023.
                            </p>
                        </li>
                        <li>
                            <h2>
                                Review
                            </h2>
                            <p>
                                The Board will be updated for periodical review of the compliance of the Fair Practices Code and the functioning of the grievance’s redressal mechanism at various levels of management, and a consolidated report of such reviews shall be submitted to the Board at regular intervals, as and when required. The Code shall be reviewed annually by the Board of Directors, and any other regulatory changes in this regard will stand updated in the Code from time to time. *********** Plentycred.
                            </p>
                            <ul>
                                <li>Company Secretary & Compliance Officer August 09, 2022 Adoption of Fair Practice Code pursuant to demerger </li>
                                <li>Chief Compliance Officer August 02, 2023 Version updated after annual review </li>
                                <li>
                                    Chief Compliance Officer October 27, 2023 Version updated due to RBI Notifications
                                    <ul>
                                        <li>Master Direction - Reserve Bank of India (Non-Banking Financial Company - Scale Based Regulation) Directions, 2023 dated October 19, 2023 </li>
                                        <li>Fair Lending Practice - Penal Charges in Loan Accounts dated August 18, 2023 </li>
                                        <li>
                                            Responsible Lending Conduct - Release of movable/immovable property documents on repayment/ settlement of personal loans dated September 13, 2023
                                        </li>
                                        <li>Reset of floating interest rate on Equated Monthly Instalments (EMI) based personal loans dated August 18, 2023, Changes to contact details of Nodal Officer & Principal Nodal Officer.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            </div>
            <Footer />
        </>
    );
}


export default FPracticesCode;