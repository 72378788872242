import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, TextField } from "@mui/material";

const ExperianCibil = () => {
  const [formData, setFormData] = useState({ panNumber: "", pinNumber: "" });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "pinNumber" ? value.replace(/[^a-zA-Z0-9]/g, "") : value,
    }));
  };

  const token = localStorage.getItem("token");

  const fetchCibilData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}/v1/cibil/signzy-experian`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ pincode: parseInt(formData.pinNumber, 10) }),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || "Network response was not ok");
      }

      const data = await response.json();
      console.log("Cibil Data Response:", data);
      toast.success("Data fetched successfully");
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      toast.error(error.message || "Error fetching data");
    } finally {
      window.location.reload();
    }
  };

  const handleVerifyClick = async () => {
    if (!formData.pinNumber || !formData.panNumber) {
      toast.error("Both Pan Number and PIN Number are required");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/cibil/add-cibil`,
        { panCardNo: formData.panNumber },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error(response.data.message || "Error uploading data");
      }

      console.log("Cibil Data Uploaded:", response.data);
      await fetchCibilData();
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(error.response?.data?.message || "Provide Correct PanCard", {
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4 max-w-md mx-auto bg-white rounded-xl shadow-md space-y-4">
      <ToastContainer />
      <h1 className="text-lg font-semibold">Verify Your Cibil</h1>
      <p className="text-xs font-medium">
        You will need to verify your cibil to start the process.
      </p>
      <div>
        <TextField
          id="panInput"
          name="panNumber"
          label="Pan Number"
          placeholder="Enter PAN Number"
          value={formData.panNumber}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          inputProps={{ maxLength: 25 }}
          margin="normal"
        />
      </div>
      <div>
        <TextField
          id="pinInput"
          name="pinNumber"
          label="PIN Code"
          placeholder="Enter PIN code"
          value={formData.pinNumber}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          inputProps={{ maxLength: 25 }}
          margin="normal"
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleVerifyClick}
        className="w-full"
        disabled={loading}
      >
        {loading ? "Verifying..." : "Verify"}
      </Button>
    </div>
  );
};

export default ExperianCibil;
