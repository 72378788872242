import React from "react";

const AdminContacts = ({ contacts }) => {
  const getRandomColor = () => {
    // Generate random color in hexadecimal format
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  };

  return (
    <section className="p-3">
      <h2 className="text-xl font-bold mb-4 mt-5">Contact List</h2>
      <div className="contact-container h-[80vh] overflow-y-auto">
        <div className="grid grid-cols-2 gap-4">
          {contacts.map((contact, index) => (
            <div
              key={index}
              className="p-4 rounded-lg border-b-4  shadow-md bg-slate-900 text-white"
              style={{ borderBottomColor: getRandomColor() }}
            >
              <ul>
                <li className="mb-2">
                  <strong>Contact number:</strong> {contact.split(",")[0]}
                </li>
                <li className="mb-2">
                  <strong>Contact name:</strong> {contact.split(",")[1]}
                </li>
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AdminContacts;
