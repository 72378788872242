import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useInView } from "react-intersection-observer";
import educateImg from "../core/images/educate.png";

// const data = [
//   { id: 1, title: "Apple" },
//   { id: 2, title: "Banana" },
//   { id: 3, title: "Avocado" },
//   { id: 4, title: "Apricot" },
//   { id: 5, title: "Blueberry" },
//   { id: 6, title: "Cherry" },
//   { id: 7, title: "Coconut" },
//   { id: 8, title: "Date" },
//   { id: 9, title: "Dragon Fruit" },
//   { id: 10, title: "Fig" },
//   { id: 11, title: "Grape" },
//   { id: 12, title: "Guava" },
//   { id: 13, title: "Kiwi" },
//   { id: 14, title: "Lemon" },
//   { id: 15, title: "Lime" },
//   { id: 16, title: "Mango" },
//   { id: 17, title: "Orange" },
//   { id: 18, title: "Papaya" },
//   { id: 19, title: "Peach" },
//   { id: 20, title: "Pear" },
//   { id: 21, title: "Pineapple" },
//   { id: 22, title: "Plum" },
//   { id: 23, title: "Raspberry" },
//   { id: 24, title: "Strawberry" },
//   { id: 25, title: "Watermelon" },
// ];

const items = [
  {
    id: 1,
    title: "Disbursement",
    description:
      "What is Loan Disbursement?  In the lending domain, disbursement refers to the process when the lender transfers the sanctioned loan amount to the borrower’s bank account. The lender may deposit the amount into the borrower’s bank account in one go or instalments, as agreed upon.  Some of its features include:  Note that some lenders also …",
    url: "https://plentycred.co.in/financial-dictionary/32",
  },
  {
    id: 2,
    title: "Finance",
    description: "Finance is a broad term used to refer to creating and managing money in the form of investments, debt or loans, securities and more for individuals, companies and the government. Government policies regarding currency, repo rates, the budget and the rate at which loans are given out by financial institutions all come under finance. Its …",
    url: "https://plentycred.co.in/financial-dictionary/42",
  },
  {
    id: 3,
    title: "Mandate",
    description: "What is a Mandate? A mandate is any official order or instruction that gives authority to the receiver to facilitate service. It is usually used in the context of banking, where a mandate helps to make recurring payments at a particular date to a specific business or entity.  Launched by the RBI and the National …",
    url: "https://plentycred.co.in/financial-dictionary/68",
  },
  {
    id: 4,
    title: "Regression",
    description: "Regression is a technique used to analyse the relationship between two or more variables in statistical studies to make predictions or estimations. It is commonly used for data analysis and forecasting purposes in fields such as economics, finance and market research.",
    url: "https://plentycred.co.in/financial-dictionary/88",
  },
  {
    id: 5,
    title: "Insurance",
    description: "What is Insurance?  Insurance is a contract between you (the policyholder) and an insurance company (the insurer). This contract provides you with financial protection against potential losses or risks.  The agreement states the terms of when and how much premium you must pay to avail of such protection. In addition, it includes the circumstances that …",
    url: "https://plentycred.co.in/financial-dictionary/56",
  },
  {
    id: 6,
    title: "EBITA",
    description: "EBITA, commonly known as Earnings Before Interest, Taxes and Amortisation. It is a measure of a company’s operating performance that shows its profitability before considering: Interest Taxes Non-cash expenses such as amortisation EBITA is used to assess the efficiency and profitability of companies and compare firms operating in similar segments.",
    url: "https://plentycred.co.in/financial-dictionary/38",
  },
  {
    id: 7,
    title: "Value Added Tax",
    description: "Today, Value Added Tax is known as the Goods and Services Tax or GST in India. It applies to a product or service at different stages of manufacturing, distribution and sale where value is added. It is charged by the central government and is paid by end consumers who make a purchase.",
    url: "https://plentycred.co.in/financial-dictionary/103",
  },
  {
    id: 8,
    title: "Gross Interest",
    description: "Gross interest is the yearly rate of interest that you receive when you invest in various vehicles such as a security or a fixed deposit. It refers to the interest before tax or before other fees are applied. Gross interest can also apply to the annual interest due on a loan. Be aware that gross …",
    url: "https://plentycred.co.in/financial-dictionary/51",
  },
  {
    id: 9,
    title: "Redemption",
    description: "Redemption means selling bonds, shares or other securities like certificates of deposit to get money. For instance, when you sell shares you own, you are making redemptions. Redemption has a direct bearing on whether you will pay a long-term or short-term capital gains tax based on the buying and selling timeline. The redemption value is …",
    url: "https://plentycred.co.in/financial-dictionary/90",
  },
];

export default function Financialdictionary() {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    // Data to be sent in the POST request (if needed)
    axios
      .post(`${process.env.REACT_APP_API_KEY}/v1/fcl/get-financial-dictionary`)
      .then((response) => {
        console.log("response>>>>", response);
        setData(response.data.data);
      })
      .catch((error) => {
        console.error("Error sending POST request:", error);
      });
  }, []);

  console.log(data);

  const groupedData = data.reduce((acc, item) => {
    const firstLetter = item.title.charAt(0).toUpperCase();
    acc[firstLetter] = acc[firstLetter] || [];
    acc[firstLetter].push(item);
    return acc;
  }, {});

  const sortedKeys = Object.keys(groupedData).sort();

  const handleNavLinkClick = (letter) => {
    const element = document.getElementById(letter);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="overflow-hidden">
      <br />
      <br />
      <div id="wrapper">
        <div className="mt-12 rounded-[20px] bg-[rgb(220,255,245)] bg-[linear-gradient(90deg,_rgba(220,255,245,1)_0%,_rgba(255,255,255,1)_24%,_rgba(255,254,189,1)_48%,_rgba(255,232,172,1)_73%,_rgba(255,188,188,1)_100%)] p-10 flex flex-wrap justify-end flex-col gap-10 lg:flex-row items-center">
          <motion.div
            ref={ref}
            initial="hidden"
            animate="visible"
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: -100 },
            }}
            transition={{ duration: 1.3 }}
            className="flex-[1]"
          >
            <h1 className="text-4xl font-semibold">Financial Dictionary</h1>
            <p className="mt-5 text-sm lg:text-2xl">
              Understanding the world of finance can be a daunting task,
              PlentyCred is here to simplify finance for you. This glossary aims
              to demystify common financial jargons, providing concise and clear
              definitions of key financial terms. So, let's dive in and explore
              the fundamental concepts that underpin the world of finance.
            </p>
            <br />
            <div to="/" className="font-semibold block mt-10">
              Find Out More <FontAwesomeIcon icon={faArrowDown} />
            </div>
          </motion.div>
          <motion.div
            ref={ref}
            initial="hidden"
            animate="visible"
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: -100 },
            }}
            transition={{ duration: 1.3 }}
            className="flex-[1]"
          >
            <img src={educateImg} alt="educateFinance" className="m-auto" />
          </motion.div>
        </div>
        <div className=" flex flex-col mx-auto mt-5">
          <div className="w-[100%] overflow-auto bg-[rgb(220,255,245)] bg-[linear-gradient(90deg,_rgba(220,255,245,1)_0%,_rgba(255,255,255,1)_24%,_rgba(255,254,189,1)_48%,_rgba(255,232,172,1)_73%,_rgba(255,188,188,1)_100%)] p-5 rounded">
            <ul className="flex flex-row gap-5">
              {sortedKeys.map((letter) => (
                <li key={letter} className="mb-2">
                  <button
                    onClick={() => handleNavLinkClick(letter)}
                    className="transition font-light bg-white rounded-lg pl-4 pr-4 p-2 text-2xl shadow-md hover:bg-gray-900 hover:text-white"
                  >
                    {letter}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="p-10 bg-white rounded-lg mt-5 shadow ">
          {sortedKeys.map((letter) => (
            <div key={letter} className="mb-4" id={letter}>
              <h2 className="text-6xl font-semibold mb-4 mt-10 text-gray-200">
                {letter}
              </h2>
              <ul className="flex flex-wrap gap-5 ml-6">
                {groupedData[letter].map((item) => (
                  <li key={item.id} className="mb-2">
                    <NavLink
                      to={`/financial-dictionary/${item.id}`}
                      className="text-[#0083ec] pr-4 font-light text-xl hover:underline"
                    >
                      {"• " + item.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="mt-20 mb-20">
          <h1 className="text-center font-semibold text-2xl">
            Most searched financial term
          </h1>
        </div>
        {items.map((item) => (
        <div className="p-10" key={item.id}>
          <h2 className="text-xl font-semibold mb-5">{item.title}</h2>
          <p className="font-light text-xl">
          {item.description}
          </p>
          <br />
          <a href={item.url} className="font-semibold text-blue-600 text-lg mt-5">
            Read More
          </a>
          <hr className="mt-5 mb-5 bg-gray-200 border-0 h-[1px]" />
        </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}
