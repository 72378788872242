// TransactionMain.js
import React, { useState } from "react";
import HeaderTransaction from "../../Transaction/TransactionHeader";
import TransactionPayIn from "../../Transaction/TransactionPayIn";
import TransactionPayOut from "../../Transaction/TransactionPayOut";
import TransactionDebit from "../../Transaction/TransactionDebit";
import { useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const AdminTransaction = () => {
  const [payinData, setPayinData] = useState(null);
  const [payOutData, setPayOutData] = useState(null);
  const [debitData, setDebitData] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    // Check if token is present in local storage
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");
      const decodeToken = jwtDecode(token);
      const userId = decodeToken.id || decodeToken.sub;

      axios
        .get(`${process.env.REACT_APP_API_KEY}/v1/auth/getUser/${userId}`)
        .then((response) => {
          console.log(response);
          setUserDetails(response.data.userDetails);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };

        // Fetching payin data
        const payinResponse = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v1/payHistory/admin-PayinHistory`,
          {
            id: userDetails?.id,
          },
          {
            headers: headers,
          }
        );

        setPayinData(payinResponse.data.PayInHistory);

        // Fetching payout data
        const payoutResponse = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v1/payHistory/admin-PayoutHistory`,
          {
            id: userDetails?.id,
          },
          {
            headers: headers,
          }
        );
        setPayOutData(payoutResponse.data.PayoutHistory);

        // Fetching debit data
        const debitResponse = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v1/payHistory/admin-DebitPresent`,
          {
            id: userDetails?.id,
          },
          {
            headers: headers,
          }
        );
        setDebitData(debitResponse.data.debitPresentationHistory);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  console.log("Data>>>>", {
    payinData,
    payOutData,
    debitData,
  });

  const TabContent1 = () => (
    <div className="p-4">
      <TransactionPayIn data={payinData} />
    </div>
  );
  const TabContent2 = () => (
    <div className="p-4">
      <TransactionPayOut data={payOutData} />
    </div>
  );
  const TabContent3 = () => (
    <div className="p-4">
      <TransactionDebit data={debitData} />
    </div>
  );

  const [activeTab, setActiveTab] = useState(1);

  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return <TabContent1 />;
      case 2:
        return <TabContent2 />;
      case 3:
        return <TabContent3 />;
      default:
        return null;
    }
  };

  return (
    <div className="flex justify-center items-center h-[50vh]">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-[1100px] w-full">
        <HeaderTransaction setActiveTab={setActiveTab} />
        {renderTabContent()}
      </div>
    </div>
  );
};

export default AdminTransaction;
