import React, { useEffect } from "react";
import Header from "../components/Header";
import { NavLink } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import { faArrowDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sectionArea from "../core/images/sectionArea.png";
import stockArea from "../core/images/calculate.png";
import SignUpRoute from "../components/SignUpRoute";
import Footer from "../components/Footer";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import InvestingTable from "../extras/InvestingTable";

const Investment = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <>
      <br /><br />
      <div id="wrapper">
        <div className="mt-12 rounded-[20px] bg-[rgb(220,255,245)] bg-[linear-gradient(90deg,_rgba(220,255,245,1)_0%,_rgba(255,255,255,1)_24%,_rgba(255,254,189,1)_48%,_rgba(255,232,172,1)_73%,_rgba(255,188,188,1)_100%)] p-10 flex flex-wrap justify-end flex-col gap-10 lg:flex-row items-center">
          <div className="flex-[1]">
            <motion.h1
              ref={ref}
              initial="hidden"
              animate="visible"
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: -100 },
              }}
              transition={{ duration: 1.3 }}
              className="font-semibold text-6xl text-gray-950"
            >
              Invest Smarter,
              <br />
              Earn Better
            </motion.h1>
            <br />
            <motion.p
              ref={ref}
              initial="hidden"
              animate="visible"
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: -100 },
              }}
              transition={{ duration: 1.3 }}
              className="text-gray-950"
            >
              It is a dream for savvy investors to watch their money grow
              effortlessly
              <br />
              while they relax. We can make your dream come true!
            </motion.p>
            <br />
            <motion.div
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: -100 },
              }}
              transition={{ duration: 1.3 }}
            >
              <NavLink
                to="/"
                className="transition bg-gray-800 px-4 py-3 rounded-lg text-white font-semibold inline-block mt-2 hover:bg-white hover:text-gray-950"
              >
                Apply Now
              </NavLink>
              <NavLink to="/" className="font-semibold block mt-10">
                Find Out More <FontAwesomeIcon icon={faArrowDown} />
              </NavLink>
            </motion.div>
          </div>
          <motion.div
            ref={ref}
            initial="hidden"
            animate="visible"
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: -100 },
            }}
            transition={{ duration: 1.3 }}
            className="flex-[1]"
          >
            <Player
              autoplay
              loop
              src="https://lottie.host/4133ce68-b4b0-4442-ad08-6de02d719e92/c1nWWxnwjs.json"
              style={{ height: "360px", width: "100%" }}
            ></Player>
          </motion.div>
        </div>
        <section className="mt-10">
          <motion.h1 className="text-5xl font-semibold">
            Get the Most Out
            <br />
            of Your Investments
          </motion.h1>
          <br />
          <div className="flex flex-col  xl:flex-row gap-4">
            <motion.div
              className="flex-[1] bg-gray-200 p-6 rounded-xl bg-right-bottom bg-no-repeat"
              style={{
                backgroundImage: `url(${sectionArea})`,
                backgroundSize: `180px`,
              }}
            >
              <h1 className="text-lg font-semibold">Invest in Plenty Cred</h1>
              <p>Make your money work harder for you!</p>
              <br />
              <NavLink to="/" className="mt-5 inline-block">
                Learn More <FontAwesomeIcon icon={faArrowRight} />
              </NavLink>
            </motion.div>
            <motion.div
              className="hidden lg:block flex-[2] text bg-gray-200 p-4 rounded-xl pr-40 bg-right-bottom bg-no-repeat"
              style={{
                backgroundImage: `url(${stockArea})`,
                backgroundSize: `140px`,
              }}
            >
              <div className="p-2">
                <h1 className="text-lg font-semibold">
                  We extend you the golden opportunity to invest in our business
                </h1>
                <br />
                <p className="text-justify text-gray-800 text-sm font-semibold">
                  earning remarkable interest rates of 12-60% (based on amount
                  and tenure), outshining every other option in the market.
                  What's even better? You can start with as low as INR 50,000!
                  We promise you absolute transparency, no risks, and
                  hassle-free monthly interest credited directly to your
                  account. Plus, gain access to your personalized dashboard,
                  giving you real-time insights into your flourishing
                  investment.
                </p>
              </div>
            </motion.div>
            <motion.div
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 100 },
              }}
              transition={{ duration: 1.3 }}
              className=" lg:hidden flex-[2] text bg-gray-200 p-4 rounded-xl bg-right-bottom bg-no-repeat"
            >
              <div className="p-2">
                <h1 className="text-lg font-semibold">
                  We extend you the golden opportunity to invest in our business
                </h1>
                <br />
                <p className="text-justify text-gray-800 text-sm font-light">
                  earning remarkable interest rates of 12-60% (based on amount
                  and tenure), outshining every other option in the market.
                  What's even better? You can start with as low as INR 50,000!
                  We promise you absolute transparency, no risks, and
                  hassle-free monthly interest credited directly to your
                  account. Plus, gain access to your personalized dashboard,
                  giving you real-time insights into your flourishing
                  investment.
                </p>
              </div>
            </motion.div>
          </div>
        </section>
        <InvestingTable />
        {/* <section>
          <div className=" mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-4">
              <div className="font-semibold text-lg mb-2">Share Market
</div>
              <div className="flex flex-wrap">
                <div className="card">
                  <p className="text-gray-700 text-base">
                    0 NPA and 0 market risk; Legal agreements both for equity
                    and debt options to pay back a fixed monthly return to
                    investors; Based majorly on business, the growth of which
                    you can track.
                  </p>
                </div>
                <div className="card">
                  <p className="text-gray-700 text-base mt-4">
                    RBI does not provide any insurance for any amount above 5
                    lakhs; Purchasing power might decrease over years due to
                    low-interest rates.
                  </p>
                </div>
                <div className="card">
                  <p className="text-gray-700 text-base mt-4">
                    Interest rates are so low that they do not even match the
                    inflation index, leading to diminished real returns and
                    almost 0 money growth.
                  </p>
                </div>
                <div className="card">
                  <p className="text-gray-700 text-base mt-4">
                    Dependent on fund managers' expertise and poor decisions can
                    lead to losses; Market risks along with less return and slow
                    growth, which can even eat up the principal in some cases.
                  </p>
                </div>
                <div className="card">
                  <p className="text-gray-700 text-base mt-4">
                    Severely subject to market volatility and fluctuations,
                    often resulting in significant losses
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section>
          <div className=" mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-4">
              <div className="font-semibold text-lg mb-2">Security</div>
              <div className="flex flex-wrap mt-5">
                <div className="card">
                  <p className="font-semibold">Plenty cred</p>
                  <p className="text-gray-700 text-base">
                    0 NPA and 0 market risk; Legal agreements both for equity
                    and debt options to pay back a fixed monthly return to
                    investors; Based majorly on business, the growth of which
                    you can track.
                  </p>
                </div>
                <div className="card">
                  <p className="font-semibold">Savings Account</p>
                  <p className="text-gray-700 text-base mt-4">
                    RBI does not provide any insurance for any amount above 5
                    lakhs; Purchasing power might decrease over years due to
                    low-interest rates.
                  </p>
                </div>
                <div className="card">
                  <p className="font-semibold">Fixed Deposits</p>
                  <p className="text-gray-700 text-base mt-4">
                    Interest rates are so low that they do not even match the
                    inflation index, leading to diminished real returns and
                    almost 0 money growth.
                  </p>
                </div>
                <div className="card">
                  <p className="font-semibold">Mutual Funds</p>
                  <p className="text-gray-700 text-base mt-4">
                    Dependent on fund managers' expertise and poor decisions can
                    lead to losses; Market risks along with less return and slow
                    growth, which can even eat up the principal in some cases.
                  </p>
                </div>
                <div className="card">
                  <p className="font-semibold">Share Market</p>
                  <p className="text-gray-700 text-base mt-4">
                    Severely subject to market volatility and fluctuations,
                    often resulting in significant losses
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <SignUpRoute />
      <Footer />
    </>
  );
};

export default Investment;
