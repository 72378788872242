import React, { useEffect, useState } from "react";
import axios from "axios";
import AdminUsersTable from "./AdminTable/AdminUsersTable";
import AdminVerifyTable from "./AdminTable/AdminVerifyTable";
import { useNavigate as useNavigation } from "react-router-dom";

const AdminUser = () => {
  const token = localStorage.getItem("token");

  const [data, setData] = useState([]);
  const [verifyData, setVerifyData] = useState([]);
  const [show, setShow] = useState("");

  const navigation = useNavigation();
  if (!token) {
    navigation("/ ");
  }

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/v2/contracting/verify-all`,
        {},
        { headers }
      )
      .then((response) => {
        setShow(response.data.VerifyAllData);
      })
      .catch((error) => {
        console.error("Error sending POST request:", error);
      });
  }, []);

  if (!show.isAdmin) {
    navigation("/");
  }

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v1/admin/allUser`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setData(response.data.data.users);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v1/admin/verify`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setVerifyData(response.data.data.users);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="p-4 rounded-lg xl:p-4">
        <AdminVerifyTable data={verifyData} />
      </div>
      <div className="p-4 xl:p-4">
        <AdminUsersTable data={data} />
      </div>
    </div>
  );
};

export default AdminUser;
