import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import image1 from "../../core/images/Slider/1.png";
import image2 from "../../core/images/Slider/2.png";
import image3 from "../../core/images/Slider/3.png";
import image4 from "../../core/images/Slider/4.png";
import image8 from "../../core/images/Slider/8.png";

const fakeData = [
  {
    id: 1,
    image:
      "https://www.cashe.co.in/wp-content/uploads/2023/12/Instant-Approval-Disbursal.png",
    title: "Instant Approval",
    description: "Pocket-friendly EMIs for 3 to 18 months",
  },
  {
    id: 3,
    image:
      "https://www.cashe.co.in/wp-content/uploads/2023/12/User-friendly.png",
    title: "User-Friendly",
    description: "Pocket-friendly EMIs for 3 to 18 months",
  },
  {
    id: 4,
    image: "https://www.cashe.co.in/wp-content/uploads/2023/12/Security.png",
    title: "It's Safe To Deal With Us",
    description:
      "Access up to Rs. 5,000 within 30 seconds for your urgent money needs. ",
  },
  {
    id: 8,
    image: "https://www.cashe.co.in/wp-content/uploads/2023/12/Grow.png",
    title: "Flexible Repayment Options",
    description: "Explore our curated investment products for superior returns",
  },
];

const CardAutoSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const button = document.getElementById("myButton");
      button.click();
    }, 3000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const getNextIndex = (currentIndex) => (currentIndex + 1) % fakeData.length;
  const getNext2Index = (currentIndex) => (currentIndex + 2) % fakeData.length;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? fakeData.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === fakeData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const nextIndex = getNextIndex(currentIndex);
  const next2Index = getNext2Index(currentIndex);

  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div className="flex flex-col items-center">
      <motion.section
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1.3 }}
        className="flex flex-col lg:flex-row items-center gap-4"
      >
        <div>
          <h1 className="text-3xl text-center ">
            Join the millions who have said "YES" to PlentyCred
          </h1>
        </div>
        <div className="flex flex-row gap-2 ">
          <button
            onClick={handlePrev}
            className="px-4 bg-blue-600 py-2 text-white rounded-full  focus:outline-none"
          >
            <ArrowLeftOutlined />
          </button>
          <button
            onClick={handleNext}
            className="px-4 py-2 bg-blue-600 text-white rounded-full  focus:outline-none"
            id="myButton"
          >
            <ArrowRightOutlined />
          </button>
        </div>
      </motion.section>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1.3 }}
        className="mb-4 p-4 mt-12 flex flex-col lg:flex-row items-center gap-5"
      >
        <div className="w-[300px] h-[220px] bg-white border-[1px] rounded-lg shadow-lg">
          <div className="p-[20px]">
            <div className="w-[100px] h-[100px] m-auto">
              <img
                className="w-[100px] h-[100px] m-auto object-cover"
                src={fakeData[currentIndex].image}
              />
            </div>
            <div className="p-5">
              <center>
                <h5 className="font-semibold text-xl text-[#0083ec] text-center">
                  {fakeData[currentIndex].title}
                </h5>
              </center>
            </div>
          </div>
        </div>
        <div className="w-[300px] h-[220px] bg-white border-[1px] rounded-lg shadow-lg">
          <div className="p-[20px]">
            <div className="w-[100px] h-[100px] m-auto">
              <img
                className="rounded-t-lg"
                src={fakeData[nextIndex].image}
                width={300}
                height={300}
              />
            </div>
            <div className="p-5">
              <center>
                <h5 className="font-semibold text-xl text-[#0083ec]">
                  {fakeData[nextIndex].title}
                </h5>
              </center>
            </div>
          </div>
        </div>
        <div className="w-[300px] h-[220px] bg-white border-[1px] rounded-lg shadow-lg">
          <div className="p-[20px]">
            <div className="w-[100px] h-[100px] m-auto">
              <img
                className="rounded-t-lg"
                src={fakeData[next2Index].image}
                width={300}
                height={300}
              />
            </div>
            <div className="p-5">
              <center>
                <h5 className="font-semibold text-xl text-[#0083ec]">
                  {fakeData[next2Index].title}
                </h5>
              </center>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default CardAutoSlider;
