import React from "react";
import { NavLink } from "react-router-dom";
import { faArrowRight, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import {
  faFacebook,
  faInstagram,
  faThreads,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import logoIC from "../core/images/logo-noncopy.png";
const Footer = ({ changeView }) => {};

export default Footer;

// return (
//   <>
//     <div className="p-10 bg-gray-950">
//       <div id="wrapper">
//         <div className="flex text-white gap-10 flex-col lg:flex-row">
//           <div className="flex-1">
//             <div className="gap-4 flex flex-col">
//               <img src={logoIC} alt="Logo" className="w-[120px]" />
//               <p className="text-white text-sm">
//                 Thinking "Oh but I have a credit card, why do I need to use
//                 PlentyCred?". Yeah, we can read minds! Well, we give you
//                 multiple benefits over credit cards..
//               </p>
//             </div>
//           </div>
//           <div className="flex-1 bg-gray-900 p-5 rounded-lg">
//             <div className="flex gap-4 flex-col">
//               <h1 className="font-semibold">Info</h1>
//               <NavLink to="/" className="transition hover:text-gray-600">
//                 <FontAwesomeIcon icon={faArrowRight} className="pr-2" /> Home
//               </NavLink>
//               <NavLink to="/about" className="transition hover:text-gray-600">
//                 <FontAwesomeIcon icon={faArrowRight} className="pr-2" /> About
//               </NavLink>
//               <NavLink
//                 to="/products"
//                 className="transition hover:text-gray-600"
//               >
//                 <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
//                 Products
//               </NavLink>
//               {/* <NavLink
//                 to="/invesments"
//                 className="transition hover:text-gray-600"
//               >
//                 <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
//                 Investments
//               </NavLink> */}
//               <NavLink
//                 to="/payments"
//                 className="transition hover:text-gray-600"
//               >
//                 <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
//                 Payments
//               </NavLink>
//               <NavLink
//                 to="/eligibility"
//                 className="transition hover:text-gray-600"
//               >
//                 <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
//                 Eligibility
//               </NavLink>
//               <NavLink
//                 to="/contact"
//                 className="transition hover:text-gray-600"
//               >
//                 <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
//                 Contact
//               </NavLink>
//               <NavLink
//                 to="/investment"
//                 className="transition hover:text-gray-600"
//               >
//                 <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
//                 Investment
//               </NavLink>
//               <NavLink
//                 to="/disclaimer"
//                 className="transition hover:text-gray-600"
//               >
//                 <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
//                 Disclaimer
//               </NavLink>
//               <NavLink
//                 to="/accessibility"
//                 className="transition hover:text-gray-600"
//               >
//                 <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
//                 Accessibility
//               </NavLink>
//             </div>
//           </div>
//           <div className="flex-[1.2]">
//             <div className="flex-1 bg-gray-900 p-5 rounded-lg">
//               <div className="flex gap-4 flex-col">
//                 <h1 className="font-semibold">Legal</h1>
//                 <NavLink
//                   to="/termsandconditions"
//                   className="transition hover:text-gray-600"
//                 >
//                   <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
//                   Terms & Conditions
//                 </NavLink>
//                 <NavLink
//                   to="/privacypolicy"
//                   className="transition hover:text-gray-600"
//                 >
//                   <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
//                   Privacy Policy
//                 </NavLink>
//                 <NavLink
//                   to="/disclosurepolicy"
//                   className="transition hover:text-gray-600"
//                 >
//                   <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
//                   Responsible Disclosure
//                 </NavLink>
//                 <NavLink
//                   to="/securitypolicy"
//                   className="transition hover:text-gray-600"
//                 >
//                   <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
//                   Security Policy
//                 </NavLink>
//                 <NavLink
//                   to="/fairpracticescode"
//                   className="transition hover:text-gray-600"
//                 >
//                   <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
//                   Fair Practice Code
//                 </NavLink>
//                 <NavLink
//                   to="/grievancepolicy"
//                   className="transition hover:text-gray-600"
//                 >
//                   <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
//                   Grievance Policy
//                 </NavLink>
//                 <NavLink
//                   to="/responsible"
//                   className="transition hover:text-gray-600"
//                 >
//                   <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
//                   Responsible Lending
//                 </NavLink>
//                 <NavLink to="/ROI" className="transition hover:text-gray-600">
//                   <FontAwesomeIcon icon={faArrowRight} className="pr-2" /> ROI
//                   Processing
//                 </NavLink>
//                 <NavLink
//                   to="/Refund"
//                   className="transition hover:text-gray-600"
//                 >
//                   <FontAwesomeIcon icon={faArrowRight} className="pr-2" />{" "}
//                   Refund and Cancellation
//                 </NavLink>
//               </div>
//             </div>
//           </div>
//           <div className="flex-1">
//             <div className="flex-1 bg-gray-900 p-5 rounded-lg">
//               <div className="flex gap-4 flex-col">
//                 <h1 className="font-semibold">Follow Us</h1>
//                 <NavLink
//                   to="https://www.facebook.com/plentycred"
//                   className="transition text-blue-400 hover:text-gray-600"
//                 >
//                   <FontAwesomeIcon icon={faFacebook} className="pr-2" />{" "}
//                   Facebook
//                 </NavLink>
//                 <NavLink
//                   to="https://www.instagram.com/plenty_cred/"
//                   className="transition text-purple-400 hover:text-gray-600"
//                 >
//                   <FontAwesomeIcon icon={faInstagram} className="pr-2" />{" "}
//                   Instagram
//                 </NavLink>
//                 <NavLink
//                   to="https://www.threads.net/@plenty_cred"
//                   className="transition hover:text-gray-600"
//                 >
//                   <FontAwesomeIcon icon={faThreads} className="pr-2" />{" "}
//                   Threads
//                 </NavLink>
//                 <NavLink
//                   to="https://www.youtube.com/channel/UCYqaLhxr4j7C-_dCe49Hfiw"
//                   className="transition text-red-500 hover:text-gray-600"
//                 >
//                   <FontAwesomeIcon icon={faYoutube} className="pr-2" />{" "}
//                   Youtube
//                 </NavLink>
//                 <br />
//                 <h1 className="font-semibold">Download the App Now</h1>
//                 <NavLink
//                   to="https://www.youtube.com/channel/UCYqaLhxr4j7C-_dCe49Hfiw"
//                   className="transition flex flex-row gap-2 bg-white text-black p-2 rounded-lg hover:bg-slate-800 hover:text-white"
//                 >
//                   <IoLogoGooglePlaystore size={20} />{" "}
//                   <span className="font-semibold text-sm">
//                     Download on Play Store
//                   </span>
//                 </NavLink>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="bg-gray-900">
//       <div id="wrapper">
//         <div className="flex flex-col lg:flex-row gap-4 justify-between gap-20px items-center">
//           <div className="flex-[1]">
//             <p className="font-semibold text-xl text-white">
//               Made with 💖 in India
//             </p>
//           </div>
//           <div className="flex-[1]">
//             <p className="font-semibold text-sm text-white">
//               Plentycred is a fully owned subsidiary of Arahant Economy
//               Services Private Limited. It is a digital platform that helps
//               customers borrow money for their needs at the click of a button.
//               All rights reserved by Arahant Economy Services
//               Private Limited. &copy; 2024
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   </>
// );
