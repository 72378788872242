import React, { useState } from "react";
import axios from "axios";

const AdminCibil26as = ({ userId }) => {
  const [asData, setAsData] = useState(null); //26as data

  const Check26 = async () => {
    const token = localStorage.getItem("token");

    try {
      const asData = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/admin/26as`,
        { id: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("As Data>>>>", asData);
      setAsData(asData.data.data);
    } catch (error) {
      console.error("Error fetching 26asData details:", error);
    }
  };

  return (
    <div className="mt-2 max-w-xl bg-white rounded-lg shadow-md border-[#1e0c27] border-b-[10px]">
      <div className="p-5 flex flex-row gap-4 bg-slate-900 rounded-lg">
        <div className="flex flex-col text-white gap-2 ">
          <div className="flex flex-row gap-4 items-center">
            <div>
              <button
                className="bg-purple-500 hover:bg-purple-600 py-3 px-4 text-white"
                onClick={Check26}
              >
                ITR 26as
              </button>
            </div>
            <h2 className="font-semibold text-white text-xl">
              Cibil 26as Data
            </h2>
          </div>
          <div className="opacity-0">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis
            dignissimos tenetur eum inventore. Pariatur in dol
          </div>
        </div>
      </div>
      <div className="p-4">
        <p className="mt-2 mb-2">
          <strong>26as Data:</strong>
        </p>
        <div className="p-2 mt-2 overflow-x-auto overflow-y-auto h-[40vh] ">
          <pre>
            {asData !== null ? JSON.stringify(asData, null, 2) : "No data"}
          </pre>
        </div>
      </div>
    </div>
  );
};

export default AdminCibil26as;
