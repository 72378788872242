import React, { useState, useEffect } from "react";

function useDeviceType() {
  const [deviceType, setDeviceType] = useState(null);

  useEffect(() => {
    const detectDeviceType = () => {
      const isMobile =
        /iPhone|iPad|iPod|Android|BlackBerry|Windows Phone/i.test(
          navigator.userAgent
        );
      const isTablet = /iPad|Android|Windows Phone/i.test(navigator.userAgent);

      if (isMobile) {
        setDeviceType("mobile");
      } else if (isTablet) {
        setDeviceType("tablet");
      } else {
        // Assuming it's a desktop if not mobile or tablet
        const screenWidth = window.innerWidth;
        const desktopThreshold = 1024; // Adjust this threshold as needed
        if (screenWidth < desktopThreshold) {
          setDeviceType("mobile");
        } else {
          setDeviceType("desktop");
        }
      }
    };

    detectDeviceType();

    // Add event listener to re-detect on resize
    window.addEventListener("resize", detectDeviceType);

    // Clean up
    return () => {
      window.removeEventListener("resize", detectDeviceType);
    };
  }, []);

  return deviceType;
}

export default useDeviceType;
