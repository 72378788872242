import React from "react";
import InfoPolicyStyle from "../core/css/tandc.module.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import {
    faFacebook,
    faInstagram,
    faThreads,
    faYoutube,
  } from "@fortawesome/free-brands-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SecurityPolicy = () => {
    return (
        <>
            <Header />
            <div className={InfoPolicyStyle.container}>
                <div className={InfoPolicyStyle.upperContainerLink}>
                    
        <NavLink onClick={()=>window.location.reload()} className={InfoPolicyStyle.linkStyle}>Home &gt;{" "}</NavLink>
                    <h3>Security Policy</h3>
                </div>
                <div className={InfoPolicyStyle.upperContainer}>
                    <h1>
                        PLENTYCRED'S  INFORMATION SECURITY POLICY
                    </h1>
                </div>
                <div className={InfoPolicyStyle.lowerContainer}>
                    <div id="wrapper">
                    <div className="flex flex-row items-center">
                        
        <NavLink to="#" className="transition hover:text-gray-600">
          <FontAwesomeIcon
            icon={faFacebook}
            size={30}
            color="blue"
            className="pr-2"
          />
        </NavLink>
        <NavLink
          to="https://www.instagram.com/plenty_cred/"
          className="transition hover:text-gray-600"
        >
          <FontAwesomeIcon
            icon={faInstagram}
            size={30}
            color="purple"
            className="pr-2"
          />
        </NavLink>
        <NavLink
          to="https://www.threads.net/@plenty_cred"
          className="transition hover:text-gray-600"
        >
          <FontAwesomeIcon icon={faThreads} size={30} className="pr-2" />
        </NavLink>
        <NavLink
          to="https://www.youtube.com/channel/UCYqaLhxr4j7C-_dCe49Hfiw"
          className="transition hover:text-gray-600"
        >
          <FontAwesomeIcon
            icon={faYoutube}
            size={30}
            color="red"
            className="pr-2"
          />
        </NavLink>
      </div>
                    <ul className={InfoPolicyStyle.innerLowerContainer}>
                        <li>
                            <h2>
                                INTRODUCTION
                            </h2>
                            <p>
                                Plentycred shall implement adequate security policies, procedures, and controls to protect the confidentiality, maintain integrity, and ensure the availability of information stored, processed, and transmitted through its information systems. This Information Security Policy is a key component of the overall information security management framework and should be considered alongside more detailed and organizational specific information security documentation including, system level security policies, security guidance protocols or procedures. Implementation of this policy will ensure adequate information security for our personnel.
                            </p>
                        </li>
                        <li>
                            <h2>
                                OBJECTIVE
                            </h2>
                            <p>
                                The objective of this policy is to protect Plentycred's information resources from accidental or intentional unauthorized access, modification, or damage, either via internal or external threat by enforcing appropriate controls with following objectives:
                            </p>
                            <ul style={{ marginLeft: "1em" }}>
                                <li>
                                    To ensure confidentiality, integrity, and availability of information assets.
                                </li>
                                <li>
                                    To ensure business continuity both in service production facilities and business
                                </li>
                                <li>
                                    To continuously analyze and identify information security risks relating to identified assets.
                                </li>
                                <li>
                                    To ensure all the members of staff have information security awareness.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h2>
                                PURPOSE
                            </h2>
                            <p>
                                The Purpose of this document is to protect and prevent organization from various threats, unauthorized disclosure, disruption, loss, access, use, or modification of organization’s information and its assets. This policy also aims to protect and follow the three principle of information security i.e. Confidentiality, Integrity and Availability.
                            </p>
                        </li>
                        <li>
                            <h2>
                                SCOPE
                            </h2>
                            <p>
                                The scope of Plentycred ISMS and the security policy contained in this document has been established to cover information, data and information systems such as software, hardware, firmware, storage and transmission media, the information in physical and electronic form and computer networks used by Plentycred. The Information Security Management System is applicable for Plentycred. This security policy applies to personnel who access Plentycred information or use Plentycred information systems. Personnel is defined as all employees, contractors and sub-contractors and onsite third-party vendors accessing Plentycred resources. The scope includes all the interested parties (internal and external) defined in the ISMS Roles and Responsibilities document. The scope of this ISMS helps us to identify the common internal and external issues pertaining to information security.
                            </p>
                        </li>
                        <li>
                            <h2>
                                RESPONSIBILITIES
                            </h2>
                            <p>
                                It is the responsibility of the below teams/functions to implement and maintain the controls defined in this policy.
                            </p>
                            <ul style={{ listStyleType: "disc", paddingLeft: "2em" }}>
                                <li>
                                    Information Security Team
                                </li>
                                <li>
                                    Information Technology Team
                                </li>
                                <li>
                                    Department Heads
                                </li>
                                <li>
                                    Team Leaders
                                </li>
                                <li>Employess</li>
                                <li>Third-Party</li>

                            </ul>
                        </li>
                        <li>
                            <h2>
                                POLICY
                            </h2>
                            <p>
                                This document represents the official mandate from Plentycred for its users of information and information assets so as to ensure confidentiality, integrity, and availability of the information assets in reference to the compliance requirements from regulatory agencies and relevant legal requirements. Following are policy statements from individual domains:
                            </p>
                        </li>
                        {/* </ul> */}
                        <h2 style={{ marginTop: "1em" }}>Organization of Information Security</h2>
                        <p>
                            Information security of the organization shall be in place to ensure the security of the systems on an ongoing basis and to support and sustain Plentycred's business vision. It provides a process and framework that will assess risk within and outside the organization by maintaining the security levels up to date.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>Human Resource Security</h2>
                        <p>
                            This includes security responsibilities in job definitions, contracts, monitoring during the employment as well as at the time of ending the employment, user training and responding to security incidents and malfunction of information assets. This policy also aims to rule out the possible options that can cause human errors, theft, fraud, misuse of facilities and assets.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>Asset Management</h2>
                        <p>
                            All assets associated with information and information processing facilities shall be identified and documented to indicate the ownership and importance, and shall be classified, used and protected in accordance with criticality and sensitivity.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>Access Control</h2>
                        <p>
                            Access to information shall be controlled to avoid unauthorized access and at the same time provides access to authorized users. The access control will be followed on a need-to-know basis and depending upon the roles and responsibilities.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>Physical and Environmental Security</h2>
                        <p>
                            The organization shall protect and minimize disruptions to office premises and equipment (IT and non-IT) from physical and environmental threats like theft, vandalism, natural disaster, man-made catastrophes, and accidental damage which may lead to disruption of business operations.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>Operations Security</h2>
                        <p>
                            Responsibilities and procedures for the management of the information system's environment shall be established to avoid the occurrence of a security incident, operational error or unauthorized access to information by protecting against data loss, malware attack and exploitation using technical vulnerabilities.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>Communications Security</h2>
                        <p>
                            There shall be network security controls implemented for internal or external networks to protect business information from unauthorized access and enable effective usage of various networking, communications and computing facilities.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>Incident Management</h2>
                        <p>
                            Information security events and behavior associated with information and/or systems need to be reported and responded appropriately to minimize the damage due to incidents.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>Change Management</h2>
                        <p>
                            The scope of change management includes all operating systems and applications in distributed systems environments. It applies to a wide range of change efforts, from the introduction of a new product or system, which has broad external and/or internal impacts, to a simple modification of an internal program with no or little visibility. Each change affecting activities, regardless of scope, must be integrated into the production environment in a systematic and controlled manner.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>Acceptable Usage</h2>
                        <p>
                            There shall be guidance available for acceptable and appropriate use of information assets by all staff. It also helps in preventing data breach that may occur because of inappropriate use of an organization's assets.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>Cryptography</h2>
                        <p>
                            The organization shall ensure proper and effective use of cryptography controls to protect the confidentiality, authenticity and/or integrity of information. These controls include encryption, digital signatures, SSL and HTTPS communication and proprietary compression.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>Backup and Restoration</h2>
                        <p>
                            The organization shall maintain backup and media security as per the business requirements. As per the business requirements, the data backup shall be done in an incremental way and regularly. Periodic restoration and test of such data should be performed.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>Supplier relationship</h2>
                        <p>
                            The organization shall require suppliers (outsourcing vendors, agents, third parties) who have access to information, to maintain due confidentiality and adopt such security procedures as advised by the organization from time to time. Supplier’s access to assets shall be restricted to the information that they require in completing the contracted work.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>Systems acquisition, Development</h2>
                        <p>
                            Appropriate security controls shall be defined for all new information systems, and enhancements to the existing information systems. The Information Security Team shall be involved in the relevant stages of the System Development Life Cycle (SDLC) to ensure that security controls requirements are defined and adhered to for new information systems or enhancements to existing ones.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>E-waste Management</h2>
                        <p>
                            The lifecycle of all IT assets spanning from acquisition to disposal shall be managed in a manner which conforms to sound environmental norms.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>Business Continuity Management</h2>
                        <p>
                            Adequate processes shall be in place to develop, maintain and test the plan for business continuity management to ensure availability of the organization's services.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>Compliance</h2>
                        <p>
                            All relevant statutory and regulatory requirements, which the organization must comply with, shall be explicitly defined, documented, and kept up to date. All relevant information security requirements shall be incorporated in contractual documents. Privacy and protection of personally identifiable information shall be ensured as per relevant laws, and regulations.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>Information Security in Project Management</h2>
                        <p>
                            The organization shall devise controls to embed information security and privacy in Project Management Life Cycle. Information Security controls shall be taken into consideration for all the organization's projects to achieve confidentiality, integrity and availability of information or resources during and after the project.
                        </p>
                        <h2 style={{ marginTop: "1em" }}>Information Security Risk Management</h2>
                        <p>
                            A risk management framework shall be established to manage the overall security exposure of the organization. Risk Assessment helps the organization to identify the gaps and place a control over it to avoid data breach.
                        </p>
                        <li>
                            <h2>
                                SECURITY AWARENESS
                            </h2>
                            <p>
                                The organization shall ensure that people using and managing information (including the senior management, middle management, end users, third-party consultants, and customers) must be adequately trained and made aware of all related aspects to improve the overall security posture of the organization. Regular training and awareness programs shall be arranged for the employees to keep them aware about the new threats and attacks.
                            </p>
                        </li>
                        <li>
                            <h2>
                                DISCIPLINARY ACTIONS FOR VIOLATIONS TO THE POLICY
                            </h2>
                            <p>
                                While Plentycred would like to respect the privacy of its personnel, it reserves the right to audit and/or monitor their activities and information stored, processed, transmitted, or handled by them using Plentycred information systems. Plentycred expects its personnel to comply with information security policies. If any personnel is found to be in breach of the security policies and procedures, appropriate disciplinary action should be taken. The disciplinary action shall be proportionate to the severity of the breach.
                            </p>
                            <p>
                                All instances of a security breach or non-compliance to the policy are reported to the Information Security Team (IST). Depending upon the severity of the breach, the Information Security Team shall further report it to senior management for further action. Violations including suspected violations shall be investigated and may recommend disciplinary action in accordance with Plentycred’s code of conduct, policies, or applicable laws.
                            </p>
                        </li>
                        <li>
                            <h2>
                                MANAGEMENT REVIEW MEETING
                            </h2>
                            <p>
                                Plentycred reviews and evaluates the performance of information security management system (ISMS) to improve it on a continual basis to protect - business and organizational information on a yearly basis.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <Footer />
        </>
    );
}


export default SecurityPolicy;