import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminNav";
import { NavLink, Outlet } from "react-router-dom";
import axios from "axios";
import AdminNavMobile from "./AdminNavMobile";

const AdminNavbar = () => {
  const token = localStorage.getItem("token");
  const [show, setShow] = useState("");
  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/v2/contracting/verify-all`,
        {},
        { headers }
      )
      .then((response) => {
        setShow(response.data.VerifyAllData);
        // console.logo("Data>>>>>>>>>>>>" , response);
      })
      .catch((error) => {
        console.error("Error sending POST request:", error);
      });
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <section className="flex flex-col xl:flex-row">
      {show.isAdmin && (
        <>
          <section className="xl:w-[250px]">
            {" "}
            {/* Adjust width as needed */}
            <div className="bg-[#1C2434] hidden xl:block">
              <AdminSidebar />
            </div>
            <div className="overflow-hidden xl:hidden mb-10">
              <AdminNavMobile />
            </div>
          </section>
        </>
      )}

      <section className="w-full xl:ml-[100px] lg:ml-[250px]">
        {" "}
        {/* Adjust margin to match sidebar width */}
        <Outlet />
      </section>
    </section>
  );
};

export default AdminNavbar;
