import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Header from "../components/Header";
import { Player } from "@lottiefiles/react-lottie-player";
import Footer from "../components/Footer";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import AboutTable from "../components/About/ElTable";
import gif3 from "../core/images/magic2.gif";

const ElPage = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <>
      <br />
      <br />
      <div id="wrapper">
        <div className="mt-12 rounded-[20px] bg-[rgb(220,255,245)] bg-[linear-gradient(90deg,_rgba(220,255,245,1)_0%,_rgba(255,255,255,1)_24%,_rgba(255,254,189,1)_48%,_rgba(255,232,172,1)_73%,_rgba(255,188,188,1)_100%)] p-10 flex flex-wrap justify-end flex-col gap-10 lg:flex-row items-center">
          <motion.div
            ref={ref}
            initial="hidden"
            animate="visible"
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: -100 },
            }}
            transition={{ duration: 1.3 }}
            className="flex-[1]"
          >
            <h1 className="text-4xl font-semibold">Eligibility Criteria</h1>
            <p className="mt-5 text-xl lg:text-2xl">
              To be listed on our platform, all borrowers are required to
              fulfill the following criteria:
            </p>
            <br />
            <NavLink
              to="/login"
              className="transition bg-gray-800 px-4 py-3 rounded-lg text-white font-semibold inline-block mt-2 hover:bg-white hover:text-gray-950"
            >
              Check Eligibility
            </NavLink>
            <NavLink to="/" className="font-semibold block mt-10">
              Find Out More <FontAwesomeIcon icon={faArrowDown} />
            </NavLink>
          </motion.div>
          <motion.div
            ref={ref}
            initial="hidden"
            animate="visible"
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: -100 },
            }}
            transition={{ duration: 1.3 }}
            className="flex-[1]"
          >
            <center>
              <img src={gif3} alt="GIF" className="w-[340px]" />
            </center>
          </motion.div>
        </div>
        <br />
        <br />
        <div className="flex p-2 flex-col justify-center">
          <h1 className="text-blue-600 font-semibold text-2xl">Student</h1>
          <h2 className="text-sm">
            In case of students, following eligibility points are applicable for
            the student and parents. Students also need to provide an
            authorization mandate, KYC details, salary slip and bank information
            of parents, upon which they will be alloowed a crediti limit of INR
            1,000 initially, which will keep increasing later based on past
            performance
          </h2>
        </div>
        <br />
        <AboutTable />
        <br />
        <br />
      </div>
      <Footer />
    </>
  );
};

export default ElPage;
