import React, { useState, useEffect } from "react";
import axios from "axios";
import NormalPhoto from "../../core/images/profileWithoutLive.png";

const UserLivePhoto = ({ userDetails }) => {
  const [locationId, setLocationId] = useState(null);
  const [imageURL, setImageURL] = useState(null);

  // useEffect(() => {
  //   if (!userDetails) return; 

  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_API_KEY}/v1/admin/live-photo`,
  //         { id: userDetails.id },
  //         {
  //           headers: {
  //             "X-admin-auth": "1234567890",
  //           },
  //         }
  //       );

  //       const locationId = response.data.data.LiveLocation;

  //       setLocationId(locationId);
  //       const storageRef = firebase.storage().ref();
  //       const imageRef = storageRef.child(locationId);
  //       const url = await imageRef.getDownloadURL();

  //       setImageURL(url);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       // If live photo doesn't exist, set imageURL to null
  //       setImageURL(null);
  //     }
  //   };

  //   fetchData();
  // }, [userDetails]); // Run effect whenever userDetails changes

  return (
    <div>
      {/* Conditional rendering based on whether imageURL is available */}

        <img width={300} height={300} src={NormalPhoto} alt="Normal Photo" />
    </div>
  );
};

export default UserLivePhoto;
