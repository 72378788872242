import React from "react";
import policyStyle from "../core/css/tandc.module.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import {
  faFacebook,
  faInstagram,
  faThreads,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Responsible = () => {
  return (
    <div className="bg-white">
      <Header />
      <br />
      <div id="wrapper" className="mt-20">
        <div className={policyStyle.upperContainerLink}>
          <NavLink
            onClick={() => window.location.reload()}
            className={policyStyle.linkStyle}
          >
            Home &gt;{" "}
          </NavLink>
          <h3>Refund And Cancellation Policy</h3>
        </div>
        <div className={policyStyle.upperContainer}>
          <h1>Responsible Lending</h1>
        </div>
        <div className="flex flex-row items-center">
          <NavLink
            onClick={() => window.location.reload()}
            className={policyStyle.linkStyle}
          >
            <FontAwesomeIcon
              icon={faFacebook}
              size={30}
              color="blue"
              className="pr-2"
            />
          </NavLink>
          <NavLink
            to="https://www.instagram.com/plenty_cred/"
            className="transition hover:text-gray-600"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              size={30}
              color="purple"
              className="pr-2"
            />
          </NavLink>
          <NavLink
            to="https://www.threads.net/@plenty_cred"
            className="transition hover:text-gray-600"
          >
            <FontAwesomeIcon icon={faThreads} size={30} className="pr-2" />
          </NavLink>
          <NavLink
            to="https://www.youtube.com/channel/UCYqaLhxr4j7C-_dCe49Hfiw"
            className="transition hover:text-gray-600"
          >
            <FontAwesomeIcon
              icon={faYoutube}
              size={30}
              color="red"
              className="pr-2"
            />
          </NavLink>
        </div>
        <div className={policyStyle.innerLowerContainer}>
          <h2>Our Commitment to Responsible Lending</h2>
          Plentycred is a lending short-term loans through its technology
          platform, Plentycred. As a responsible lender, we recognize the
          importance of meeting our customer’s financial needs and help them
          succeed financially. We believe in being a responsible business entity
          and hence thoroughly adhere to RBI’s fair practice codes and policies
          from time to time. Further, as regulated by the RBI, all loan
          transactions are in line with the stipulated rules and regulations.
          <br />
          <br />
          <h2>What you can expect from us as a responsible lender</h2>
          We strictly prohibit abusive, misleading, or fraudulent lending
          practices We do not misuse any information you provide during the
          application process
          <br />
          <br />
          Our products are advertised in a fair and clear manner thus ensuring
          that you understand of our key terms and conditions.
          <br />
          <br />
          We provide you with all the information you may need to make fully
          informed decisions about our loan products
          <br />
          <br />
          We clearly display all information on the cost of borrowing, fees and
          charges and our lending terms and conditions on our mobile application
          and on the website
          <br />
          <br />
          We always treat your personal information as confidential Our detailed
          FAQ section of the application and on the website provides easy to
          understand information on the queries you may have. Address your
          queries and complaints professionally within a stipulated time frame.
          <br />
          <br />
          Our loan recovery process adheres to RBI’s fair practice code We never
          indulge in sending fake legal notices from any government bodies or
          use logos or stamps of bodies such as RBI, CBI, UIDAI, Tax department
          etc.
          <br />
          <br />
          <h2>What we need from you as a borrower</h2>
          Always provide us with complete and accurate information on your
          application in order to assess your eligibility. Please make sure you
          fully understand the terms and conditions on which the money is
          borrowed. We urge you to seek further information, if needed.
          <br />
          <br />
          Please ensure timely repayments, in order to maintain a positive
          credit profile. Further it will help you to get credit faster on
          subsequent loans.
          <br />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Responsible;
