import React from "react";
import PaymentGateway from "./PaymentGateway";

const MainPayment = ({ token, userVerfiyAll, userAccount }) => {
  console.log("MainPayment ", {
    token,
    userVerfiyAll,
    userAccount,
  });
  return (
    <>
      <PaymentGateway
        userShow={token}
        userVerfiyAll={userVerfiyAll}
        userAccount={userAccount}
      />
    </>
  );
};

export default MainPayment;
