import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Header from "../components/Header";
import { Player } from "@lottiefiles/react-lottie-player";
import Footer from "../components/Footer";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import AboutTable from "../components/About/ElTable";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import Whyus1 from "../core/images/social-digital-wallet-in-mobile-app.gif";

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const features = [
  {
    name: "click for instant money transfer",
    image: "",
    description:
      "No rubbing the lamp for genie We offer swift access to funds with instant credit disbursal at the click of a button on whatsapp",
    color: "#FFA500", // orange
  },
  {
    name: "100% confidential",
    image: "",
    description:
      "Secret kept better than the Victoria's secret; We secure your data using 2048 bit Secure Sockets Layers (SSL) based encryption and treat it with utmost confidentiality",
    color: "#0000FF", // blue
  },
  {
    name: "Direct account transfer",
    image: "",
    description:
      "Cash me outside We transfer money directly to your bank account, so you can use it through UPI at any vendor without the hassle of cards or merchant rates",
    color: "#800080", // purple
  },
  {
    name: "Micro credits, max impact: ",
    image: "",
    description:
      "So that you can say Keep the change OR Helping you not be a Popatlaal ; We believe that no financial need is too small to be addressed, and thus we offer last-minute credits as small as INR 100",
    color: "#008000", // green
  },
  {
    name: "Adjustable tenure",
    image: "",
    description:
      "Mah credit, mah rules; We offer flexible repayment options so that you customize your loan tenure as per your needs, be it 7 days or 7 months",
    color: "#000", // black
  },
  {
    name: "From click to cash - 100% virtual",
    image: "",
    description:
      "Save paper, save earth; We offer on-the-go digital services, ensuring convenience at your fingertips",
    color: "#ff0000", // red
  },
];

const Whyus = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div id="wrapper">
      <br /><br />
      <section className="mt-12 rounded-[20px] bg-[rgb(220,255,245)] bg-[linear-gradient(90deg,_rgba(220,255,245,1)_0%,_rgba(255,255,255,1)_24%,_rgba(255,254,189,1)_48%,_rgba(255,232,172,1)_73%,_rgba(255,188,188,1)_100%)] p-10 flex flex-wrap justify-end flex-col gap-10 lg:flex-row items-center">
        <motion.div
          ref={ref}
          initial="hidden"
          animate="visible"
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: -100 },
          }}
          transition={{ duration: 1.3 }}
          className="flex-[1]"
        >
          <h1 className="text-4xl font-semibold">WHY US?</h1>
          <p className="mt-5 text-xl lg:text-2xl">
            The following pointers can either roll up in the same spot as we
            scroll down or can be listed one below the other across the page
          </p>
          <br />
          <NavLink to="/" className="font-semibold block mt-10">
            Find Out More <FontAwesomeIcon icon={faArrowDown} />
          </NavLink>
        </motion.div>
        <motion.div
          ref={ref}
          initial="hidden"
          animate="visible"
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: -100 },
          }}
          transition={{ duration: 1.3 }}
          className="flex-[1]"
        >
          <center>
            <img src={Whyus1} alt="GIF" className="w-[340px]" />
          </center>
        </motion.div>
      </section>
      <br />
      <br />
      <section>
        <motion.div
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 100 },
          }}
          transition={{ duration: 1 }}
          className="grid grid-cols-1 lg:grid-cols-2 justify-center xl:grid-cols-3 gap-12"
        >
          {features.map((feature) => (
            <div
              className={`max-w-2xl px-2 py-4 rounded-lg shadow-md bg-white flex items-center justify-center flex-col xl:flex-row gap-4 
             border-solid border-4`}
              style={{ borderColor: feature.color }}
            >
              <div className=" flex-[7]">
                <NavLink
                  to="/"
                  className="text-sm xl:text-base font-bold text-gray-700  hover:text-gray-600  hover:underline"
                  tabindex="0"
                  role="link"
                >
                  {feature.name}
                </NavLink>
                <p className="mt-2 text-xs  text-gray-600">{feature.description}</p>
              </div>
            </div>
          ))}
        </motion.div>
      </section>
      <br />
      <br />
      <section>
        <motion.div
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: -100 },
          }}
          transition={{ duration: 1.3 }}
          className="xl:p-0 p-1 table-fixed w-full overflow-x-auto max-w-full rounded-lg shadow-lg"
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="w-20 text-[10px] lg:text-base px-2 py-2 font-semibold border text-center ">
                  Feature
                </TableCell>
                <TableCell className="w-44 px-2 text-[10px] lg:text-base py-2 font-semibold border text-center ">
                  Credit Cards
                </TableCell>
                <TableCell className="w-60 px-2 text-[10px] lg:text-base py-2 font-semibold border text-center ">
                  Plentycred
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className="px-2 py-2 text-[10px] lg:text-base font-semibold border text-center ">
                  Micro credits
                </TableCell>
                <TableCell className="px-2 py-2 text-[10px] lg:text-base border text-center ">
                  Does not mostly cover small expenses, as is limited by credit
                  limit
                </TableCell>
                <TableCell className="px-2 py-2 text-[10px] lg:text-base border text-center ">
                  Micro credit starting INR 100, addressing the smallest needs
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="px-2 py-2 text-[10px] lg:text-base font-semibold border text-center ">
                  Interest rate
                </TableCell>
                <TableCell className="px-2 py-2 text-[10px] lg:text-base border text-center ">
                  High compound interest rates
                </TableCell>
                <TableCell className="px-2 py-2 text-[10px] lg:text-base border text-center ">
                  Only 1.8%* interest rate, per month (*other charges applied)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="px-2 py-2 text-[10px] lg:text-base font-semibold border text-center ">
                  Credit period
                </TableCell>
                <TableCell className="px-1 py-1 text-[10px] border text-center">
                  Promise 45 days credit period, but do not even credit for 30
                  days due to fixed credit cycles , forcing users to pay minimum
                  dues as per other companies
                </TableCell>
                <TableCell className="px-1 py-1 text-[10px] border text-center ">
                  Credit period starts on the very day of credit transfer, and
                  no minimum dues to be paid
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="px-2 py-2 text-[10px] lg:text-base font-semibold border text-center ">
                  Hidden charges and fines
                </TableCell>
                <TableCell className="px-2 py-2 text-[10px] lg:text-base border text-center ">
                  Enforces rigid payment cycles, imposing fines for missed
                  payments, in addition to undisclosed fees and charges
                </TableCell>
                <TableCell className="px-2 py-2 text-[10px] lg:text-base border text-center ">
                  No payment cycle, no hidden charges and no fine dues
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="px-2 py-2 text-[10px] lg:text-base font-semibold border text-center ">
                  Merchant rates
                </TableCell>
                <TableCell className="px-2 py-2 text-[10px] lg:text-base border text-center ">
                  Charges Merchant Discount Rate (MDR) which is most often
                  passed on to the customers, increasing costs for transactions
                </TableCell>
                <TableCell className="px-2 py-2 text-[10px] lg:text-base border text-center ">
                  No MDR charges
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="px-2 py-2 font-semibold border text-center ">
                  Repayment options
                </TableCell>
                <TableCell className="px-2 py-2 border text-center ">
                  Fixed schedules, often leading to debt traps
                </TableCell>
                <TableCell className="px-2 py-2 border text-center ">
                  Customizable repayment plans as per your capability
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="px-2 py-2 text-[10px] lg:text-base font-semibold border text-center ">
                  Usage convenience
                </TableCell>
                <TableCell className="px-2 py-2 text-[10px] lg:text-base border text-center ">
                  Can only be used in few big outlets with card machine
                </TableCell>
                <TableCell className="px-2 py-2 text-[10px] lg:text-base border text-center ">
                  100% digital, UPI friendly and can easily be transferred to
                  any other account
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </motion.div>
      </section>
    </div>
  );
};

export default Whyus;
