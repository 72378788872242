import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function FinancialDetails() {
  const { id } = useParams();
  const [item, setItem] = useState({});

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/v1/fcl/get-financial-dictionary/${id}`
      )
      .then((response) => {
        setItem(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching item details:", error);
      });
  }, [id]);

  return (
    <>
    <Header />
    <br />
    <br />
      <div id="wrapper">
        <div className="mt-12 rounded-[20px] bg-[rgb(220,255,245)] bg-[linear-gradient(90deg,_rgba(220,255,245,1)_0%,_rgba(255,255,255,1)_24%,_rgba(255,254,189,1)_48%,_rgba(255,232,172,1)_73%,_rgba(255,188,188,1)_100%)] p-10 flex flex-wrap justify-end flex-col gap-10 lg:flex-row items-center">
          <div className="container mx-auto p-4">
            <h2 className="text-3xl font-bold mb-4">{item.title}</h2>
            <p className="text-gray-700">{item.desc}</p>
            {item.summary && (
              <p className="mt-2 text-gray-600">{item.summary}</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
