import React, { useEffect } from "react";

import gif1 from "../../core/images/magic.gif";
import gif2 from "../../core/images/magic2.gif";
import gif3 from "../../core/images/magic3.gif";
import gif4 from "../../core/images/magic4.gif";
import gif5 from "../../core/images/magic5.gif";
import gif6 from "../../core/images/magic6.gif";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { NavLink } from "react-router-dom";

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const features = [
  {
    name: "Lower Interest Rates",
    image: gif1,
    description:
      "Get loans for multiple purposes at lower interest rates to suit your needs",
    color: "#FFA500", // orange
  },
  {
    name: "Fast Processing and Disbursal",
    image: gif2,
    description:
      "Apply online, check your eligibility and get money directly in your bank in 10 minutes",
    color: "#0000FF", // blue
  },
  {
    name: "Easy Repayment Options",
    image: gif3,
    description:
      "Repay the loan amount in easy EMI with flexible tenure options",
    color: "#800080", // purple
  },
  {
    name: "100% Paperless",
    image: gif4,
    description:
      "No paperwork or physical documentation is required, and you can apply and get a personal loan completely online.",
    color: "#008000", // green
  },
  {
    name: "Safe, Secure and Transparent",
    image: gif5,
    description:
      "Our loan application process is fully secured and safe and there are no hidden charges.",
    color: "#000", // black
  },
  {
    name: "Collateral Free",
    image: gif6,
    description:
      "No collateral is required to apply for our personal loans.",
    color: "#ff0000", // red
  },
];

const Home3 = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const randomColor = getRandomColor();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div className="max-w-screen-x mx-auto my-16">
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1 }}
        className="grid grid-cols-1 lg:grid-cols-2 justify-center xl:grid-cols-3 gap-12"
      >
        {features.map((feature) => (
          <div
            className={`max-w-2xl px-8 py-4 rounded-lg shadow-md bg-white flex items-center justify-center flex-col xl:flex-row gap-4 
             border-solid border-b-4`}
            style={{ borderColor: feature.color }}
          >
            <div className="hidden xl:block flex-[3] ">
              <img src={feature.image} alt="GIF" />
            </div>
            <div className=" xl:hidden flex-[3] ">
              <img src={feature.image} width={120} height={120} alt="GIF" />
            </div>
            <div className="mt-2 flex-[7]">
              <NavLink
                to="/"
                className="text-sm xl:text-xl font-bold text-gray-700  hover:text-gray-600  hover:underline"
                tabindex="0"
                role="link"
              >
                {feature.name}
              </NavLink>
              <p className="mt-2 text-xs xl:text-sm text-gray-600">
                {feature.description}
              </p>
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default Home3;
