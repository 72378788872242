import React from "react";
import Image from "../../core/images/PHoneArea.png";
import QRApp from "../../core/images/QR.jpg";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { NavLink } from "react-router-dom";

const Home4 = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const duration = 1;

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 100 },
  };

  return (
    <div className="flex flex-col gap-5 items-center justify-around lg:flex-row">
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={variants}
        transition={{ duration: duration }}
        className="flex flex-col justify-center items-center p-3 text-center gap-4 xl:gap-10"
      >
        <div>
          <h1 className="text-4xl font-bold text-blue-600 mb-4">
            The Cool New Vibe to PlentyCred
          </h1>
          <h3 className="text-lg text-gray-700">
            Download the PlentyCred app and get instant credit anywhere
          </h3>
        </div>

        <div className="flex flex-col justify-center items-center gap-3">
          <p className="text-sm">Scan QR to get PlentyCred App</p>
          <div className="flex justify-center  flex-col gap-4">
            <img src={QRApp} width={140} height={300} alt="" />
          </div>
          <NavLink
            to={
              "https://play.google.com/store/apps/details?id=com.application.plentycredapp"
            }
            style={{
              borderWidth: "2px",
              borderStyle: "solid",
              background: "linear-gradient(to right, #0b5ed7 , #06b6d4)", // Cyan to blue gradient
            }}
            className="border-[4px]  w-[300px] items-center flex flex-row gap-2 text-white p-2"
          >
            <img
              src="https://www.svgrepo.com/show/223032/playstore.svg"
              alt=""
              width={36}
              height={36}
            />
            <h1>Download on Play Store</h1>
          </NavLink>
        </div>
      </motion.div>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={variants}
        transition={{ duration: duration }}
      >
        <img src={Image} width={300} height={300} alt="" />
      </motion.div>
    </div>
  );
};

export default Home4;
