import React, { useState } from "react";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminAmountID = ({ userID }) => {
  const [alootedAmount, setAlootedAmount] = useState("");
  const [loanExtract, setLoanExtract] = useState("");

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/admin/amount`,
        {
          id: userID,
          alootedAmount: parseInt(alootedAmount),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Successfully Updated", { theme: "colored" });
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="flex flex-col xl:flex-row gap-3">
        <input
          type="number"
          name="alootedAmount"
          value={alootedAmount}
          onChange={(e) => setAlootedAmount(Number(e.target.value))}
          className="border border-gray-300 p-2"
          placeholder="Enter alooted amount"
        />
        <div className="hidden">
        <input
          type="number"
          name="loanExtract"
          value={loanExtract}
          onChange={(e) => setLoanExtract(Number(e.target.value))}
          className="border border-gray-300 p-2"
          placeholder="Enter loan extract"
        />
        </div>
        <button
          onClick={handleSubmit}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default AdminAmountID;
