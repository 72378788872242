import React from "react";
import policyStyle from "../core/css/tandc.module.css";
import { NavLink } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  faFacebook,
  faInstagram,
  faThreads,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GRPolicy = () => {
  return (
    <>
      <Header />
      <div className={policyStyle.container}>
        <div className={policyStyle.upperContainerLink}>
          <NavLink
            onClick={() => window.location.reload()}
            className={policyStyle.linkStyle}
          >
            Home &gt;{" "}
          </NavLink>
          <h3>Grievance Redressal Policy</h3>
        </div>
        <div className={policyStyle.upperContainer}>
          <h1>Grievance Redressal Policy</h1>
        </div>

        <div >
          <div className="p-2 flex flex-row items-center">
            <NavLink to="#" className="transition hover:text-gray-600">
              <FontAwesomeIcon
                icon={faFacebook}
                size={30}
                color="blue"
                className="pr-2"
              />
            </NavLink>
            <NavLink
              to="https://www.instagram.com/plenty_cred/"
              className="transition hover:text-gray-600"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                size={30}
                color="purple"
                className="pr-2"
              />
            </NavLink>
            <NavLink
              to="https://www.threads.net/@plenty_cred"
              className="transition hover:text-gray-600"
            >
              <FontAwesomeIcon icon={faThreads} size={30} className="pr-2" />
            </NavLink>
            <NavLink
              to="https://www.youtube.com/channel/UCYqaLhxr4j7C-_dCe49Hfiw"
              className="transition hover:text-gray-600"
            >
              <FontAwesomeIcon
                icon={faYoutube}
                size={30}
                color="red"
                className="pr-2"
              />
            </NavLink>
          </div>
          <div id="wrapper" className={policyStyle.lowerContainer}>
            <ul className={policyStyle.innerLowerContainer}>
              <li>
                <div>Definitions</div>
                <p>
                  “Applicable Laws” means all applicable laws, statutes,
                  enactments, acts of central or state legislature, ordinances,
                  rules, regulations, notifications, guidelines, directions,
                  directives, policies, circulars, decisions and any other
                  pronouncements issued in accordance with the Companies Act, or
                  any other law applicable to the jurisdiction of India by any
                  central, state, local, or other governmental, administrative
                  or regulatory authority exercising executive, legislative,
                  judicial, regulatory or administrative functions of or
                  pertaining to the government. “Board” means the board of
                  directors of amount to the end user.
                </p>
              </li>
              <li>
                <div>Background</div>
                <ul>
                  <li>
                    Plentycred is an entity engaged, amongst others, in
                    providing sourcing, referral, collection, and life cycle
                    management services for personal loan products offered by
                    plentycred time to time.
                  </li>
                  <li>
                    For the convenience of the Customers using Plentycred mobile
                    application, and to offer optimum support, Plentycred hasset
                    up a Grievance Redressal Mechanism for addressing Customer
                    complaints and grievances through a proper channelized
                    approach, including a review mechanism to promptly redress
                    all Customer grievances.
                  </li>
                </ul>
              </li>
              <li>
                <div>Objective</div>
                <p>Objectives of this Grievance Redressal Policy are to</p>
                <ul>
                  <li>
                    Treat all Customers fairly and consistently and conduct
                    operations in accordance with prevailing industry standards
                    and regulatory/statutory requirements
                  </li>
                  <li>
                    make Plentycred's redressal channels effective and
                    meaningful by putting in place a structured system to ensure
                    that Customer complaints are redressed seamlessly and well
                    within the committed timeframe
                  </li>
                </ul>
              </li>
              <li>
                <div>Overview</div>
                <p>
                  This Grievance Redressal Policy is divided into the following
                  parts :
                </p>
                <p>
                  Part 1 (Grievance Redressal Mechanism) describes the Grievance
                  Redressal Mechanism adopted by Plentycred
                </p>
                <p>
                  Part 2A provides a framework for registration and handling of
                  Customer complaints
                </p>
                <p>
                  Part 3 (Time Estimate) provides a time estimate for handling
                  and resolution of Customer complaints.
                </p>
                <p>
                  Part 4 (Review and Oversight) sets out the general terms and
                  conditions, and procedural aspects for evaluation and review
                  of this Grievance Redressal Policy.
                </p>
              </li>
              <div className={policyStyle.headerContainer}>
                <h1>PART 1 GRIEVANCE REDRESSAL MECHANISM</h1>
              </div>
              <li>
                <div>Principles</div>
                <p>
                  The principles governing the Grievance Redressal Mechanism are
                  as follows:{" "}
                </p>
                <ul>
                  <li>
                    to establish a robust Grievance Redressal Mechanism for
                    Plentycred.{" "}
                  </li>
                  <li>
                    to constantly devise newer and smarter mechanisms to receive
                    and redress Grievances.{" "}
                  </li>
                  <li>
                    to guide and enable all employees of Plentycred to work in
                    good faith and without prejudice to the interests of the
                    Customers.
                  </li>
                  <li>
                    {" "}
                    to prioritize redressal of Grievances of Customers with
                    disabilities.{" "}
                  </li>
                  <li>
                    . to redress the Customer’s Grievances arisen on account of
                    services provided by any outsourced agency
                  </li>
                  <li>
                    {" "}
                    to deal with all Grievances in a prompt, efficient, timely
                    and courteous manner.
                  </li>
                  <li>To always treat all Customers fairly</li>
                  <li>
                    to keep the Customer informed about the mechanism to address
                    their complaints, concerns, and grievances within Plentycred
                  </li>
                  <li>
                    to analyses and implement the feedback received from
                    Customers on a continuous basis
                  </li>
                  <li>
                    Redressal of Grievances of a Customer with disabilities will
                    be prioritized once a formal request is made and proof of
                    disability is furnished by such Customer. If any Customer
                    requests for hard copy of agreement, statements or any other
                    documents, a copy of which has already been provided to the
                    Customer in soft copy, such hard copy may be provided to the
                    Customer by Plentycred after levying, at their respective
                    discretion, a charge of a nominal amount.
                  </li>
                </ul>
              </li>
              <li>
                <div>
                  Team Sensitization on Handling Complaints The following
                  officers are responsible for handling the grievances of
                  Customers:
                </div>
                <ul>
                  <li>
                    Grievance Redressal Officer (“GRO”) 6.1.1. Appointment:
                    Plentycred shall appoint a GRO
                  </li>
                  <li>
                    . Role: The GRO has the responsibility to handle grievances
                    of the Customers
                  </li>
                  <li>
                    The GRO may be assisted by a team comprising of any
                    individuals as may deemed appropriate from time to time.
                  </li>
                  <ul style={{ listStyleType: "disc" }}>
                    The team handling Grievances from Customers:
                    <li>
                      undergoes training to ensure that the Grievances and
                      queries from a customer are handled in an appropriate
                      manner in accordance with this Grievance Redressal Policy;
                    </li>
                    <li>
                      are encouraged to work in a manner which helps Plentycred
                      in offering a first time, efficient and speedy resolution
                    </li>
                  </ul>
                </ul>
              </li>
              <div className={policyStyle.headerContainer}>
                <h1>PART 2 REGISTRATION OF COMPLAINTS</h1>
              </div>
              <li>
                <div>Complaints</div>
                <h3>
                  Manner of Registering Complaints A Customer can register
                  Grievances in accordance with the process set out in this
                  Grievance Redressal Policy. Plentycred follows a 'three level'
                  process for registering and addressing all Grievances as
                  described below
                </h3>
                <ul>
                  <li>Level-1 - Customer Service Team</li>
                  <li>
                    Approach A Customer can approach Plentycred by writing an
                    email to the Care teammates assist@plentycred.co.in “Contact
                    us” website FAQ from the date he/she has applied for a loan
                    from the Plentycred App
                  </li>
                  Acknowledgement
                  <li>
                    All Grievances received in the manner set out in paragraph
                    7.1.1 above (i.e., by e-mail or through the online
                    website/App complaint mechanism) are acknowledged by a
                    system generated response or via individual emails (to the
                    extent possible).
                  </li>
                  <li>
                    The Customer will be given a ticket bearing a reference
                    number for all future communication around the complaint.
                    The aspects relating to quoting the ticket number in future
                    communications is provided below
                  </li>
                  <li>
                    . The customer support team initiates action on the
                    Grievance and where necessary contacts the Customer for any
                    additional details as may be necessary to address the
                    Grievance
                  </li>
                  <li>
                    The Customers are kept informed of the action taken, the
                    progress while redressing grievances, and/or, the reasons
                    for delay if any, in redressing any Grievance
                  </li>
                  <li>
                    . The Customers are informed via e-mail the follow up action
                    and the turnaround times for complaint resolution
                  </li>
                  Resolution
                  <li>
                    All Grievances received are resolved within the timelines
                    set out in paragraph 9 below.
                  </li>
                  <li>
                    . In case any Grievance takes more than the specified
                    resolution time, the Customers are
                    <ul style={{ listStyleType: "disc" }}>
                      <li>intimated; a c c o r d i n g l y</li>
                      <li>
                        kept updated on the progress / status of the Grievance
                        on a periodic basis until the Grievance is resolved.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Customer Grievance Redress Escalation In case the Customer
                  </li>
                  <li>
                    does not receive a response within the timelines set out in
                    Level 1
                  </li>
                  <li>
                    is dissatisfied with the response received Plentycred;
                  </li>
                  <li>
                    The Customer may escalate the complaint to the next level as
                    indicated below by setting out a full description of the
                    Grievance and all necessary details and discrepancies due to
                    which the Grievance could not be resolved
                  </li>
                  <li>
                    The Customer can address the escalation to the below address
                    for escalations in accordance with paragraph
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className={policyStyle.lowerContainer}>
            <h1>Purpose</h1>
            <p>
              Customer complaints constitute an important voice of customer, and
              this policy details complaint handling through a structured
              grievance redressal framework. Complaint redressal is supported by
              a review mechanism, to minimize the recurrence of similar issues
              in future.
            </p>
            <p>
              The Grievance Redressal policy follows the following principles:
            </p>
            <ul style={{ marginLeft: "3em" }}>
              <li>Customers are treated fairly.</li>
              <li>
                Complaints raised by customers are dealt with courtesy and in a
                timely manner.
              </li>
              <li>
                Customers are informed of avenues to escalate their complaints
                within the organization, and their rights if they are not
                satisfied with the resolution of their complaints.
              </li>
              <li>
                The employees work in good faith and without prejudice, towards
                the interests of the customers.
              </li>
            </ul>
            <h1>INTERNAL MACHINERY TO HANDLE CUSTOMER COMPLAINTS</h1>
            <p>
              The Company has invested in the best-in-class CRM system to ensure
              timely resolution of the grievances. The system captures the
              complaints; follows TATs based on the nature of the query and
              escalates issues based on predefined TATs and as per the
              escalation matrix.
            </p>
            <p>
              Once captured in the CRM system, the customer care will be
              responsible for resolution of complaint/grievance to the
              customer’s satisfaction within a period of fourteen (7) working
              days. Every attempt will be made to offer the customer suitable
              and appropriate alternate solutions wherever possible. However, if
              the customer continues to remain dissatisfied with the resolution,
              (s)he can escalate the issue through the grievance redressal
              mechanism as referred below.
            </p>
            <h1>TIME FRAME</h1>
            <p>
              complaint depending upon the investigations which would be
              involved in resolving the same. Complaints are suitably
              acknowledged on receipt and the customers are informed of delays
              if any, in the resolution.
            </p>
            <h1>REVIEW AND MONITORING</h1>
            <p>
              Periodic review of monitoring of complaints, TATs, nature of
              complaints will be done to ensure that process loopholes, if any,
              are plugged and trends are checked.
            </p>
            <h1>TOUCH POINTS</h1>
            <p>
              Customer complaints constitute an important voice of customer, and
              this policy details complaint handling through a structured
              grievance redressal framework. Complaint redressal is supported by
              a review mechanism, to minimize the recurrence of similar issues
              in future. The customer can raise their concerns pertaining to the
              Plentycred and Plentycred Mobile Application (hereinafter referred
              as “Platform”) or to the lender who provides loan to the customer
              through the Platform in the following matters:
            </p>
            <h1>GRIEVANCE REDRESSAL MECHANISM OF THE PLATFORM</h1>
            <p>
              Customer can raise their concerns pertaining to the Platform, EMI
              schedule, Facility Type, Processing Fee and / or any other charges
              or any other concern related to the product to authorized
              representatives of the Company as below.
              <ul style={{ listStyleType: "disc", marginLeft: "3em" }}>
                <li>
                  <div>
                    <b>Grievance Redressal</b>
                  </div>
                  <h3>Contact: +91- 7058111852</h3>
                  <h3>Email: Plentycred.co.in</h3>
                  <p>
                    The Grievance Redressal Officer may be reached on the number
                    provided above anytime between 10:00 to 11:00 from Monday to
                    Saturday except public holidays or through the e - mail
                    address above. The Grievance Redressal Officer shall
                    endeavor to resolve the grievance within a period of (7)
                    fourteen days from the date of receipt of a grievance.
                  </p>
                </li>
                <li>
                  <div>
                    <b>Nodal Officer</b>
                  </div>
                  <p>
                    If the customer does not receive a response from the
                    Grievance Redressal Officer within 14 (fourteen) days of
                    making a representation, or if the customer is not satisfied
                    with the response received from the Grievance Redressal
                    Officer, the customer may reach the Nodal Officer on the
                    number below anytime between 10:00 to 11:00 from Monday to
                    Saturday except public holidays or write to the Nodal
                    Officer at the e-mail address below. The contact details of
                    the Nodal Officer are provided below.
                  </p>
                  <br />
                  <p>
                    Mr. Niraj Hemraj Shah Address: 505 near traffic park, <br />{" "}
                    Dharam Peth extension Nagpur Maharashtra India 440010.
                  </p>
                  <p>
                    Contact: +91- 7058111852 <br />
                    <b> Email: Plentycred.co.in </b>
                  </p>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GRPolicy;
