import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const AdminContactReply = ({ id }) => {
  const [formData, setFormData] = useState({
    subject: "",
    message: "",
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v2/contact/reply/${id}`,
        {
          id: id,
          subject: formData.subject,
          message: formData.message,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Assuming the auth token is stored in localStorage
          },
        }
      );

      if (response.status === 200) {
        toast.success("Reply sent successfully!");
        setFormData({ subject: "", message: "" });
        setIsPopupOpen(false); // Close the popup after successful submission
      }
    } catch (error) {
      toast.error("Failed to send reply. Please try again.");
    }
  };

  return (
    <div>
      <button
        onClick={() => setIsPopupOpen(true)}
        className="bg-blue-600 text-white font-semibold px-4 py-2 rounded hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
      >
        Reply
      </button>

      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
            <h2 className="text-2xl font-bold mb-4">Reply to Contact</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="subject"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  required
                  className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="message"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  className="border border-gray-300 rounded px-4 py-2 w-full h-32 resize-none focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setIsPopupOpen(false)}
                  className="bg-gray-400 text-white font-semibold px-4 py-2 rounded mr-2 hover:bg-gray-500 focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-600 text-white font-semibold px-6 py-2 rounded hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                >
                  Send Reply
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminContactReply;
