import React from "react";
import policyStyle from "../core/css/tandc.module.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import {
  faFacebook,
  faInstagram,
  faThreads,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className={policyStyle.container}>
        <div className={policyStyle.upperContainerLink}>
          <NavLink onClick={()=>window.location.reload()} className={policyStyle.linkStyle}>
            Home &gt;{" "}
          </NavLink>
          <h3>Privacy Policy</h3>
        </div>
        <div className={policyStyle.upperContainer}>
          <h1>DOSSIER PRIVACY POLICY</h1>
        </div>
        {/* <div className={policyStyle.upperContainer}>
        <h1>
            This Policy is applicable to Plentycred
        </h1>
    </div> */}
        <div id="wrapper">
        <div className="flex flex-row items-center">
        <NavLink onClick={()=>window.location.reload()} className={policyStyle.linkStyle}>Home &gt;{" "}
          <FontAwesomeIcon
            icon={faFacebook}
            size={30}
            color="blue"
            className="pr-2"
          />
        </NavLink>
        <NavLink
          to="https://www.instagram.com/plenty_cred/"
          className="transition hover:text-gray-600"
        >
          <FontAwesomeIcon
            icon={faInstagram}
            size={30}
            color="purple"
            className="pr-2"
          />
        </NavLink>
        <NavLink
          to="https://www.threads.net/@plenty_cred"
          className="transition hover:text-gray-600"
        >
          <FontAwesomeIcon icon={faThreads} size={30} className="pr-2" />
        </NavLink>
        <NavLink
          to="https://www.youtube.com/channel/UCYqaLhxr4j7C-_dCe49Hfiw"
          className="transition hover:text-gray-600"
        >
          <FontAwesomeIcon
            icon={faYoutube}
            size={30}
            color="red"
            className="pr-2"
          />
        </NavLink>
      </div>
          <div className={policyStyle.lowerContainer}>
            <ul className={policyStyle.innerLowerContainer}>
              <li>
                <div>Copyrights</div>
                <p>
                  All rights reserved 2024. No part of this document may be
                  reproduced or transmitted in any form and by any means without
                  the prior permission of to Plentycred
                </p>
              </li>
              <li>
                <div>Preface</div>
                <p>
                  This Dossier Privacy Policy sets forth the reasonable security
                  practices and procedures adopted by to Plentycred and shall
                  apply to use and disclosure and sharing of Client's Dossier on
                  the website/ web application, mobile application or otherwise
                  and in scope includes Cloud IT environment as well
                  Plentycred". This privacy policy shall be read in conjunction
                  with the terms of use agreed by the Client while registering
                  with to Plentycred for availing its Services.
                </p>
              </li>
              <li>
                <div>Important Definitions</div>
                <p>
                  Client shall mean any individual who has visits on Plentycred
                  Mobile App or Web based application or any other Plentycred
                  facts resource or is a registered user or a borrower of to
                  Plentycred.
                </p>
                <p>
                  Client can request and ask us to cancel the services as well
                  as delete all the data colllected at any point of time by
                  sending us a request through email 24/7. Request will be
                  responded within 24 HRS.
                </p>
                <p>
                  Dossier for the purpose of this Policy (except where
                  specifically defined otherwise) shall mean and include all
                  facts and details supplied by the Client or accessed by to
                  Plentycred or Third Party as per the Consent provided
                  including sensitive personal Dossier/ facts that identifies
                  individuals, such as individual's Name, Address, Date of
                  birth, Bank Account details, Phone number, Fax number or Email
                  address.
                </p>
                <p>
                  Services means any of the service(s) that are provided by to
                  Plentycred to its clients and/or users by way of any to
                  Plentycred facts resources including lending services.
                </p>
                <p>
                  Third Party shall mean and include to Plentycred group
                  entity(ies), third party vendors (of any nature whatsoever)
                  and/or consultants, advisors, partners, banks, financial
                  institutions, credit bureau/ agencies, identity authenticating
                  agencies (NSDL, UIDAI, State Transport Department, etc.,)
                  regulatory or statutory bodies to Plentycred shall obtain a
                  Client's consent in writing or electronically accessed by to
                  Plentycred or Third Party as per the Consent provided by the
                  Client to Access the Dossier with an audit trail of the
                  purpose/ usage to provide Services under the terms of use.
                  This personal Dossier collected from Clients is solely for the
                  Purposes provided under this Policy and the Consent provided
                  by the Client. Access, storage and usage of Dossier by to
                  Plentycred shall be as permitted/ allowed under extant
                  statutory and regulatory guidelines. To this Policy, 'Access'
                  shall mean and include access, collection, storage, sharing,
                  monitoring.
                </p>
                <p>
                  Dossier shall be collected on need basis and as provided will
                  be on 'as on basis'. to Plentycred shall not be responsible
                  for unverified personal facts or Dossier supplied by Clients.
                </p>
                <p>
                  Additionally, in case of Digital Lending (as defined under RBI
                  Digital Lending Guidelines, September 2, 2022), Plentycred
                  shall desist from accessing mobile phone resources like file
                  and media, contact list, call logs, telephony functions, etc.,
                  except if needed for the purpose of providing Services to
                  Clients or when an express Consent is provided by the Client.
                  Further, to onboard the Client using mobile or web application
                  and KYC regulatory requirements, mobile resource access for
                  camera, microphone, location or any other facility necessary,
                  shall be requested from the Client. Clients have the option to
                  not agree to the collection of Dossier. Clients can also at
                  any time withdraw the consent and delete/ forget Dossier by
                  uninstallation of the application from the device of the
                  Client.
                </p>
              </li>
            </ul>
            <ul
              className={policyStyle.innerLowerContainer}
              style={{ listStyleType: "none" }}
            >
              <li>
                <div>Purpose and Usage of Dossier:</div>
              </li>
              <p>
                to Plentycred requires Client's Dossier to operate and provide
                various Services. to Plentycred may Access, share, transfer or
                use the Dossier only for the purpose as follows:
              </p>
              <br />
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  Dossier includes Contact List and Location of app users only
                  to make their user experience better and will not be shared with
                  any 3rd Party.
                </li>
                <li>
                  To fulfil the Client requests for products and services
                  offered and subscribed and accepted by Client.
                </li>
                <li>
                  To deliver to Client any administrative notices, alerts,
                  advice, and communications relevant to Client's use of the
                  Service
                </li>
                <li>
                  Share Client's Dossier with any Third Parties in so far as
                  required for purpose of providing additional Services and / or
                  to similar Services to provide Client with various value-added
                  Services.
                </li>
                <li>
                  For market research, project planning, troubleshooting
                  problems, detecting, and protecting against error, fraud or
                  other criminal activity.To Third-Party contractors that
                  provide services to to Plentycred are bound by similar privacy
                  restrictions.
                </li>
                <li>
                  Verifying Client identity and address details and find nearest
                  branches.
                </li>
                <li>
                  Enabling secure downloads and uploads of documents related to
                  loan, KYC, otherwise.
                </li>
                <li>
                  Managing phone calls and SMS/texts, Sending reminders, etc.
                </li>
                <li>
                  Analysing Client's financial position/credit risk assessment/
                  credit appraisal.
                </li>
                <li>
                  “Know Your Client” (KYC) requirements like verification or
                  authentication (any nature); Dossier enrichment; Making
                  personalized offers of products and services, credit rating,
                  promotion, or marketing of to Plentycred product or its group
                  companies or Third Party. For the purposes connected with
                  Plentycred 'Terms of Use' on any of to Plentycred facts
                  resource. To undertake activities as may be permitted under
                  law/regulations/directions/guidelines or by any authority or
                  under any license or registration of to Plentycred
                </li>
                <li>
                  The Dossier so collected shall be used only for the purpose
                  otherwise as may be expressly consented by the Client.
                </li>
                <li>
                  All such Dossier collected shall be retained so long as
                  Clients are having account with Plentycred and remain active
                  to avail various services and/or as may be required under the
                  law or by any authority. In case of deactivation / termination
                  of the account / services with us, the Dossier provided will
                  no longer be used unless consented for.
                </li>
              </ul>

              <li>
                <div>Update of Dossier:</div>
                <p>
                  Plentycred encourages the Clients to update these facts as and
                  when there are any changes. The Client is also entitled to
                  review the facts provided and ensure that any Dossier/
                  personal facts found to be inaccurate or deficient be
                  corrected or amended as feasible. However, Plentycred shall
                  not be responsible for unverified, inaccurate, or un-updated
                  Dossier supplied or Accessed from the Clients.
                </p>
              </li>
              <li>
                <div>Sharing/Transfer/Disclosure of Dossier:</div>
                <p>
                  Dossier of the Clients will not be sold or otherwise
                  transferred to unaffiliated third parties except if otherwise
                  stated at the time of collection/Access or under Consent
                  obtained from the Client or as required under law. However,
                  Plentycred can share, exchange and disclose Dossier of the
                  Client to Third Parties with prior consent of the Client or as
                  may be permitted under applicable laws.
                </p>
                <p>
                  Plentycred treats Client's Dossier as private and confidential
                  and does not check, edit, or reveal it to any third parties
                  except as provided under this Policy or where it is expressly
                  agreed and where it believes in good faith, such action is
                  necessary to comply with the applicable legal and regulatory
                  processes or to enforce the terms of service. Plentycred may
                  disclose personal facts where it is under legal obligation to
                  do so or where it is mandated under law or directed by any
                  authority. Subject to the provisions of this Policy,
                  Plentycred may transfer Dossier to another Indian or overseas
                  body corporate that ensures the same level of Dossier
                  protection that is adhered to by to Plentycred , if it is
                  necessary for the performance of a lawful contract between to
                  Plentycred or any person on Client's behalf or where Client
                  have consented to the Dossier transfer.
                </p>
                <p>
                  Lending Service Providers (as defined under law) or Third
                  Parties are also bound by a contractual obligation to ensure
                  confidentiality of shared Dossier and to comply with various
                  technology standards/ requirements on cybersecurity stipulated
                  by RBI and other authorities, as may be specified from time to
                  time.
                </p>
                <p>
                  Details of Lending Service Providers (where and as applicable)
                  allowed to collect Dossier through the web- based or mobile
                  application is as available on www. Plentycred.co.in.
                </p>
              </li>
              <li>
                <div>Unsolicited Facts:</div>
                <p>
                  Except where specifically agreed or necessary for operational
                  or regulatory reasons, to Plentycred will not send the Client
                  any unsolicited facts. However, to help the Client to take
                  full advantage of the service offerings to Plentycred, the
                  Client will have the opportunity to indicate whether the
                  individual would like to "opt out" of receiving promotional
                  and/or marketing facts about other products, services, and
                  offerings from to Plentycred and/or any Third Parties etc. If
                  the Client does not opt out, to Plentycred may use any email
                  addresses of Client to send occasional emails pertaining to
                  the said facts. The Client can nevertheless unsubscribe from
                  receipt of such emails by following instructions therein or by
                  communicating accordingly to to Plentycred
                </p>
              </li>
              <li>
                <div>Storage and Retention of Dossier:</div>
                <p>
                  To Plentycred shall retain Dossier of Client as may be
                  required to carry out their operations under applicable laws/
                  regulations/licenses or under the Consent provided by the
                  Client. The Dossier will be stored only in servers located
                  within India and shall be retained for a period of 10 years
                  from the date it is Accessed or obtained by to Plentycred or
                  so long as Clients are having account with to Plentycred and
                  remain active to avail various services, whichever is later.
                  Subject to as provided in this provision/clause and only if
                  required under any of the applicable laws to Plentycred will
                  purge the Dossier on the Client exercising its right to
                  delete/forget the Dossier or at the end of the tenure of the
                  loan/contract, whichever is later.
                </p>
              </li>
              <li>
                <div>
                  Disposal, Destruction and Redaction of Dossier to Plentycred
                </div>
                <p>
                  Dossier Retention and Disposal require managerial approval for
                  the disposal, destruction, and deletion of any Dossier. Our
                  disposal, destruction and redaction procedures prevent the
                  recovery, theft, misuse, or unauthorized access of Dossier.
                  The same is governed under existing organization's policies/
                  procedures.
                </p>
              </li>
              <li>
                <div>Use of Cookies:</div>
                <p>
                  to Plentycred websites may use "cookies" (facts stored on an
                  individual's computer by an individual's browser at our
                  request). "Cookies" is a term generally used for small text
                  files a web site can use to recognize repeat users, facilitate
                  the user's ongoing access to and use of the site, allow a site
                  to track usage behaviour and compile aggregate Dossier that
                  will allow content improvements and targeted advertising etc.
                  Cookies themselves do not personally identify individuals but
                  they do identify individual’s computers or devices. Generally,
                  cookies work by assigning a unique number to the user
                  computer/device that has no meaning outside the assigning
                  site. Users are also being made aware that to Plentycred
                  cannot control the use of cookies or the resulting facts by
                  advertisers or third parties hosting Dossier on to Plentycred
                  website. If a user does not want facts collected using
                  cookies, there is a simple procedure in most browsers that
                  allows the user to deny or accept the cookie feature.
                </p>
              </li>
              <li>
                <div>Security of Dossier</div>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    to Plentycred deals with mostly personal identifiable facts
                    (PII) of users, it becomes imperative to protect such facts
                    as soon as they are in the to Plentycred network. The
                    ownership of the Dossier lies with the skilled IT Function
                    Team. Below are the security measures taken to prevent
                    misuse of this type of facts.
                  </li>
                  <li>
                    to Plentycred has implemented physical, administrative, and
                    technical security measures across the organization which
                    are designed to prevent Dossier loss, unauthorized Access to
                    Dossier and misuse, disclosure, alteration, damage, or
                    destruction of Dossier.
                  </li>
                  <li>
                    Sensitive PII Dossier are encrypted at rest using strong
                    encryption algorithms. The keys used for such encryption
                    algorithms are stored securely and access to keys are
                    restricted to authorized entities only.
                  </li>
                  <li>
                    to Plentycred fully understands that the Dossier collected
                    from individuals is under our guardianship. Therefore, to
                    Plentycred trains its employees on the privacy policy as
                    well as facts security procedures regarding the appropriate
                    access, use, and disclosure of Dossier.
                  </li>
                  <li>
                    to Plentycred also conducts periodic risk assessments on the
                    processes and facts systems and audits of material
                    third-party vendors dealing with Dossier of the Client/user.
                  </li>
                  <li>
                    to Plentycred also conducts third party audit & assessment
                    on periodic intervals for material vendors.
                  </li>
                  <li>
                    to Plentycred has in place an incident response plan with
                    trained personnel to respond to, investigate and mitigate
                    the impact of any incident.
                  </li>
                  <li>
                    to Plentycred also maintains adequate plans for business
                    continuity management, as well as disaster recovery
                    processes for testing Dossier bases, servers, facts systems
                    and processes that handle personal Dossier.
                  </li>
                  <p>
                    This Dossier Privacy policy shall be read in conjunction to
                    Plentycred Facts Security and Facts technology policies and
                    procedures, as may be existing and applicable.
                  </p>
                </ul>
              </li>
              <li>
                <div>Quality</div>
                <p>
                  to Plentycred informs individuals/users that it is the
                  responsibility of the individuals/ users to provide accurate,
                  complete, and relevant facts in order to maintain the quality
                  and integrity of the Dossier available with to Plentycred.
                  Individuals/Client may to Plentycred designated personnel and
                  have the personal facts/Dossier amended or deleted, as
                  required to ensure accuracy.
                </p>
              </li>
              <li>
                <div>Compliance and Reporting</div>
                <p>
                  to Plentycred is committed to comply with this Policy and with
                  applicable privacy laws, regulations and applicable guidelines
                  from authorities to Plentycred conducts regular audits of our
                  compliance with applicable privacy policies, procedures, laws,
                  regulations, contracts, and standards under applicable
                  regulations.
                </p>
              </li>
              <li>
                <div>Change in Privacy Policy</div>
                <p>
                  to Plentycred reserves the right to change Privacy Policy at
                  any time. Users/Clients may note that this Policy itself and
                  any such change of Policy will be effective from the date of
                  posting on www.Plentycred.co.in..and shall be considered
                  disclosed to the users/Clients.
                </p>
              </li>
              <li>
                <div>Grievances</div>
                <p>
                  For any grievances redressal, users/Clients may note the
                  redressal process under provided the Grievance Redressal
                  policy available on www.Plentycred.co.in.
                </p>
              </li>
              <li>
                <div>Protecting Personal Dossier of Aadhar Number Holders:</div>
                <p>
                  Subject to obtaining the Authentication Requesting Agency /
                  KYC User Agency license from UIDAI (“the License”), to
                  Plentycred will be permitted to directly use the online
                  Dossier base of Aadhar to verify the identity of the Clients
                  including the photograph seamlessly for the purpose of
                  carrying out the KYC of its Clients/borrowers before
                  onboarding. The provisions governing privacy and protection of
                  personal date of the Aadhar Number Holders (Aadhar Holders
                  Personal Dossier) is detailed under Annexure A of this Policy.
                  It may be noted that if any provision(s) of the Policy and
                  Annexure A mean, read or interpreted differently, the
                  provisions of Annexure A shall prevail for the purposes of
                  governing the personal Dossier of Aadhar Number Holders.
                </p>
                <p>
                  The Annexure A shall stand effective from the date of receipt
                  of the License.
                </p>
              </li>
              <li>
                <div>Review / Revision of Policy</div>
                <p>
                  If at any point a conflict of interpretation / facts between
                  the Policy and any regulations, rules, guidelines,
                  notification, clarifications, circulars, master circulars/
                  directions issued by relevant authorities (“Regulatory
                  Provisions”) arises, then interpretation of the Regulatory
                  Provisions shall prevail. In case of any amendment(s) and/or
                  clarification(s) to the Regulatory Provisions, the Policy
                  shall stand amended accordingly from the effective date
                  specified as per the Regulatory Provisions.
                </p>
              </li>
            </ul>
            <ul className={policyStyle.innerLowerContainer}>
              <h1>Annexure A</h1>
              <h3>
                The purpose of this Annexure is to provide direction to the
                responsible personnel within the Company to protect the personal
                Dossier of Aadhar number holders in compliance to the relevant
                provisions of the Aadhar Act, 2016; the Aadhar and Other Laws
                (Amendment) Act, 2019; the Aadhar (Authentication) Regulations,
                2016; the Aadhar (Dossier Security) Regulations; the Aadhar
                (Sharing of Facts) Regulations, 2016; and the Facts Technology
                Act, 2000, and regulations thereunder.
              </h3>
              <li>
                <div>Definitions</div>
                <ul
                  style={{
                    listStyleType: "disc",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1em",
                  }}
                >
                  <li>
                    “Aadhar number” means an identification number issued to an
                    individual under sub-section (3) of section 3, and includes
                    any alternative virtual identity generated under sub-section
                    (4) of that section.
                  </li>
                  <li>
                    “Aadhar Dossier Vault” (ADV) means a separate secure Dossier
                    base/vault/system where the entities mandatorily store
                    Aadhar numbers and any connected Dossier such that it will
                    be the only place where the said Dossier will be stored.
                  </li>
                  <li>
                    "Anonymization" in relation to personal Dossier, means such
                    irreversible process of transforming or converting personal
                    Dossier to a form in which an individual cannot be
                    identified, which meets the standards of irreversibility.
                  </li>
                  <li>
                    “Authentication” means the process by which the Aadhar
                    number along with demographic facts or biometric facts of an
                    individual is submitted to the Central Identities Dossier
                    Repository for its verification and such Repository verifies
                    the correctness, or the lack thereof, based on facts
                    available with it.
                  </li>
                  <li>
                    “Authentication Service Agency” or “ASA” shall mean an
                    entity providing necessary infrastructure for ensuring
                    secure network connectivity and related services for
                    enabling a requesting entity to perform authentication using
                    the authentication facility provided by the Authority.
                  </li>
                  <li>
                    “Authentication User Agency” or “AUA” means a requesting
                    entity that uses the Yes/ No authentication facility
                    provided by the Authority.
                  </li>
                  <li>
                    “Authority” means the Unique Identification Authority of
                    India established under sub-section (1) of section 11 of the
                    Aadhar (Targeted Delivery of Financial and other Subsidies,
                    Benefits and Services) Act, 2016.
                  </li>
                  <li>
                    “Biometric facts” means photograph, fingerprint, iris scan,
                    or such other biological attributes of an individual as may
                    be specified by regulations.
                  </li>
                  <li>
                    “Central Identities Dossier Repository” (CIDR) means a
                    centralized Dossier base in one or more locations containing
                    all Aadhar numbers issued to Aadhar number holders along
                    with the corresponding demographic facts and biometric facts
                    of such individuals and other facts related
                  </li>
                  <li>
                    "Consent" means
                    <ul style={{ marginLeft: "2em", listStyleType: "disc" }}>
                      <li>
                        the personal Dossier shall not be processed, except on
                        the consent given by the Dossier principal at the
                        commencement of its processing.
                      </li>
                      <li>
                        The consent of the Dossier principal shall not be valid,
                        unless such consent is—
                        <ul style={{ listStyleType: "disc" }}>
                          <li>
                            free, having regard to whether it complies with the
                            standard specified under section 14 of the Indian
                            Contract Act, 1872;
                          </li>
                          <li>
                            informed, having regard to whether the Dossier
                            principal has been provided with the facts required
                            under section 7;
                          </li>
                          <li>
                            specific, having regard to whether the Dossier
                            principal can determine the scope of consent in
                            respect of the purpose of processing.
                          </li>
                          <li>
                            clear, having regard to whether it is indicated
                            through an affirmative action that is meaningful in
                            a given context; and
                          </li>
                          <li>
                            capable of being withdrawn, having regard to whether
                            the ease of such withdrawal is comparable to the
                            ease with which consent may be given.
                          </li>
                        </ul>
                      </li>
                      <li>
                        In addition to the provisions contained in sub-section
                        (2), the consent of the Dossier principal in respect of
                        processing of any sensitive personal Dossier shall be
                        explicitly obtained—
                        <ul style={{ listStyleType: "disc" }}>
                          <li>
                            after informing him the purpose of, or operation in,
                            processing which is likely to cause significant harm
                            to the Dossier principal.
                          </li>
                          <li>
                            in clear terms without recourse to inference from
                            conduct in a context; and
                          </li>
                          <li>
                            after giving him the choice of separately consenting
                            to the purposes of, operations in, the use of
                            different categories of, sensitive personal Dossier
                            relevant to processing.
                          </li>
                        </ul>
                      </li>
                      <li>
                        The provision of any goods or services or the quality
                        thereof, or the performance of any contract, or the
                        enjoyment of any legal right or claim, shall not be made
                        conditional on the consent to the processing of any
                        personal Dossier not necessary for that purpose.
                      </li>
                      <li>
                        The burden of proof that the consent has been given by
                        the Dossier principal for processing of the personal
                        Dossier under this section shall be on the Dossier
                        fiduciary.
                      </li>
                      <li>
                        Where the Dossier principal withdraws his consent from
                        the processing of any personal Dossier without any valid
                        reason, all legal consequences for the effects of such
                        withdrawal shall be borne by such Dossier principal.
                      </li>
                    </ul>
                  </li>
                  <li>
                    “Hardware Security Module (HSM)” means a device that will
                    store the keys used for digital signing of Auth XML and
                    decryption of e-KYC response Dossier received from UIDAI.
                  </li>
                  <li>
                    “Identity facts” in respect of an individual, includes his
                    Aadhar number, his biometric facts and his demographic
                    facts.
                  </li>
                  <li>
                    “Limited KYC” means the service that does not return Aadhar
                    number and only provides an agency specific unique UID Token
                    along with other demographic fields that are shared with the
                    Local AUAs depending upon its need.
                  </li>
                  <li>
                    “PID Block” means the Personal Identity Dossier element
                    which includes necessary demographic and/or biometric and/or
                    OTP collected from the Aadhar number holder during
                    authentication.
                  </li>
                  <li>
                    “Personal Dossier” in respect of and for the purpose of
                    protecting personal Dossier of Aadhar Number holders shall
                    mean the Dossier relating to a natural person who is
                    directly or indirectly identifiable, having regard to any
                    characteristic, trait, attribute or any other feature of the
                    identity of such natural person, whether online or offline,
                    or any combination of such features with any other facts,
                    and shall include any inference drawn from such Dossier for
                    the purpose of profiling;
                  </li>
                  <li>
                    “Personnel” means all the employees, staff and other
                    individuals employed/contracted by the requesting entities.
                  </li>
                  <li>
                    "Processing" in relation to personal Dossier, means an
                    operation or set of operations performed on personal
                    Dossier, and may include operations such as collection,
                    recording, organisation, structuring, storage, adaptation,
                    alteration, retrieval, use, alignment or combination,
                    indexing, disclosure by transmission, dissemination or
                    otherwise making available, restriction, erasure or
                    destruction.
                  </li>
                  <li>
                    "Resident" means an individual who has resided in India for
                    a period or periods amounting in all to one hundred and
                    eighty-two days or more in the twelve months immediately
                    preceding the date of application for enrolment.
                  </li>
                  <li>
                    “Sensitive personal Dossier or facts” means such personal
                    facts which consists of facts. relating to —
                    <ul style={{ listStyleType: "disc" }}>
                      <li>password.</li>
                      <li>
                        financial facts such as Bank account or credit card or
                        debit card or other payment instrument details
                      </li>
                      <li>
                        physical, physiological, and mental health condition;
                        iv. sexual orientation.
                      </li>
                      <li>
                        medical records and history; vi. Biometric facts; vii.
                        any detail relating to the above clauses as provided to
                        body corporate for providing service
                      </li>
                      <li>
                        any of the facts received under above clauses by body
                        corporate for processing, stored or processed under
                        lawful contract or otherwise. provided that, any facts
                        that is freely available or accessible in public domain
                        or furnished under the Right to Facts Act, 2005 or any
                        other law for the time being in force shall not be
                        regarded as sensitive personal Dossier or facts for the
                        purposes of these rules and this Policy.
                      </li>
                    </ul>
                  </li>
                  <li>
                    “UID Token” means a 72-character alphanumeric string
                    returned by UIDAI in response to the authentication and
                    Limited KYC request. It will be unique for each Aadhar
                    number for a particular entity (AUA/Sub-AUA) and will remain
                    same for an Aadhar number for all authentication requests by
                    that particular entity.
                  </li>
                  <li>
                    “Virtual ID (VID)” means any alternative virtual identity
                    issued as an alternative to the actual Aadhar number of an
                    individual that shall be generated by the Authority in such
                    manner as may be specified by regulations.
                  </li>
                </ul>
              </li>
              <li>
                <div>Personal Dossier collection</div>
                <p>
                  a to Plentycred shall collect the personal Dossier including
                  Aadhar number/Virtual ID, directly from the Aadhar number
                  holder for conducting authentication with UIDAI at the time of
                  providing the services.
                </p>
              </li>
              <li>
                <div>Specific purpose for collection of Personal Dossier</div>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    The Identity facts including Aadhar number / Virtual ID
                    shall be collected for the purpose of authentication of
                    Aadhar number holder to provide lending service.
                  </li>
                  <li>
                    The identity facts collected and processed shall only be
                    used pursuant to applicable law and as permitted under the
                    Aadhar Act 2016 or its Amendment and Regulations.
                  </li>
                  <li>
                    The identity facts shall not be used beyond the mentioned
                    purpose without consent from the Aadhar number holder and
                    even with consent use of such facts for other purposes
                    should be under the permissible purposes in compliance to
                    the Aadhar Act 2016.
                  </li>
                  <li>
                    Process shall be implemented to ensure that Identity facts
                    is not used beyond the purposes mentioned in the
                    notice/consent form provided to the Aadhar number holder.
                    Identity facts and Personal Dossier may be collected and
                    processed by to Plentycred
                  </li>
                  <li>
                    to undertake activities permitted under the License and
                    applicable laws.
                  </li>
                </ul>
              </li>
              <li>
                <div>Notice / Disclosure of Facts to Aadhar number holder </div>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    Aadhar number holder shall be provided relevant facts prior
                    to collection of identity facts.
                    <br />
                    Personal Dossier. These may include, as applicable:
                    <ul style={{ listStyleType: "disc" }}>
                      <li>
                        The purpose for which personal Dossier / identity facts
                        is being collected
                      </li>
                      <li>
                        The facts that shall be returned by UIDAI upon
                        authentication.
                      </li>
                      <li>
                        The facts that the submission of Aadhar number or the
                        proof of Aadhar is mandatory or voluntary for the
                        specified purpose and if mandatory the legal provision
                        mandating it
                      </li>
                      <li>
                        The alternatives to submission of identity facts (if
                        applicable);
                      </li>
                      <li>
                        Details of Section 7 notification (if applicable) by the
                        respective department under the Aadhar Act, 2016, which
                        makes submission of Aadhar number as a mandatory or
                        necessary condition to receive subsidy, benefit, or
                        services where the expenditure is incurred from the
                        Consolidated Fund of India or Consolidated Fund of
                        State. Alternate and viable means of identification for
                        delivery of the subsidy, benefit or service may be
                        provided if an Aadhar number is not assigned to an
                        individual.
                      </li>
                      <li>
                        The facts that Virtual ID can be used in lieu of Aadhar
                        number at the time of Authentication. The name and
                        address of to Plentycred
                      </li>
                      <li>
                        collecting and processing the personal Dossier. Aadhar
                        number holder shall be notified of the authentication
                        either through the e-mail or phone or SMS at the time of
                        authentication and the to Plentycred
                      </li>
                    </ul>
                  </li>
                  <li>shall maintain logs of the same;</li>
                </ul>
              </li>
              <li>
                <div>Obtaining Consent</div>
                <p>
                  Upon notice / disclosure of facts to the Aadhar number holder,
                  consent shall be taken in writing or in electronic form on the
                  website or mobile application or other appropriate means and
                  to Plentycred
                </p>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    shall maintain logs of disclosure of facts and Aadhar number
                    holder's consent.
                  </li>
                  <li>
                    Legal department shall be involved in vetting the method of
                    taking consent and logging of the same, and formal approval
                    shall be recorded from the legal department.
                  </li>
                </ul>
              </li>
              <li>
                <div>Processing of Personal Dossier</div>
                <p>
                  The identity facts, including Aadhar number, biometric
                  /demographic facts collected from the Aadhar number holder to
                  Plentycred
                </p>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    shall only be used for the Aadhar authentication process by
                    submitting it to the Central Identities Dossier Repository
                    (CIDR).
                  </li>
                  <li>
                    Legal department shall be involved in vetting the method of
                    taking consent and logging of the same, and formal approval
                    shall be recorded from the legal department.
                  </li>
                  <li>
                    Aadhar authentication or Aadhar e-KYC shall be used for the
                    specific purposes declared to UIDAI and permitted by UIDAI.
                    Such specific purposes shall be notified to the residents /
                    Clients / Individuals at the time of authentication through
                    disclosure of facts notice.
                  </li>
                  <li>
                    to Plentycred shall not use the Identity facts including
                    Aadhar number or eKYC for any other purposes than allowed
                    under RBI regulations or laws applicable to NBFC-ICC or as
                    may be permitted under License and informed to the resident
                    / Clients / individuals at the time of Authentication.
                  </li>
                  <li>
                    For e-KYC, the demographic details of the individual
                    received from UIDAI as a response shall be used for
                    identification of the individual for the specific purposes
                    of providing the specific services for the duration of the
                    services.
                  </li>
                </ul>
              </li>
              <li>
                <div>Retention of Personal Dossier</div>
                <p>
                  The authentication transaction logs shall be stored for a
                  period of two years after which the logs shall be archived for
                  a period of five years or as per the regulations governing the
                  entity, whichever is later and upon expiry of which period,
                  barring the authentication transaction logs required to be
                  maintained by a court order or pending dispute, the
                  authentication transaction logs shall be deleted.
                </p>
              </li>
              <li>
                <div>Sharing of Personal Dossier</div>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    Identity facts shall not be shared in contravention to the
                    Aadhar Act 2016, its Amendment, Regulations and other
                    circulars released by UIDAI from time to time.
                  </li>
                  <li>
                    Biometric facts collected shall not be transmitted over any
                    network without creation of encrypted PID block as per
                    Aadhar Act and regulations
                  </li>
                  <li>
                    to Plentycred shall not require an individual to transmit
                    the Aadhar number over the Internet unless such transmission
                    is secure and the Aadhar number is transmitted in encrypted
                    form except where
                  </li>
                  <li>
                    transmission is required for correction of errors or
                    redressal of grievances.
                  </li>
                </ul>
              </li>
              <li>
                <div>Dossier Security</div>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    The Aadhar number shall be collected over a secure
                    application, transmitted over a secure channel as per
                    specifications of UIDAI and the identity facts returned by
                    UIDAI shall be stored securely. me.
                  </li>
                  <li>
                    The biometric facts shall be collected, if applicable, using
                    the registered devices specified by UIDAI. These devices
                    encrypt the biometric facts at device level and the
                    application sends the same over a secure channel to UIDAI
                    for authentication.
                  </li>
                  <li>
                    OTP facts shall be collected in a secure application and
                    encrypted on the client device before transmitting it over a
                    secure channel as per UIDAI specifications.
                  </li>
                  <li>
                    Aadhar /VID number that are submitted by the resident /
                    Client / individual to the requesting entity and PID block
                    hence created shall not be retained under any event and
                    entity shall retain the parameters received in response from
                    UIDAI.
                  </li>
                  <li>
                    e-KYC facts shall be stored in an encrypted form only. Such
                    encryption shall match UIDAI encryption standards and follow
                    the latest Industry best practice.
                  </li>
                  <li>
                    to Plentycred has been classified as a Local AUA by UIDAI
                    and does not store Aadhar numbers of the Clients /
                    individuals / residents to maintain their privacy and
                    security
                  </li>
                  <li>
                    to Plentycred (if classified as Global AUAs and KUAs) shall,
                    as mandated by law, encrypt, and store the Aadhar numbers
                    and any connected Dossier only on the secure Aadhar Dossier
                    Vault (ADV) in compliance to the Aadhar Dossier vault
                    circular issued by UIDAI; &lt; Applicable to global AUAs
                    &gt;
                  </li>
                  <li>
                    The keys used to digitally sign the authentication request
                    and for encryption of Aadhar numbers in Dossier vault shall
                    be stored only in HSMs in compliance to the HSM and Aadhar
                    Dossier vault circulars.
                  </li>
                  <li>
                    to Plentycred shall use only Standardization Testing and
                    Quality Certification (STQC) / UIDAI certified biometric
                    devices for Aadhar authentication (if biometric
                    authentication is used); Standardization Testing and Quality
                    Certification (STQC) Directorate, an attached office of the
                    Ministry of Electronics and Facts Technology, Government of
                    India, provides quality assurance services in the area of
                    Electronics and IT through countrywide network of
                    laboratories and centres.
                  </li>
                  <li>
                    All applications used for Aadhar authentication or e-KYC
                    shall be tested for compliance to Aadhar Act 2016 before
                    being deployed in production and after every change that
                    impacts the processing of Identity facts; The applications
                    shall be audited on an annual basis by facts systems
                    auditor(s) certified by Standardization Testing and Quality
                    Certification, CERT-IN or any other UIDAI recognized body.
                  </li>
                  <li>
                    In the event of an identity facts breach, the organization
                    shall notify UIDAI of the following:
                    <ul style={{ listStyleType: "disc" }}>
                      <li>A description and the consequences of the breach.</li>
                      <li>
                        A description of the number of Aadhar number holders
                        affected and the number of records affected; iii. The
                        privacy officer’s contact details; iv. Measures taken to
                        mitigate the identity facts breach.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Appropriate security and confidentiality obligations shall
                    be implemented in the non-disclosure agreements (NDAs) with
                    employees/contractual agencies /consultants/advisors and
                    other personnel handling identity facts.
                  </li>
                  <li>
                    Only authorized individuals shall be allowed to access
                    Authentication application, audit logs, authentication
                    servers, application, source code, facts security
                    infrastructure. An access control list shall be maintained
                    and regularly updated by organization.
                  </li>
                  <li>
                    Best practices in Dossier privacy and Dossier protection
                    based on international Standards shall be adopted.
                  </li>
                  <li>
                    The response received from CIDR in the form of
                    authentication transaction logs shall be stored with
                    following details:
                    <ul style={{ listStyleType: "disc" }}>
                      <li>
                        The Aadhar number against which authentication is
                        sought. In case of Local AUAs where Aadhar number is not
                        returned by UIDAI and storage is not permitted,
                        respective UID token shall be stored in place of Aadhar
                        number.
                      </li>
                      <li>
                        Specified parameters received as authentication
                        response.
                      </li>
                      <li>
                        The record of disclosure of facts to the Aadhar number
                        holder at the time of authentication; and
                      </li>
                      <li>
                        Record of consent of the Aadhar number holder for
                        authentication but shall not, in any event, retain the
                        PID facts.
                      </li>
                    </ul>
                  </li>
                  <li>
                    A Facts Security policy in-line with ISO27001 standard,
                    international standard for facts security. –, UIDAI specific
                    Facts Security policy and Aadhar Act 2016 shall be
                    formulated to ensure Security of Identity facts.
                    <p>
                      ISO 27001, the international standard for facts security,
                      works on three principles: confidentiality, integrity, and
                      availability of Dossier.
                    </p>
                    <p></p>
                  </li>
                  <li>
                    Aadhar numbers shall only be stored in Aadhar Dossier vault
                    as per the specifications provided by UIDAI.
                  </li>
                </ul>
              </li>
              <li>
                <div>Rights of the Aadhar Number Holder</div>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    The Aadhar number holder has the right to obtain and request
                    update of identity facts stored with the organization,
                    including Authentication logs. The collection of core
                    biometric facts, storage and further sharing is protected by
                    Section 29 of the Aadhar Act 2016, hence the Aadhar number
                    holder cannot request for the core biometric facts.
                  </li>
                  <li>
                    to Plentycred shall provide a process for the Aadhar number
                    holder to view their identity facts stored and request
                    subsequent updating after authenticating the identity of the
                    Aadhar number holder. In case the update is required from
                    UIDAI, same shall be informed to the Aadhar number holder.{" "}
                    <br />
                    The Aadhar number holder may, at any time, revoke consent
                    given to to Plentycred for storing his e-KYC Dossier, and
                    upon such revocation, to Plentycred
                  </li>
                  <li>
                    shall delete the e-KYC Dossier in a verifiable manner and
                    provide an acknowledgement of the same to the Aadhar number
                    holder.
                  </li>
                  <li>
                    The Aadhar number holder has the right to lodge a complaint
                    with the privacy officer who is responsible for monitoring
                    of the identity facts processing activities so that the
                    processing is not in contravention of the law.
                  </li>
                </ul>
              </li>
              <li>
                <div>Aadhar Number Holder Access request</div>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    A process shall be formulated to handle the queries and
                    process the exercise of rights of Aadhar number holders with
                    respect to their identity facts / personal Dossier. As part
                    of the process, it shall be mandatory to authenticate the
                    identity of the Aadhar number holder before providing access
                    to any identity facts.
                  </li>
                  <li>
                    All requests from the Aadhar number holder shall be formally
                    recorded and responded to within a reasonable period.
                  </li>
                  <li>
                    Compliance to the relevant Dossier protection / privacy law
                    (s) shall be ensured.
                  </li>
                </ul>
              </li>
              <li>
                <div>Privacy by Design</div>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    Processes shall be established to embed privacy aspects at
                    the design stage of any new systems, products, processes,
                    and technologies involving Dossier processing of identity
                    facts of Aadhar number holders.
                  </li>
                  <li>
                    The to Plentycred in possession of the Aadhar number of
                    Aadhar number holders, shall not make public any Dossier
                    base or records of the Aadhar numbers unless the Aadhar
                    numbers have been redacted or blacked out through
                    appropriate means, both in print and in electronic form.
                  </li>
                  <li>
                    Before going live with any new process that involves
                    processing of identity facts, the organization shall ensure
                    that Disclosure of facts / Privacy notice in compliance to
                    the Aadhar Act 2016 is provided to the resident / Client /
                    individual and that consent is taken and recorded in
                    compliance to Aadhar Act 2016.
                  </li>
                  <li>
                    Quarterly self-assessments shall be conducted to ensure
                    compliance to disclosure of facts and consent requirements.
                  </li>
                  <li>
                    Privacy enhancing organizational and technical measures like
                    anonymization, de-identification and minimization shall be
                    implemented to make the collection of identity facts
                    adequate, relevant, and limited to the purpose of
                    processing.
                  </li>
                  <li>
                    Compliance to the relevant Dossier protection / privacy law
                    (s) shall be ensured.
                  </li>
                </ul>
              </li>
              <li>
                <div>Governance and Accountability Obligations</div>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    A Privacy committee shall be established to provide
                    strategic direction on Privacy matters.
                  </li>
                  <li>
                    The to Plentycred in possession of the Aadhar number of
                    Aadhar number holders, shall not make public any Dossier
                    base or records of the Aadhar numbers unless the Aadhar
                    numbers have been redacted or blacked out through
                    appropriate means, both in print and in electronic form.
                  </li>
                  <li>
                    A person (Privacy Officer) responsible for developing,
                    implementing, maintaining, and monitoring the comprehensive,
                    organization-wide governance and accountability shall be
                    designated to ensure compliance with the applicable laws.
                  </li>
                  <li>
                    The name of the Privacy Officer and contact details shall be
                    made available to UIDAI and other external agencies through
                    appropriate channel.
                  </li>
                  <li>
                    The Privacy Officer shall be responsible to assess privacy
                    risks of processing Identity facts / personal Dossier and
                    mitigate the risks.
                  </li>
                  <li>
                    The Privacy Officer shall be independent and shall be
                    involved in all the issues relating to processing of
                    identity facts.
                  </li>
                  <li>
                    The Privacy Officer shall be an expert in Dossier protection
                    and privacy legislations, regulations, and best practices.
                  </li>
                  <li>
                    The Privacy Officer shall advise the top management on the
                    privacy obligations.
                  </li>
                  <li>
                    The Privacy Officer shall advise on high-risk processing and
                    the requirement of Dossier privacy impact assessments.
                  </li>
                  <li>
                    The Privacy Officer shall act as a point of contact for
                    UIDAI for coordination and implementation of privacy
                    practices and other external agencies for any queries.
                  </li>
                  <li>
                    The Privacy Officer shall be responsible for managing
                    privacy incidents and responding to the same.
                  </li>
                  <li>
                    The Privacy Officer shall also be responsible for putting in
                    place measures to create awareness and training of staff
                    involved in processing identity facts, about the legal
                    consequences of Dossier breach to the reputation of the
                    organization.
                  </li>
                  <li>
                    Privacy officer shall ensure that the Authentication
                    operations, systems, and applications are audited by CERT-IN
                    (Indian Computer Emergency Response Team), Standardization
                    Testing and Quality Certification (STQC) empanelled auditors
                    or any other UIDAI recognized body at least on an annual
                    basis.
                  </li>
                  <li>
                    Privacy officer shall conduct internal audits through
                    Dossier Privacy Officer Office (through Infosec team) on a
                    quarterly basis and monitor compliance through these audits
                    against Aadhar Act 2016.
                  </li>
                  <li>
                    rivacy officer shall ensure that the front-end operators
                    interacting with Aadhar number holders are trained on a
                    periodic basis to ensure they communicate the disclosure of
                    facts to the Aadhar number holder, take consent
                    appropriately after showing the screen to the Aadhar number
                    holder and ensure Security of identity facts. Such trainings
                    shall be documented for audit purposes.
                  </li>
                  <li>
                    Aadhar specific trainings to developers, systems admins and
                    other users shall be provided to ensure they are aware of
                    the obligations for their respective roles; Completion of
                    such trainings shall be documented.
                  </li>
                  <li>
                    Privacy officer shall be responsible to formally communicate
                    this policy to all stakeholders and staff who need to comply
                    with this policy; Any changes to the policy shall be
                    communicated immediately.
                  </li>
                  <li>
                    Privacy Officer shall facilitate formal Privacy performance
                    reviews with the relevant stakeholders / Privacy Committee
                    and suggest improvements. The reviews shall consider the
                    results of various audits, privacy incidents, privacy
                    initiatives, UIDAI requirements etc.
                  </li>
                </ul>
              </li>
              <li>
                <div>
                  Transfer of Identity facts outside India is prohibited
                </div>
                <p>
                  Identity facts shall not be hosted or transferred outside the
                  territory of India in compliance to the Aadhar Act and its
                  Regulations
                </p>
              </li>
              <li>
                <div>Grievance Redressal Mechanism</div>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    Aadhar number holders with grievances about the processing
                    can contact the organization’s Privacy Officer via multiple
                    channels like on the website, through phone, SMS, mobile
                    application etc.
                  </li>
                  <li>
                    Reasonable measures shall be taken to inform the residents /
                    Clients / individuals about the Privacy Officer and its
                    contact details.
                  </li>
                  <li>
                    The contact details of Privacy Officer and the format for
                    filing the complaint shall be displayed on the
                    organization's website and other such mediums that are
                    commonly used for interaction with the residents / Clients /
                    individuals.
                  </li>
                  <li>
                    Where the medium of interaction is not electronic (such as
                    physical), Poster / Notice board that is prominently visible
                    shall be used to display the name of Privacy officer and
                    contact details. If any issue is not resolved through
                    consultation with the management of the to Plentycred
                  </li>
                  <li>
                    Aadhar number holders can seek redressal by way of
                    mechanisms as specified in Section 33B of the Aadhar Act,
                    2016.
                  </li>
                </ul>
              </li>
              <li>
                <div>
                  Responsibility for implementation and enforcement of the
                  policy
                </div>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    The overall responsibility of monitoring and enforcement of
                    this policy through various mechanisms such as Audits etc.
                    shall be with Chief Facts Security Officer
                  </li>
                  <li>
                    Responsibility of the implementation of controls of this
                    policy shall be with Chief Facts Security Officer
                  </li>
                  <li>
                    The contact details of Privacy Officer and the format for
                    filing the complaint shall be displayed on the
                    organization's website and other such mediums that are
                    commonly used for interaction with the residents / Clients /
                    individuals.
                  </li>
                  <li>
                    Responsibility of review of Disclosure of facts notice,
                    consent clause, method of consent, logging of consent etc.
                    shall be with Legal Head
                  </li>
                  <li>
                    Aadhar number holders can seek redressal by way of
                    mechanisms as specified in Section 33B of the Aadhar Act,
                    2016.
                  </li>
                </ul>
              </li>
              <li>
                <div>
                  Relevant Provisions of Aadhar Act and Supreme court judgement
                </div>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    Following relevant documents shall be referred to for
                    ensuring compliance to the Aadhar requirements:
                    <ul
                      style={{
                        listStyleType: "lower-roman",
                        marginLeft: "2em",
                      }}
                    >
                      <li>
                        Judgement of Honourable Supreme court dated September
                        2018
                      </li>
                      <li>Aadhar Act 2016</li>
                      <li>Aadhar and Other Laws (Amendment) Act 2019</li>
                      <li>Aadhar (Authentication) Regulations 2016</li>
                      <li>Aadhar (Dossier Security) Regulations 2016</li>
                      <li>Aadhar (Sharing of Facts) Regulations 2016</li>
                      <li>
                        Any other Regulations or notices or Circulars issued by
                        UIDAI from time to time
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <br /><br />
              Plentycred is a fully owned subsidiary of Arahant Economy Services Private Limited. It is a digital platform that helps customers borrow money for their needs at the click of a button. All rights reserved by Arahant Economy Services Private Limited. &copy; 2024
              <li>
                <div>Contact Details</div>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <b>Name of Privacy Officer: Mr. Niraj Hemraj shah </b>
                  </li>
                  <li>
                    <b>Phone: +91 7058111852</b>
                  </li>
                  <li>
                    <b>Email; assist@plentycred.co.in</b>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
