import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const ElKYC = ({ status }) => {
  const [electricityStatus, setElectricityStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const finish = async () => {
    setLoading(true);
    setElectricityStatus(null);
    try {
      const token = localStorage.getItem("token");
      try {
        await axios.post(
          `${process.env.REACT_APP_API_KEY}/v2/kyc/store-electricity-bill`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
            }
          }
        );
        setElectricityStatus("success");
        setTimeout (() => {
          window.location.reload()
        }, 1000)
      } catch (error) {
        setElectricityStatus("failed");
        toast.error("Electricity bill verification failed. Please try again.", {
          theme: "colored"
        });
        return;
      }

      try {
        await axios.post(
          `${process.env.REACT_APP_API_KEY}/v2/kyc/store-dl`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
            }
          }
        );
        toast.success("Completed verification done", { theme: "colored" });
      } catch (error) {
        toast.error("Verification failed, please try again.", {
          theme: "colored"
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Unexpected error occurred. Please try again later.", {
        theme: "colored"
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Only show the component if status is false
  if (status) {
    return null; // Return nothing if status is true
  }

  return (
    <div>
      <div className="flex flex-col p-4">
        <div className="p-6 rounded-lg bg-white shadow-lg">
          <div className="flex flex-row justify-between items-center">
            <div className="flex-[1]">
              <h2 className="text-2xl font-semibold">Finish KYC</h2>
            </div>
            <div className="flex-[1] flex justify-end">
              <button
                onClick={finish}
                className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300 disabled:opacity-50"
                disabled={loading}
              >
                {loading ? (
                  <span className="flex items-center">
                    <svg
                      className="animate-spin mr-2 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                    Submitting...
                  </span>
                ) : (
                  "Verfiy Electricity"
                )}
              </button>
            </div>
          </div>
          {electricityStatus && (
            <div
              className={`mt-4 text-center ${
                electricityStatus === "success"
                  ? "text-green-600"
                  : "text-red-600"
              }`}
            >
              {electricityStatus === "success" ? (
                "Electricity bill verification succeeded!"
              ) : (
                <div>
                  Electricity bill verification failed.
                  <button
                    onClick={finish}
                    className="bg-red-500 text-white py-2 px-4 mt-4 rounded-lg hover:bg-red-600 transition duration-300"
                  >
                    Try Again
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ElKYC;
