import { useState } from "react";
import { useLocation } from "react-router-dom";

export const useViewManager = () => {
  const [currentViewIndex, setCurrentViewIndex] = useState(0);
  const { pathname } = useLocation();

  const changeView = (index) => {
    console.log("Index>>>>", index);
    setCurrentViewIndex(index);
    // Scroll to the top when changing view
    window.scrollTo(0, 0);
  };

  return {
    currentViewIndex,
    changeView,
  };
};