import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const LoanCalculator = () => {
  const [loanAmountInr, setLoanAmountInr] = useState(1000); // Initial value in INR
  const [interestRate, setInterestRate] = useState(18);
  const [loanTermMonths, setLoanTermMonths] = useState(1); // Initial value for 10 years
  const [emi, setEmi] = useState(0); // Start with 0 EMI
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalMonths, setTotalMonths] = useState(0);

  const calculateEmi = () => {
    const p = parseFloat(loanAmountInr);
    const r = parseFloat(interestRate) / 1200; // Monthly interest rate
    const nMonths = parseFloat(loanTermMonths); // Total number of payments in months

    const emiValue =
      (p * r * Math.pow(1 + r, nMonths)) / (Math.pow(1 + r, nMonths) - 1);

    setEmi(emiValue.toFixed(2));
    setTotalInterest((emiValue * nMonths - p).toFixed(2));
    setTotalMonths(nMonths);
  };

  const handleMonthsChange = (e, value) => {
    setLoanTermMonths(value);
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const duration = 1; // Customize the duration as needed

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 100 },
  };

  const onChange = () => {};

  return (
    <>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={variants}
        transition={{ duration: duration }}
        className="max-w-6xl mx-auto my-8 p-6 bg-white rounded-md shadow-md"
      >
        <h2 className="text-3xl font-bold mb-4 text-center text-blue-600">
          Loan EMI Calculator (INR)
        </h2>
        <div className="flex flex-col mt-7 justify-center gap-20 xl:flex-row">
          <div className="w-full">
            <div className="mb-4">
              <label className="block text-sm font-semibold mb-2">
                Loan Amount (INR):
              </label>
              <Slider
                value={loanAmountInr}
                onChange={(e, value) => setLoanAmountInr(value)}
                min={0}
                max={10000}
                valueLabelDisplay="auto"
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-semibold mb-2">
                Annual Interest Rate (%):
              </label>
              <Slider
                value={interestRate}
                onChange={(e, value) => setInterestRate(value)}
                min={17}
                max={18}
                valueLabelDisplay="auto"
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-semibold mb-2">
                Loan Term (Months): {loanTermMonths}
              </label>
              <Slider
                value={loanTermMonths}
                onChange={handleMonthsChange}
                min={2}
                max={7}
                valueLabelDisplay="auto"
                className="w-full"
              />
            </div>
          </div>
          <div className="max-w-[440px]">
            <div className="">
              <h3 className="text-lg font-semibold text-blue-600">
                Monthly EMI:
              </h3>
              <div className="mt-2 mb-5">
                <p className="text-xl font-semibold text-slate-700">
                  EMI RESULT : ₹{emi}
                </p>
                <p className="text-xl font-semibold text-slate-700">
                  Total Interest : ₹{totalInterest}
                </p>
                <p className="text-xl font-semibold text-slate-700">
                  Total Months : {totalMonths}
                </p>
              </div>
            </div>

            <button
              className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none"
              onClick={calculateEmi}
            >
              Calculate EMI
            </button>
            <div className="mt-4 text-gray-600">
              Get an estimate of your monthly EMI based on the loan amount,
              interest rate, and loan term. <p className="font-semibold inline-block text-blue-600">Terms & Conditions apply.</p>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};
