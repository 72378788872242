import React, { useState } from "react";
import axios from "axios";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { toast } from "react-toastify";

const PayComplete = () => {
  const [iframeSrc, setIframeSrc] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const token = localStorage.getItem("token");

  const handleFullPaymentClick = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v2/payments/create`,
        { payment: "full" },
        { headers }
      );
      setIframeSrc(response.data.paymentURL);
      setIsOpen(true);
      toast.success("Full payment initiated", { theme: "colored" });
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error("Error initiating full payment", { theme: "colored" });
    }
  };

  return (
    <div>
      <button
        className="transition bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-xl w-full mt-5"
        onClick={handleFullPaymentClick}
      >
        Pay Complete <ArrowRightAltIcon />
      </button>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative w-[800px] h-[600px] bg-white rounded-lg shadow-lg">
            <iframe
              src={iframeSrc}
              title="Payment"
              className="w-full h-full rounded-lg"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PayComplete;
