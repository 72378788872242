import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const NACHMandate = ({ show }) => {
  const token = localStorage.getItem("token");
  const [ifscCode, setIfscCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [loading, setLoading] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false); // State to manage popup visibility
  const navigation = useNavigate();

  const fetchBank = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v2/nach/fetch-bank`,
        { ifscCode },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setBankName(response.data.data.result.bankName);
      toast.success("Bank fetched successfully", { theme: "colored" });
    } catch (error) {
      toast.error(`Error fetching bank: ${error.message}`, {
        theme: "colored",
      });
      console.error("Error fetching bank:", error);
    } finally {
      setLoading(false);
    }
  };

  const NachMandate = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v2/nach/create-mandate`,
        {
          bankCode: ifscCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("NachMandate successfully created", { theme: "colored" });

      if (response.data.result.data) {
        window.location.href = response.data.result.data;
      } else {
        toast.error("No provided url", {
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(`Error creating NachMandate: ${error.message}`, {
        theme: "colored",
      });
      console.error("Error creating NachMandate:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {show?.NachMandatePending ? (
        <>
          <h1>
            Please wait while we verify the NachMandate. It may take 24 to 32
            hours to complete the verification process.
          </h1>
        </>
      ) : (
        <div>
          <div className="my-5">
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded mt-3"
              placeholder="Enter IFSC Code"
              value={ifscCode}
              onChange={(e) => setIfscCode(e.target.value)}
            />
          </div>
          <button
            className="transition bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-xl w-full mt-5"
            onClick={fetchBank}
          >
            Fetch Bank <ArrowRightAltIcon />
          </button>
          {bankName && (
            <div className="my-5">
              <p className="text-lg font-medium">Bank Name: {bankName}</p>
            </div>
          )}
          {bankName && (
            <div>
              <button
                className="transition bg-green-500 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-xl w-full mt-5"
                onClick={NachMandate}
                disabled={loading}
              >
                {loading ? "Processing..." : "Create NachMandate"}{" "}
                <ArrowRightAltIcon />
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NACHMandate;
