import React, { useState, useEffect, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import facePng from "../core/images/face.png";
import { useNavigate } from "react-router-dom";
import CameraIcon from "@mui/icons-material/Camera";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ReplayIcon from "@mui/icons-material/Replay";
import CircularProgress from "@mui/material/CircularProgress";

const WebcamComponent = () => <Webcam />;
const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: "user",
};

const LivePhoto = () => {
  const [picture, setPicture] = useState("");
  const [show, setShow] = useState("");
  const [loading, setLoading] = useState(false);
  const webcamRef = useRef(null);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const capture = useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
  }, []);

  const handleUpload = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/profile/upload-live-selfie`,
        { image: picture }, // Assuming picture contains the image data
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Image uploaded successfully!");
        window.location.reload();
      } else {
        throw new Error("Failed to upload image.");
      }
    } catch (error) {
      toast.error("Error uploading image.");
      console.error("Error uploading:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/v2/contracting/verify-all`,
        {},
        { headers }
      )
      .then((response) => {
        setShow(response.data.VerifyAllData);
      })
      .catch((error) => {
        console.error("Error sending POST request:", error);
      });
  }, [token]);

  useEffect(() => {
    if (show?.LivePhotoVerify) {
      navigate("/dashboard");
    }
  }, [show, navigate]);

  return (
    <div className="p-2 xl:p-16">
              <ToastContainer
        />
      <div
        id="glassMorph"
        className="mx-auto max-w-[700px] text-black p-10 flex-row items-center rounded-lg shadow-lg"
      >
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold text-white">Live Selfie</h2>
          <p className="text-sm text-white">
            Click Live Selfie To Make Sure Your Profile Doesn't Get Rejected.
          </p>
        </div>
        <div className="flex flex-col items-center">
          {picture === "" ? (
            <div className="relative">
              <div className="absolute w-[400px] h-[400px] justify-center items-center flex">
              </div>
              <Webcam
                audio={false}
                height={400}
                ref={webcamRef}
                width={400}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                className="rounded-lg"
              />
            </div>
          ) : (
            <img src={picture} alt="Captured" className="mb-4 rounded-lg" />
          )}
          <div className="flex mt-2">
            {picture !== "" ? (
              <button
                onClick={() => setPicture("")}
                className="bg-gray-900 hover:bg-gray-950 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Retake <ReplayIcon />
              </button>
            ) : (
              <button
                onClick={capture}
                className="transition bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Capture <CameraIcon />
              </button>
            )}
            {picture !== "" && (
              <button
                onClick={handleUpload}
                className="transition bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} className="text-white" />
                ) : (
                  <>
                    Upload <FileUploadIcon />
                  </>
                )}
              </button>
            )}
          </div>
        </div>

      </div>
      <div
        className="fixed w-full h-full top-0 left-0 z-[-10]"
        id="DASHbackground4"
      ></div>
    </div>
  );
};

export default LivePhoto;
