import React, { useEffect } from "react";
import animate from "../core/css/animation.module.css";
import { Player } from "@lottiefiles/react-lottie-player";
import Header from "../components/Header";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import ProductCardAutoSlider from "../components/Extracom/ProductCardAutoSlider";

const Products = () => {
  const controls = useAnimation();
  const location = useLocation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <>
      {location.pathname === "/products" ? (
        <>
          <Header />
        </>
      ) : (
        <></>
      )}
      <div id="wrapper">
        <br /><br />
        <div className="mt-12 mb-10 rounded-[20px] bg-[rgb(220,255,245)] bg-[linear-gradient(90deg,_rgba(220,255,245,1)_0%,_rgba(255,255,255,1)_24%,_rgba(255,254,189,1)_48%,_rgba(255,232,172,1)_73%,_rgba(255,188,188,1)_100%)] p-10 flex flex-wrap justify-end flex-col gap-10 lg:flex-row items-center">
          <div className="flex-[1]">
            <motion.h1
              ref={ref}
              initial="hidden"
              animate="visible"
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: -100 },
              }}
              transition={{ duration: 1.3 }}
              className="text-4xl text-gray-800 font-extrabold mx-auto md:text-5xl 2xl: leading-tight"
            >
              <span className="text-black/80 font-semibold">Join</span>{" "}
              <span className="text-blue-700">Plenty Cred</span>{" "}
              <span className="text-black/80 font-semibold">
                Quick boost to bank account
              </span>
            </motion.h1>
            <br />
            <motion.p
              ref={ref}
              initial="hidden"
              animate="visible"
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: -100 },
              }}
              transition={{ duration: 1.3 }}
              className="max-w-xl mx-auto xl:mx-0 font-semibold leading-relaxed"
            >
              It's month-end with low bank balance and you suddenly have a
              craving or an urgent expense has come up?
            </motion.p>
            <br />
            <motion.div
              ref={ref}
              initial="hidden"
              animate="visible"
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: -100 },
              }}
              transition={{ duration: 1.3 }}
            >
              <NavLink
                to="/"
                className="transition bg-gray-800 px-4 py-3 rounded-lg text-white font-semibold inline-block mt-2 hover:bg-white hover:text-gray-950"
              >
                CheckOut the Dashboard <FontAwesomeIcon icon={faArrowRight} />
              </NavLink>
              <NavLink
                to="/"
                className="ml-4 transition hover:bg-gray-800 px-4 py-3 rounded-lg hover:text-white font-semibold inline-block mt-2 bg-white text-gray-950"
              >
                Know More <FontAwesomeIcon icon={faArrowRight} />
              </NavLink>
              <NavLink to="/" className="font-semibold block mt-10">
                Find Out More <FontAwesomeIcon icon={faArrowDown} />
              </NavLink>
            </motion.div>
          </div>
          <motion.div
            ref={ref}
            initial="hidden"
            animate="visible"
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: -100 },
            }}
            transition={{ duration: 1.3 }}
            className="flex-[1]"
          >
            <Player
              autoplay
              loop
              src="https://lottie.host/bf681e2b-3031-47fa-b895-963963a75a11/7J0Gp3xuI9.json"
              style={{ height: "360px", width: "100%" }}
            ></Player>
          </motion.div>
        </div>

        <div>
          <ProductCardAutoSlider />
        </div>
      </div>
      <div>
        <div id="wrapper">
          <motion.div
            ref={ref}
            initial="hidden"
            animate="visible"
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 100 },
            }}
            transition={{ duration: 1.3 }}
            className="flex mt-4 flex-col xl:flex-row justify-center items-center rounded-lg gap-8"
          >
            <div className="">
              <div className="text-center">
                <h2 className="font-medium text-2xl md:text-4xl my-4">
                  The maze of obstacles leads to frustration which hampers
                  overall peace of mind and creates a cycle of stress and
                  financial stagnation.
                </h2>
                <div className="max-w-2xl md:max-w-4xl mx-auto text-black">
                  <p>
                    Lengthy paperwork, high interest rates, and a scarcity of
                    microcredit offerings often create barriers to accessing
                    funds for 'out of the blue' or 'moody' needs. Additionally,
                    dealing with rigid, one-size-fits-all loan features can
                    leave you without the flexibility you need to address your
                    unique needs.
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      <motion.div>
        <div className="relative flex  flex-col justify-center overflow-hidden py-6 sm:py-12">
          <div className="w-full items-center mx-auto max-w-screen-lg">
            <div className="p-8 w-full flex flex-col xl:flex-row">
              <div>
                <div className="pr-12">
                  <div className="flex flex-col gap-2">
                    <p className="peer mb-6 text-blck">
                      At PlentyCred, we understand the specific financial
                      problems of working professionals and strive to solve
                      their unique needs.enabling you to take control of your
                      financial journey with confidence.
                    </p>
                    <p className="hidden xl:flex  peer mb-6 ">
                      With our tailored microcredit services ranging from INR
                      100 to INR 10,000, you gain access to instant funds,
                      allowing you to address pressing matters without delays
                    </p>
                  </div>

                  <div className="hidden xl:flex mb-6  flex-col gap-5 ">
                    <p>
                      We charge only flat 1.8%* interest rate per month, making
                      the credit affordable for your needs all year long (*Other
                      charges applied)
                    </p>
                    <p>
                      We offer a 1 year line of credit, with a limit based on
                      your profile health
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-2 lg:p-16 relative flex flex-col before:block before:absolute before:h-1/6 before:w-4 before:bg-blue-500 before:bottom-0 before:right-0 before:rounded-lg  before:transition-all group-hover:before:bg-orange-300 overflow-hidden">
                <div className="absolute top-0 right-0 bg-blue-500 lg:w-4/6 w-full lg:px-12 px-6 lg:py-14 py-10 flex flex-col justify-center rounded-xl group-hover:bg-sky-600 transition-all">
                  <span className="block mb-4 font-bold text-white group-hover:text-white">
                    HERE WE ARE
                  </span>
                  <h2 className="text-white font-bold text-3xl">
                    PlentyCred's All in one Solution
                  </h2>
                </div>
                <a
                  className="font-bold text-sm flex mt-2 mb-8 items-center gap-2"
                  href=""
                >
                  <span className="hidden">MORE ABOUT US</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                    />
                  </svg>
                </a>
                <div className="rounded-xl overflow-hidden">
                  <img
                    src="https://picsum.photos/800/800"
                    alt=""
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      <motion.div
        ref={ref}
        initial="hidden"
        animate="visible"
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1.3 }}
        className="flex justify-center mt-24 mb-4"
      >
        <h1 className="p-5 text-center text-4xl font-medium text-[#]"></h1>
      </motion.div>

      <Footer />
    </>
  );
};

export default Products;

{
  /* <motion.div
ref={ref}
initial="hidden"
animate="visible"
variants={{
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 100 },
}}
transition={{ duration: 1.3 }}
className="flex flex-row flex-wrap gap-5 p-4  justify-evenly"
>
<div>
  <h1 className="text-md font-medium p-2 bg-white text-gray-800 rounded-lg shadow-lg border-solid border-[#11c4be] border-2">
    <FontAwesomeIcon
      icon={faPizzaSlice}
      className="mr-2 text-[#11c4be]"
      id=""
    />{" "}
    Need a Domino's loan?
  </h1>
</div>
<div className="flex flex-col justify-center items-center">
  <h1 className="text-md font-medium p-2 bg-white text-gray-800 rounded-lg shadow-lg border-solid border-[#f1c232] border-2">
    <FontAwesomeIcon icon={faGift} className="mr-2 text-[#f1c232]" />{" "}
    Need a birthday gift loan?{" "}
  </h1>
</div>
<div>
  <h1 className="text-md font-medium p-2 bg-white text-gray-800 rounded-lg shadow-lg border-solid border-[#e69138] border-2">
    <FontAwesomeIcon
      icon={faDumbbell}
      className="mr-2 text-[#e69138]"
      id=""
    />
    Need a gym membership loan?{" "}
  </h1>
</div>
<div>
  <h1 className="text-md font-medium p-2 bg-white text-gray-800 rounded-lg shadow-lg border-solid border-[#ff5c5c] border-2">
    <FontAwesomeIcon
      icon={faPlane}
      className="mr-2 text-[#ff5c5c]"
      id=""
    />
    Need a vacation loan?
  </h1>
</div>
</motion.div>
<motion.div
ref={ref}
initial="hidden"
animate="visible"
variants={{
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 100 },
}}
transition={{ duration: 1.3 }}
className=" mt-6 flex justify-center items-center"
>
<h1 className="text-md max-w-[700px] font-medium p-2 bg-white text-gray-800 rounded-lg shadow-lg border-solid border-[#eaedea] border-2">
  <FontAwesomeIcon
    icon={faArrowRight}
    className="mr-2 text-[#11c4be]"
    id=""
  />
  We cater to all your unique needs in seconds!
</h1>
</motion.div> */
}
