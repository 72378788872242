import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminImages = ({ userId }) => {
  const [idCardUrl, setIdCardUrl] = useState('');
  const [salarySlipUrl, setSalarySlipUrl] = useState('');
  const [liveSelfieUrl, setLiveSelfieUrl] = useState('');

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const token = localStorage.getItem('token');
        
        const idCardResponse = await axios.get(`https://api.plentycred.co.in/v1/admin/get-id-card/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setIdCardUrl(idCardResponse.data.photo_url || '');

        const salarySlipResponse = await axios.get(`https://api.plentycred.co.in/v1/admin/get-salary-slip/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setSalarySlipUrl(salarySlipResponse.data.photo_url || '');

        const liveSelfieResponse = await axios.get(`https://api.plentycred.co.in/v1/admin/get-live-selfie/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setLiveSelfieUrl(liveSelfieResponse.data.photo_url || '');

      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, [userId]);

  return (
    <div className="admin-images flex flex-col lg:flex-row gap-5 justify-center items-center p-4">
      <div>
        <h3>ID Card</h3>
        {idCardUrl ? <img src={idCardUrl} alt="ID Card" /> : <p>No Image Available</p>}
      </div>
      <div>
        <h3>Salary Slip</h3>
        {salarySlipUrl ? <img src={salarySlipUrl} alt="Salary Slip" /> : <p>No Image Available</p>}
      </div>
      <div>
        <h3>Live Selfie</h3>
        {liveSelfieUrl ? <img src={liveSelfieUrl} alt="Live Selfie" /> : <p>No Image Available</p>}
      </div>
    </div>
  );
};

export default AdminImages;
