// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error_container__M4CNb {
    display: flex;
    margin-top: 200px;
    flex-direction: row;
    margin-left: 8em;
}

.error_left_container__8d8XL {
    display: inline;
}

.error_left_container__8d8XL h1 {
    font-size: 4em;
    font-weight: bolder;
}

.error_left_container__8d8XL p {
    font-size: 2.7em;
}

.error_navButton__g0gBE {
    text-decoration: none;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    border: 2px solid black;
    padding: 0.2em;
    border-radius: 9px;
    font-size: 1.8em;
    transition: ease-in-out 300ms;
    border-color: #000000;
    color: #000000;
    background-image: linear-gradient(45deg, #000000 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    transition: background 400ms ease-in-out;
}

.error_navButton__g0gBE:hover {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    background-position: 0;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/core/css/error.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,wEAAwE;IACxE,uBAAuB;IACvB,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAEhB,6BAA6B;IAC7B,qBAAqB;IACrB,cAAc;IAEd,sEAAsE;IACtE,yBAAyB;IACzB,qBAAqB;IAErB,wCAAwC;AAC5C;;AAEA;IACI,2FAA2F;IAC3F,sBAAsB;IACtB,WAAW;AACf","sourcesContent":[".container {\n    display: flex;\n    margin-top: 200px;\n    flex-direction: row;\n    margin-left: 8em;\n}\n\n.left_container {\n    display: inline;\n}\n\n.left_container h1 {\n    font-size: 4em;\n    font-weight: bolder;\n}\n\n.left_container p {\n    font-size: 2.7em;\n}\n\n.navButton {\n    text-decoration: none;\n    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;\n    border: 2px solid black;\n    padding: 0.2em;\n    border-radius: 9px;\n    font-size: 1.8em;\n    color: #000;\n    transition: ease-in-out 300ms;\n    border-color: #000000;\n    color: #000000;\n    background-image: -webkit-linear-gradient(45deg, #030303 50%, transparent 50%);\n    background-image: linear-gradient(45deg, #000000 50%, transparent 50%);\n    background-position: 100%;\n    background-size: 400%;\n    -webkit-transition: background 400ms ease-in-out;\n    transition: background 400ms ease-in-out;\n}\n\n.navButton:hover {\n    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;\n    background-position: 0;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `error_container__M4CNb`,
	"left_container": `error_left_container__8d8XL`,
	"navButton": `error_navButton__g0gBE`
};
export default ___CSS_LOADER_EXPORT___;
