import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";

import { styled } from "@mui/material/styles";
const LoginApp = () => {
  const BootstrapButton = styled(Button)({
    backgroundColor: "#0076ff",
    boxShadow: "none",
    width: "100%",
    
    "&:hover": {
      backgroundColor: "#025fca",
    },
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token"));

  useEffect(() => {
    const handleTokenChange = () => {
      setIsLoggedIn(!!localStorage.getItem("token"));
    };
    window.addEventListener("storage", handleTokenChange);
    return () => {
      window.removeEventListener("storage", handleTokenChange);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  }, [isLoggedIn]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/auth/login`,
        { email: formData.email, password: formData.password }
      );
      console.log("Login response:", response.data);

      toast.success("Login successful");
      localStorage.setItem("token", response.data.tokens.access.token);

      navigate("/dashboard");
    } catch (error) {
      console.error("Error Login User", error);

      //   alert(`Error: ${error.response.data.message}`);
      toast.error(`${error.response.data.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="rtlanimation">
          <Header />
      <div
        className="
    justify-center relative min-h-screen bg-slate-900 xl:bg-transparent
      flex flex-col xl:flex-row items-center xl:min-h-screen"
      >
        <ToastContainer />
        <div>
          <section className="bg-transparent ">
            <div className="flex flex-col  items-center justify-center md:px-6 md:py-4  lg:mx-auto md:h-screen lg:py-0">
              {/* <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img className="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo">
      </img> 
      </a> */}
              <div
                className="w-full rounded-lg shadow border md:mt-0 sm:max-w-lg xl:p-0 z-[5]"
                id="glassMorph"
              >
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight  md:text-2xl text-white">
                    Login account
                  </h1>
                  <form
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    className="space-y-4 md:space-y-6"
                    action="#"
                  >
                    <div>
                      <label
                        for="email"
                        className="block mb-2 text-sm font-medium  text-white"
                      >
                        Your email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        required="required"
                        value={formData.email}
                        className=" border  sm:text-sm rounded-lg  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                        placeholder="name@company.com"
                      />
                    </div>
                    <div>
                      <label
                        for="password"
                        className="block mb-2 text-sm font-medium  text-white"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="••••••••"
                        required="required"
                        value={formData.password}
                        className=" border  sm:text-sm rounded-lg  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                    <div className="flex items-start">
                      <div className=" text-sm">
                        <NavLink
                          to={"/forgot-password"}
                          className="font-semibold cursor-pointer  text-blue-500 no-underline"
                        >
                          Forgot password?
                        </NavLink>
                      </div>
                    </div>
                    <BootstrapButton variant="contained" type="submit">
                      {loading ? "logging in...." : "Login Your Account"}
                    </BootstrapButton>
                    <p className="text-sm font-light text-white ">
                      Don't have an account?{" "}
                      <NavLink
                        to={"/app/signup"}
                        className="font-medium  text-white hover:underline dark:text-primary-500"
                      >
                        Register here
                      </NavLink>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <svg
        className="z-[1] absolute bottom-0 left-0 "
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 230"
      >
        <path
          fill="#030712"
          fillOpacity="1"
          d="M0,0L40,42.7C80,85,160,171,240,197.3C320,224,400,192,480,154.7C560,117,640,75,720,74.7C800,75,880,117,960,154.7C1040,192,1120,224,1200,213.3C1280,203,1360,149,1400,122.7L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        ></path>
      </svg>
      <Footer />
    </div>
  );
};

export default LoginApp;

//  <div>
//   <Header />
//   <div className={logStyle.messageContainer}>
//     <div className={logStyle.innerArea}>
//       <div className={logStyle.messageArea}>
//         {/* <img src={logoIC} alt="Financial Logo" className={logStyle.logo} />
//         <br />
//         <br />
//         <h1>Welcome to Plenty-Cred</h1> */}
//       </div>
//     </div>
//   </div>
//   <div className={logStyle.loginArea}>
//     <div className={logStyle.innerLoginArea}>
//       {/* <center><img src="welcome.jpg" style={{width: "240px"}} /></center> */}
//       <br />
//       <br />
//       <br />
//       <h1>Login</h1>
//       <br />
//       <p>
//         Doesn't Have an account yet?
//         {/* <a href="">Sign Up</a> */}
//         <Link to="/signup"> Sign Up</Link>
//       </p>
//       <br />
//       <br />
//       <form onChange={handleChange} onSubmit={handleSubmit}>
//         <label htmlFor="email">
//           Email or Mobile
//           <br />
//           <input
//             type="email"
//             name="email"
//             className={logStyle.inputStyler}
//             placeholder="Enter Your Credentials"
//             required="required"
//             value={formData.email}
//           />
//         </label>
//         <br />
//         <br />
//         <label htmlFor="password">
//           Password
//           <br />
//           <input
//             type="password"
//             name="password"
//             className={logStyle.inputStyler}
//             placeholder="It Should Be Secured"
//             required="required"
//             value={formData.password}
//           />
//         </label>
//         <input
//           type="submit"
//           // value={"Login"}
//           value={loading ? "logging in...." : "Login"}
//           className={logStyle.submitStyler}
//         />
//         <ToastContainer />
//       </form>
//     </div>
//   </div>
// </div>
