import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import image1 from "../../core/images/Slider/1.png";
import image2 from "../../core/images/Slider/2.png";
import image3 from "../../core/images/Slider/3.png";
import image4 from "../../core/images/Slider/4.png";
import image5 from "../../core/images/Slider/5.png";
import image6 from "../../core/images/Slider/6.png";
import image7 from "../../core/images/Slider/7.png";
import image8 from "../../core/images/Slider/8.png";

const fakeData = [
  {
    id: 1,
    image: image1,
    title: "EVERYDAY LOANS",
    description: "Pocket-friendly EMIs for 7 days to 7 months",
  },
  {
    id: 2,
    image: image2,
    title: "CREDIT LIMIT",
    description:
      "Pay interest only on usage, convert to a loan, or use for Buy Now Pay Later.",
  },
  {
    id: 3,
    image: image3,
    title: "BENEFITS GALORE",
    description: "Pocket-friendly EMIs for 7 days to 7 months",
  },
  {
    id: 4,
    image: image4,
    title: "LIGHTNING FAST",
    description:
      "Access up to Rs. 10,000 within 30 seconds for your urgent money needs. ",
  },
  {
    id: 8,
    image: image8,
    title: "DIVERSE",
    description: "Explore our curated investment products for superior returns",
  },
];

const CardSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Fake data for demonstration

  const getNextIndex = (currentIndex) => (currentIndex + 1) % fakeData.length;
  const getNext2Index = (currentIndex) => (currentIndex + 2) % fakeData.length;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? fakeData.length - 1 : prevIndex - 1
    );
  };

  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === fakeData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const nextIndex = getNextIndex(currentIndex);
  const next2Index = getNext2Index(currentIndex);

  return (
    <div className="flex flex-col items-center">
      <motion.section
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1.3 }}
        className="flex flex-col lg:flex-row items-center gap-4"
      >
        <div>
          <h1 className="text-3xl text-center ">
            Unlock Possibilities Beyond Credit with PlentyCred
          </h1>
        </div>
        <div className="flex flex-row gap-2 ">
          <button
            onClick={handlePrev}
            className="px-4 bg-blue-600 py-2 text-white rounded-full  focus:outline-none"
          >
            <ArrowLeftOutlined />
          </button>
          <button
            onClick={handleNext}
            className="px-4 py-2 bg-blue-600 text-white rounded-full  focus:outline-none"
          >
            <ArrowRightOutlined />
          </button>
        </div>
      </motion.section>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1.3 }}
        className="mb-4 p-4 mt-12 flex flex-col lg:flex-row items-center gap-5"
      >
        <div className="max-w-sm w-full text-white bg-blue-600 border border-gray-200 rounded-lg shadow-2xl ">
          <div>
            <div className="p-7">
              <img
                className="rounded-t-lg"
                src={fakeData[currentIndex].image}
                width={400}
                height={400}
              />
            </div>
            <div className="p-5">
              <h5 className="font-semibold text-xl text-[#fff]">
                {fakeData[currentIndex].title}
              </h5>
              <p className="font-semilight text-[14px]">
                {fakeData[currentIndex].description}
              </p>
            </div>
          </div>
        </div>
        <div className="max-w-sm hidden lg:block bg-white border border-gray-200 rounded-lg shadow-2xl ">
          <div className="p-[20px]">
            <div className="relative w-[200px] h-[200px]">
              <img
                className="rounded-t-lg"
                src={fakeData[nextIndex].image}
                width={300}
                height={300}
              />
            </div>
            <div className="p-5">
              <h5 className="font-semibold text-xl text-[#0083ec]">
                {fakeData[nextIndex].title}
              </h5>
            </div>
          </div>
        </div>
        <div className="max-w-sm hidden lg:block bg-white border border-gray-200 rounded-lg shadow-2xl ">
          <div className="p-[20px]">
            <div className="relative w-[200px] h-[200px]">
              <img
                className="rounded-t-lg"
                src={fakeData[next2Index].image}
                width={300}
                height={300}
              />
            </div>
            <div className="p-5">
              <h5 className="font-semibold text-xl text-[#0083ec]">
                {fakeData[next2Index].title}
              </h5>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default CardSlider;
