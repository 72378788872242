import React, { useState, useEffect } from "react";
// import firebase from "../../../utils/firebase";

const AdminMobileMedia = ({folderId}) => {
  const [imageUrls, setImageUrls] = useState([]);

  // useEffect(() => {
  //   const getImagesUrls = async () => {
  //     try {
  //       const imagesRef = firebase.storage().ref(`images/${folderId}`);
  //       const imagesList = await imagesRef.listAll();

  //       const imageUrlPromises = imagesList.items.map(async (imageRef) => {
  //         return imageRef.getDownloadURL();
  //       });

  //       const urls = await Promise.all(imageUrlPromises);

  //       setImageUrls(urls);
  //     } catch (error) {
  //       console.error("Error getting image URLs from Firebase Storage:", error);
  //     }
  //   };

  //   getImagesUrls();
  // }, [folderId]);

  return (
    <div className="container p-4 mx-auto py-8">
      Firebase folder and images need to be added
      {/* <h2 className="text-2xl font-bold mb-4">Images in Folder {folderId}:</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {imageUrls.map((url, index) => (
          <div key={index} className="border rounded overflow-hidden">
            <img src={url} alt={`Image ${index}`} className="w-full h-auto" />
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default AdminMobileMedia;
