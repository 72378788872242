import { Button } from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify"; // Assuming you're using react-toastify for notifications

const Contracting = () => {
  const [loading, setLoading] = useState(false);

  const handleAgree = async () => {
    setLoading(true);

    try {
      // Get the token from localStorage
      const token = localStorage.getItem("token");

      // Prepare the request headers
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v2/contracting/create-contract`,
        {},
        config
      );

      // Check if the contract status is "INITIATED"
      if (response.data.contractStatus === "INITIATED") {
        const workflowUrl = response.data.signerdetail[0].workflowUrl;
        window.location.href = workflowUrl;
      } else {
        toast.error("Contract error: Unable to initiate the contract.");
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error creating contract:", error);
      toast.error("Error creating contract. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="">
        <h1 className="text-lg font-semibold">Complete Verification</h1>
        <div className="text-white">
          DISCLAIMER: This contract disclaimer asserts that both parties
          acknowledge and accept the terms mutually agreed upon. It is not a
          substitute for legal advice. Verify details independently. Proceeding
          indicates understanding and consent.
        </div>
        <div className="">
          <Button onClick={handleAgree} variant="contained">
            {loading ? "Sending" : "Agree"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Contracting;
