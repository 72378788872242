import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import { CloseRounded } from "@mui/icons-material";
import gif7 from "../../core/images/pgway.gif";
import png7 from "../../core/images/pgway_payment.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NACHMandate from "./NACHMandate";
import GETCredit from "./GETCredit";
import PayInterest from "./PayInterest";
import PayComplete from "./PayComplete";
import AutoPay from "./AutoPay";
import { useNavigate } from "react-router-dom";
import Payout from "./Payout";

const PaymentGateway = ({ userAccount, userVerfiyAll }) => {
  const [amount, setAmount] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [show, setShow] = useState();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    console.log("PaymentGateway : ", { userVerfiyAll, userAccount });
    setUserDetails(userAccount);
    setShow(userVerfiyAll);
  }, []);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/v1/admin/get-amount`,
        {},
        { headers }
      )
      .then((response) => {
        setAmount(response.data.data);
      })
      .catch((error) => {
        console.error("Error sending POST request:", error);
      });
  }, []);

  return (
    <>
      <ToastContainer />
      <br />
      <div className="mt-20 overflow-hidden">
        <div className="flex flex-wrap items-center justify-center gap-10 flex-gap">
          <div className="flex-[1]">
            <div className="bg-white rounded-lg shadow-lg p-10 border-b-blue-800 border-b-[8px]">
              {show?.NachPending ? (
                <div className="text-2xl font-medium justify-center items-center flex h-32">
                  <h1>Verification is Pending</h1>
                </div>
              ) : (
                <>
                  {show?.PaymentAccess ? (
                    <>
                      <center>
                        {!show?.NachMandate ? (
                          <NACHMandate userDetails={userDetails} show={show} />
                        ) : (
                          <div className="flex flex-wrap gap-10 justify-center items-center p-10">
                            <div className="flex-[2]">
                              <div
                                className="rounded-full  flex flex-wrap justify-center items-center border-[8px] border-b-[#f1c232] border-r-[#ff5c5c] border-l-[#11c4be] border-t-blue-600 shadow-xl"
                                id="rtlanimation"
                              >
                                <div className="font-bold p-10 text-4xl text-white">
                                  {amount?.alootedAmount || "No amount added"}
                                </div>
                              </div>
                              {amount?.removedAmount && (
                                <div className="mt-2 text-red-600 text-2xl font-medium">
                                  -{amount.removedAmount}
                                </div>
                              )}
                            </div>
                            <div className="flex-[1.4]">
                              <AutoPay isLocked={!show?.AutoPayAccess} />
                              <Payout  userDetails={userDetails}/>
                              <PayInterest />
                              <PayComplete />
                              <div className="mt-5">
                                <GETCredit />
                              </div>
                            </div>
                          </div>
                        )}
                      </center>
                    </>
                  ) : (
                    <div className="text-2xl font-medium justify-center items-center flex h-32">
                      Complete the full verification in Dashboard
                    </div>
                  )}
                </>
              )}
              <button
                onClick={() => navigate("/")}
                className="mt-4 px-4 py-2 bg-orange-500 text-white rounded"
              >
                Go to Home
              </button>
              <center>
                <img
                  src={png7}
                  alt="Payment Gateway"
                  className="max-w-[350px]"
                />
              </center>
            </div>
          </div>
          <div className="hidden xl:flex flex-[0.8]">
            <img src={gif7} alt="Payment Processing Animation" />
          </div>
        </div>
      </div>

      <Modal isOpen={false} onRequestClose={() => {}}>
        <div className="flex flex-col gap-2 items-center justify-center">
          <button
            onClick={() => {}}
            className="absolute top-2 right-2 text-black"
          >
            <CloseRounded />
          </button>
          <div className="flex flex-col items-center justify-center gap-5">
            <p className="text-2xl">Processing...</p>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              Click here to proceed with payment
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PaymentGateway;
