import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import axios from "axios";

export default function DashMobileNav() {
  const [state, setState] = React.useState({
    left: false,
  });
  const [show, setShow] = React.useState("");

  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  React.useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/v2/contracting/verify-all`,
        {},
        { headers }
      )
      .then((response) => {
        setShow(response.data.VerifyAllData);
      })
      .catch((error) => {
        console.error("Error sending POST request:", error);
      });
  }, []);

  console.log(show);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemButton>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText>
              <NavLink to={"/dashboard"}>Dashboard</NavLink>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        {show.cibilVerify ? (
          <>
            <ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <MailIcon />
                </ListItemIcon>
                <ListItemText>
                  <NavLink to={"/dashboard/kyc"}>Kyc</NavLink>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <MailIcon />
                </ListItemIcon>
                <ListItemText>
                  <NavLink to={"/dashboard/photo"}>Live Photo</NavLink>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <MailIcon />
                </ListItemIcon>
                <ListItemText>
                  <NavLink to={"/dashboard/employmentdetails"}>
                    Employment Details
                  </NavLink>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </>
        ) : (
          <></>
        )}
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            className="absolute bg-transparent top-5 left-1"
          >
            <FontAwesomeIcon icon={faBars} className="text-white text-xl" />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
