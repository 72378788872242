import React from "react";

const NavbarComponent = ({ onPageChange }) => {
  return (
    <div className="flex flex-row">
      <li className="mr-6">
        <div
          className="cursor-pointer text-gray-700 hover:text-gray-900"
          onClick={() => {
            onPageChange(0);
          }}
        >
          Home
        </div>
      </li>
      <li className="mr-6">
        <div
          className="cursor-pointer text-gray-700 hover:text-gray-900"
          onClick={() => {
            onPageChange(1);
          }}
        >
          Products
        </div>
      </li>
      <li className="mr-6">
        <div
          className="cursor-pointer text-gray-700 hover:text-gray-900"
          onClick={() => {
            onPageChange(2);
          }}
        >
          About us
        </div>
      </li>
      <li className="mr-6">
        <div
          className="cursor-pointer text-gray-700 hover:text-gray-900"
          onClick={() => {
            onPageChange(3);
          }}
        >
         Blogs
        </div>
      </li>
    </div>
  );
};

export default NavbarComponent;
