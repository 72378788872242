import React from "react";
import policyStyle from "../core/css/tandc.module.css";
import { Link,NavLink } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const DisclosurePolicy = () => {
    return (
        <>
        <Header/>

            <div className={policyStyle.container}>
            <div className={policyStyle.upperContainerLink}>
            <NavLink onClick={()=>window.location.reload()} className={policyStyle.linkStyle}>Home &gt;{" "}</NavLink>
                    <h3>Disclosure Policy</h3>
                </div>
                <div className={policyStyle.upperContainer}>
                    <div>
                        We're all ears! For any queries, call us on +91-7058111852
                    </div>
                    <div>
                        (MON to SAT 10 AM to 11 PM).
                    </div>
                    {/* <hr style={{backgroundColor : "#06BCF1",height : "3px",color : "06BCF1"}}/>
                    <h1>
                        Responsible Disclosure Policy
                    </h1> */}
                </div>
                <div className={policyStyle.upperContainer}>
                    <h1>
                        Responsible Disclosure Policy
                    </h1>
                </div>
                <div id="wrapper" className={policyStyle.lowerContainer}>

                    <ul className={policyStyle.innerLowerContainer}>
                        <li>
                            <div>
                                Policy
                            </div>
                            <p>
                                Plentycred is committed to the safety and security of its systems and services and to the integrity of its data. We recognize the valuable role of the security research community, and we welcome reports from researchers, both of potential vulnerabilities in our systems and of confidential data from or relating to our services that may be accessible by unauthorized persons.
                            </p>
                            <p>
                                Plentycred will not initiate legal action against anyone who makes a report in compliance with this policy.
                            </p>
                        </li>
                        <li>
                            <div>
                                Disclosure Policy
                            </div>
                            <p>
                                If a researcher follows the rules set out in this Responsible Disclosure Policy when reporting a security vulnerability to us, unless prescribed otherwise by law or the payment scheme rules, we commit to:
                            </p>
                            <ul style={{ listStyleType: "disc" }}>
                                <li>
                                    Promptly acknowledging receipt of your vulnerability report and work with the researcher to understand and attempt to resolve the issue quickly.
                                </li>
                                <li>
                                    Validating, responding and fixing such vulnerability in accordance with our commitment to security and privacy. We will notify you when the issue is fixed.
                                </li>
                                <li>
                                    Unless prescribed by law otherwise, not pursuing or take legal action against you or the person who reported such security vulnerabilities.
                                </li>
                                <li>
                                    Not suspending or terminate access to our service(s) if you are a merchant. If you are an agent, not suspending or terminating merchant's access to our services to which the agent represents.
                                </li>
                            </ul>
                            <p>
                                Plentycred does not offer a bug bounty program or compensation for disclosure.
                            </p>
                        </li>
                        <li>
                            <div>
                                In Scope of this Policy
                            </div>
                            <p>
                                Any of the Plentycred services, iOS or Android-based apps, which process, store, transfer or use in one way or personal or sensitive personal information. Web service vulnerabilities are classified using OWASP Top-10. Mobile application vulnerabilities are classified using OWASP Mobile Top-10.
                            </p>
                        </li>
                        <li>
                            <div>
                                Domains
                            </div>
                            <p>
                                <Link to="/" style={{ textDecoration: "none", color: "black" }}>www.plentycred.co.in</Link>
                            </p>
                        </li>
                        <li>
                            <div>
                                Out of Scope
                            </div>
                            <p>
                                Any services hosted by third party providers and services not provided by Plentycred.
                            </p>
                        </li>
                        <li>
                            <div>
                                Testing
                            </div>
                            <p>
                                A researcher can test only against an end user/merchant account if they are an account owner, or an agent authorized by the account owner to conduct such testing. As a researcher, in no event are you permitted to access, download or modify data residing in any other account or that does not belong to you or attempt to do any such activities. In the interest of the safety of our merchants, users, employees, the internet at large and you as a researcher, the following test types are expressly excluded from scope and testing: any findings from physical testing (office access, tailgating, open doors) or DOS or DDOS vulnerabilities. A responsible disclosure also does not include identifying any spelling mistakes, or any UI and UX bug.
                            </p>
                        </li>
                        <li>
                            <div>
                                The rules
                            </div>
                            <h3>We require that all researchers must:</h3>
                            <ul style={{ listStyleType: "disc" }}>
                                <li>
                                    Make every effort to avoid privacy violations, degradation of user or merchant experience, disruption to production systems, and destruction of data during security testing.
                                </li>
                                <li>
                                    Not attempt to gain access to any other person's account, data or personal information.
                                </li>
                                <li>
                                    Use the identified email address to report any vulnerability information to us;
                                </li>
                                <li>
                                    Use the identified email address to report any vulnerability information to us;
                                </li>
                                <li>
                                    Keep information about any vulnerabilities you've discovered confidential between yourself and Plentycred. Plentycred will take a reasonable time to remedy such vulnerability (approximately 3 months as a minimum but this is dependent on the nature of the security vulnerability and regulatory compliance by Plentycred). The researcher shall not publicly disclose the bug or vulnerability on any online or physical platform before it is fixed and prior written approval to publicly disclose from Plentycred;
                                </li>
                                <li>
                                    Not perform any attack that could harm the reliability, integrity, and capacity of our services. DDoS/spam attached not allowed.
                                </li>
                                <li>
                                    Not use scanners or automated tools to find vulnerabilities (noisy and we may automatically suspend your account and ban your IP address);
                                </li>
                                <li>
                                    As a researcher, you represent and warrant that you have the right, title and interest to disclose any vulnerability found and to submit any information, including documents, codes, among others, in connection therewith. Once you inform a vulnerability, you grant Plentycred, its subsidiaries and affiliates an irrevocable, worldwide, royalty-free, transferrable, sub-licensable right to use in any way Plentycred deems appropriate for any purpose, such as: reproduction, modification, distribution, adaptation among other uses, the information related with the vulnerabilities. Further, you hereby waive all other claims of any nature, including express contract, implied-in-fact contract, or quasi-contract, arising out of any disclosure accepted by Plentycred. Remember that you must never attempt non-technical attacks such as social engineering, phishing, or physical attacks against our employees, users, or infrastructure.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div>
                                Reporting Potential Vulnerabilities
                            </div>
                            <h3>
                                The information in your report is extremely sensitive. To make a report to Plentycred Information Security, email to assist@plentycred.co.in
                                Reports should include the following information:
                            </h3>
                            <ul style={{ listStyleType: "disc" }}>
                                <li>
                                    Your name and contact information.
                                </li>
                                <li>
                                    Your organization (if applicable) or LinkedIn/Twitter profile URLs;
                                </li>
                                <li>
                                    The Plentycred services that may be affected;
                                </li>
                                <li>
                                    A detailed description of the issue that you've discovered.
                                </li>
                                <li>
                                    Supporting technical details, including descriptions or examples of exploit/attack code, packet captures, and steps to reproduce the issue.
                                </li>
                                <li>
                                    Any known information about live exploits.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div>
                                Indemnification
                            </div>
                            <h3>
                                Researcher shall fully indemnify, hold harmless and defend (collectively “indemnify” and “indemnification”) Plentycred, its subsidiaries and affiliates, its directors, officers, employees, agents, and stockholders (collectively, “Indemnified Parties”) from and against all claims, demands, actions, suits, damages, liabilities, losses, settlements, judgments, costs and expenses (including but not limited to reasonable attorney's fees and costs), whether or not involving a third party claim, which arise out of or relate to:
                            </h3>
                            <ul style={{ listStyleType: "disc" }}>
                                <li>
                                    Any breach of any representation or warranty contained in this Responsible Disclosure Policy made by the researcher.
                                </li>
                                <li>
                                    Any breach or violation of the terms of this Responsible Disclosure Policy or any obligation or duty of the Researcher referred therein or under applicable law.
                                </li>
                                <li>
                                    Any breach of the confidentiality.
                                </li>
                                <li>
                                    Any breach of any waiver granted.
                                </li>
                                <li>
                                    Any attempt to contact Plentycred clients, users or third parties to inform the existence of the vulnerability. It includes any reference or message in social media referring to the finding.
                                </li>
                                <li>
                                    Any attempt to bring direct or indirectly claims, lawsuits, demands, actions judgments against Plentycred or any other Indemnified Party, in each case whether caused by the negligence of Plentycred or any other Indemnified Party and whether or not the relevant claim has merit.
                                </li>
                            </ul>
                        </li>
                        <br />
                    </ul>
                </div>
            </div>
            <br />
                    <Footer />
        </>
    );
}


export default DisclosurePolicy;