import React from "react";
import policyStyle from "../core/css/tandc.module.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import {
  faFacebook,
  faInstagram,
  faThreads,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ROI = () => {
  return (
    <div className="bg-white">
      <Header />
      <br />
      <div id="wrapper" className="mt-20">
        <div className={policyStyle.upperContainerLink}>
          <NavLink
            onClick={() => window.location.reload()}
            className={policyStyle.linkStyle}
          >
            Home &gt;{" "}
          </NavLink>
          <h3>ROI Processing</h3>
        </div>
        <div>
          <div className={policyStyle.upperContainer}>
            <h1>ROI Processing</h1>
          </div>
        </div>
        <div className="flex flex-row items-center">
          <NavLink
            onClick={() => window.location.reload()}
            className={policyStyle.linkStyle}
          >
            <FontAwesomeIcon
              icon={faFacebook}
              size={30}
              color="blue"
              className="pr-2"
            />
          </NavLink>
          <NavLink
            to="https://www.instagram.com/plenty_cred/"
            className="transition hover:text-gray-600"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              size={30}
              color="purple"
              className="pr-2"
            />
          </NavLink>
          <NavLink
            to="https://www.threads.net/@plenty_cred"
            className="transition hover:text-gray-600"
          >
            <FontAwesomeIcon icon={faThreads} size={30} className="pr-2" />
          </NavLink>
          <NavLink
            to="https://www.youtube.com/channel/UCYqaLhxr4j7C-_dCe49Hfiw"
            className="transition hover:text-gray-600"
          >
            <FontAwesomeIcon
              icon={faYoutube}
              size={30}
              color="red"
              className="pr-2"
            />
          </NavLink>
        </div>
        <div className={policyStyle.innerLowerContainer}>
          <h2>1. INTRODUCTION</h2>
          <p>
            In order to ensure its standards of transparency, in conformity with
            the stipulations of the Reserve Bank of India’s (hereafter referred
            to as ‘RBI’) directives, Plenty Cred (“Plenty Cred”) has adopted the
            following interest rate policy:
          </p>
          <h2>2. INTEREST RATE MODEL AND APPROACH FOR GRADATION OF RISK</h2>
          <p>
            The interest rate to be charged to the borrower for the loans will
            be decided keeping in view the RBI’s guidelines.
            <br />
            <br />
            Interest rate will be arrived based on the following broad
            parameters:
            <br />
            a) Risk profile of the borrower;
            <br />
            b) Interest rate trend prevailing in the money market;
            <br />
            c) Cost of borrowings;
            <br />
            d) Interest rate charged by competitors;
            <br />
            e) Tenure of the borrowing;
            <br />
            f) Historical track record of the borrower with the Plenty Cred ;
            <br />
            g) Administrative cost and profit margin.
            <br />
            <br />
            Interest rate would be intimated to the borrower at the time of
            sanction / availing of the loan. The rate of interest for the same
            product and tenor availed during same period by different borrower
            need not be uniform. It could vary for different products for the
            same or different borrower.
            <br />
            <br />
            The interest rates could be offered on fixed/variable rate basis.
            Interest is calculated on the loan amount sanctioned to the
            borrower. The interest shall be payable immediately on the due date
            as mentioned in the loan agreement oras communicated.
            <br />
            <br />
            In case of delay in payment of any dues by the borrower, the agreed
            upon rate of interest may been enhanced accordingly as delayed
            interest rate and such enhanced delayed rate of interest may be
            applied on the unpaid liability, indebtedness or part thereof which
            is due but not paid on due date for the period it remains unpaid.
            The aim of the Plenty Cred is not to make money out of it but to
            encourage prompt and timely repayment of dues of the Plenty Cred and
            to deter the borrower from intentional delay in honouring
            commitments. However, in deserving cases, the incremental rate on
            interest may be completely waived off or settled at reduced rates.
            <br />
            <br />
            No interest is payable on credit balance in borrower’s account.
            <br />
            <br />
            No interest is payable on credit balance in borrower’s account. The
            final lending rate for each loan or various products offered by
            Plenty Cred will be arrived at after taking into account market
            reputation, prevailing interest rate in the market, credit and
            default risk associated with the particular borrower/borrowers’
            group, historical performance of the borrower/borrowers’ group,
            profile of the borrower/borrowers’ group, tenure of relationship
            with the borrower/borrowers’ group, the administrative cost and
            profit margin, etc. Such information is gathered based on
            information provided by the
            <br />
            <br />
            borrower/borrowers’ group, credit reports, market intelligence and
            any other information as available to Plenty Cred.
            <br />
            <br />
            The lending rate of Plenty Cred should not be less than the lowest
            of the rate of interest charged on the funds borrowed by Plenty
            Cred. The lending rate is subject to change as per the discretion of
            management of Plenty Cred based on the variables as set by the
            management of Plenty Cred.
          </p>
          <br />
          <br />
          <h2>3. PROCESSING / DOCUMENTATION AND OTHER CHARGES</h2>
          <br />
          <p>
            The processing/ documentation and other charges recovered will be
            expressly stated in the Loan Agreement.
            <br />
            <br />
            The Plenty Cred may levy any of the below mentioned fees, charges,
            etc. to the borrower on case-to-case basis:
            <br />
            <br />
          </p>
          <table className="tg">
            <thead>
              <tr>
                <th className="tg-0pky">a) Loan Processing Charges</th>
                <th className="tg-0pky">
                  To be charged to the borrower/s for expenses pertaining to
                  documentation, agreement, etc.
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tg-0pky">b) Delayed Payment Charges</td>
                <td className="tg-0pky">
                  To be charged on the unpaid dues/liability where the borrower
                  defaults on EMI due date.
                </td>
              </tr>
              <tr>
                <td className="tg-0pky">c) Part Prepayment</td>
                <td className="tg-0pky">NIL</td>
              </tr>
              <tr>
                <td className="tg-0pky">d) Foreclosure</td>
                <td className="tg-0pky">
                  For floating rate loans-Nil, For Fixed rate loans- as decided
                  by the management
                </td>
              </tr>
              <tr>
                <td className="tg-0pky">e) Cheque/NACH Bouncing Charges</td>
                <td className="tg-0pky">
                  To be charged to the borrower/s in each instance that any
                  cheque is dishonoured (under any of the payment modes) and
                  consequently represented OR in each instance that a cheque/
                  pay order/ demand draft is presented when any instalment/s
                  is/are not received by the PlentyCred by /upon issue of debit
                  instructions under the ECS method or direct debit method or
                  any other payment method (other than the PDC method) as
                  selected by the borrower/s for any reasons whatsoever.
                </td>
              </tr>
              <tr>
                <td className="tg-0pky">Loan Recovery Charge</td>
                <td className="tg-0pky">
                  To be charged for the expense incurred by the Plenty Cred to
                  recover the dues including legal fees, duties, notices,
                  advertisement, etc.
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <p>
            The aim of PlentyCred to levy the above mentioned charges other than
            those mentioned as Loan Processing Charges is not to make money out
            of it but to encourage prompt and timely repayment of dues of the
            PlentyCred and to deter the borrower against intentional
            delinquency. However, in deserving cases, such additional / penal
            interest may be completely waived off or settled at much lower rates
          </p>
          <br />
          <h2>4. DISCLOSURE</h2>
          <br />
          <p>
            Appropriate disclosure regarding this Interest Rate Policy shall be
            made on the Company website.
          </p>
          <br />
          <h2>5. POLICY REVIEW</h2>
          <br />
          <p>
            This policy shall be reviewed and updated periodically for any
            changes and be approved by Board of the Directors.
          </p>
          <br />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ROI;
