import React from "react";

const AdminPercentage = ({ show }) => {
  console.log("AdminPercentage", show);

  const {
    aadharVerify,
    cibilVerify,
    signzySent,
    itrVerify,
    LivePhotoVerify ,
    edVerify,
  } = show;

  console.log({
    aadharVerify,
    cibilVerify,
    signzySent,
    itrVerify,
    LivePhotoVerify,
    edVerify,
  });

  const totalSteps = 6;
  const completedSteps = [
    aadharVerify,
    cibilVerify,
    signzySent,
    itrVerify,
    LivePhotoVerify,
    edVerify,
  ].filter(Boolean).length;

  const percentage = Math.round((completedSteps / totalSteps) * 100);

  return (
    <div
      className="bg-blue-600  p-4 rounded-md"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          width: "80px",
          height: "80px",
          border: "8px solid #ccc",
          borderRadius: "50%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "8px",
        }}
      >
        <div
          style={{
            width: `${percentage}%`,
            height: "100%",
            background: "linear-gradient(to right, #00c853, #64dd17)",
            borderRadius: "50%",
            transition: "width 0.5s ease",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          {percentage}%
        </div>
      </div>
      <p className="text-white" style={{ fontWeight: "bold" }}>
        Verification Progress
      </p>
    </div>
  );
};

export default AdminPercentage;
