import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import policyStyle from "../core/css/tandc.module.css";
import { Link, NavLink } from "react-router-dom";
import {
  faFacebook,
  faInstagram,
  faThreads,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faArrowRight, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IoLogoGooglePlaystore } from "react-icons/io5";

const Accessibility = () => {
  return (
    <>
      <Header />
      <br />
      <br />
      <br />
      <div className={policyStyle.upperContainerLink}>
        <NavLink to="/" className={policyStyle.linkStyle}>
          Home &gt;{" "}
        </NavLink>
        <h3>Accessibility</h3>
      </div>
      <div className={policyStyle.upperContainer}>
        <h1>ACCESSIBILITY</h1>
      </div>
      <div id="wrapper" className="flex flex-row items-center">
        <NavLink to="#" className="transition hover:text-gray-600">
          <FontAwesomeIcon
            icon={faFacebook}
            size={30}
            color="blue"
            className="pr-2"
          />
        </NavLink>
        <NavLink
          to="https://www.instagram.com/plenty_cred/"
          className="transition hover:text-gray-600"
        >
          <FontAwesomeIcon
            icon={faInstagram}
            size={30}
            color="purple"
            className="pr-2"
          />
        </NavLink>
        <NavLink
          to="https://www.threads.net/@plenty_cred"
          className="transition hover:text-gray-600"
        >
          <FontAwesomeIcon icon={faThreads} size={30} className="pr-2" />
        </NavLink>
        <NavLink
          to="https://www.youtube.com/channel/UCYqaLhxr4j7C-_dCe49Hfiw"
          className="transition hover:text-gray-600"
        >
          <FontAwesomeIcon
            icon={faYoutube}
            size={30}
            color="red"
            className="pr-2"
          />
        </NavLink>
      </div>
      <div id="wrapper">
        <div className="flex flex-col  justify-start items-center">
          <h1 className="text-blue-500 text-3xl">
            Our Commitment to Accessibility
          </h1>
          <br />

          <p>
            {" "}
            Plentycred is committed in making our website's content accessible
            and user friendly to everyone. If you are having difficulty viewing
            or navigating the content on this website, or notice any content,
            feature, or functionality that you believe is not fully accessible
            to people with disabilities, please email our Customer Service team
            at assist@plentycred.co.in with “Disabled Access” in the subject
            line and provide a description of the specific feature you feel is
            not fully accessible or a suggestion for improvement.{" "}
          </p>
          <br />
          <p>
            We take your feedback seriously and will consider it as we evaluate
            ways to accommodate all of our customers and our overall
            accessibility policies. Additionally, while we do not control such
            vendors, we strongly encourage vendors of third-party digital
            content to provide content that is accessible and user friendly.
          </p>
        </div>
      </div>
      <br />
      <br />

      <Footer />
    </>
  );
};

export default Accessibility;
