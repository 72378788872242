import React, { useState, useEffect, useRef } from "react";
import "../core/css/home.module.css";
import Header from "../components/Header";
import homeStyle from "../core/css/home.module.css";
import Footer from "../components/Footer";
import Home3 from "../components/HomePage/Home3";
import SignUpRoute from "../components/SignUpRoute";
import Home2 from "../components/HomePage/Home2";
import { LoanCalculator } from "../components/LoanCal";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import Home4 from "../components/HomePage/Home4";
import Products from "./Products";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import About from "./About";
import styles from "../core/css/header.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import longLogo from "../core/images/logo-noncopyright.png";
import FaceIcon from "@mui/icons-material/Face";
import StraightIcon from "@mui/icons-material/Straight";
import {
  faBars,
  faTimes,
  faUser,
  faChevronRight,
  faArrowRight,
  faTruckFast,
  faForward,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faThreads,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import logoIC from "../core/images/logo-noncopy.png";
import MobileNav from "../components/Header/MobileNav";
import NavMenu from "../components/Header/NavMenu";
import { jwtDecode } from "jwt-decode";
import NavbarDrop from "../components/Header/NavbarDrop";
import useDeviceType from "../components/Props/DeviceType";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import NavbarComponent from "../components/NavCompRoutes/NavbarComponent";
import MainHome from "../components/HomePage/MainHome";
import RSS from "./RSS";
import { useViewManager } from "../components/NavCompRoutes/UseViewManger";
import MainPayment from "../components/Payments/PaymentRoutes";
import Payment from "../components/Payments/Payment";
import FaQ from "../extras/Faqpages";
import ElPage from "./ElPage";
import Financialdictionary from "./Financialdictionary";
import Investment from "./Investment";
import Contact from "./Contact";
import Login from "./Login";
import Register from "./Register";
import ScrollToTop from "../components/Props/ScrollonTop";
import Disclaimer from "../extras/Disclaimer";
import Accessibility from "./Accessibility";
import TandC from "../extras/TandC";
import PrivacyPolicyApp from "./PrivacyPolicyApp";
import Responsible from "../extras/Responsible";
import SecurityPolicy from "../extras/SecurityPolicy";
import FPracticesCode from "../extras/FPracticesCode";
import GRPolicy from "../extras/GRPolicy";
import DisclosurePolicy from "../extras/DisclosurePolicy";
import ROI from "../extras/ROI";
import Refund from "../extras/Refund";
import FooterMain from "../components/FooterMain";
import PrivacyPolicy from "../extras/PrivacyPolicy";
import Whyus from "./Whyus";
import BBPS from "../components/BBPS/BBPS";

const BootstrapButton = styled(Button)({
  fontWeight: "600",
  border: "2px solid #0083ec",
  backgroundColor: "transparent",
  textTransform: "Capitalize",
  borderRadius: "10px",
  boxShadow: "none",
  color: "#0083ec",
  "&:hover": {
    backgroundColor: "#025fca",
    color: "#fff",
  },
});

const Home = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(true);
  const [userDetails, setUserDetails] = useState("");
  const { currentViewIndex, changeView } = useViewManager();
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem("token") // Initialize based on token
  );

  const divVariants = {
    hidden: { opacity: 0, y: -100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  const views = [
    <MainHome />, // 0
    <Products />, // 1
    <About />, // 2
    <RSS />, // 3
    <Payment PageChange={changeView} />, // 4
    <FaQ />, // 5
    <ElPage />, // 6
    <Financialdictionary />, // 7
    <Investment />, // 8
    <Contact />, // 9
    <Login changeView={changeView} />, // 10
    <Register changeView={changeView} />, // 11
    <Disclaimer />, // 12
    <Accessibility />, // 13
    <TandC />, // 14
    <PrivacyPolicy />, // 15
    <DisclosurePolicy />, // 16
    <SecurityPolicy />, // 17
    <FPracticesCode />, // 18
    <GRPolicy />, // 19
    <Responsible />, // 20
    <ROI />, // 21
    <Refund />, //22
    <Whyus />, //23
    <BBPS />, //24
  ];

  const navigate = useNavigate();
  const deviceType = useDeviceType();

  useEffect(() => {
    const handleTokenChange = () => {
      setIsLoggedIn(!!localStorage.getItem("token"));
    };
    window.addEventListener("storage", handleTokenChange);
    return () => {
      window.removeEventListener("storage", handleTokenChange);
    };
  }, []);

  useEffect(() => {
    // Check if token is present in local storage
    if (isLoggedIn && localStorage.getItem("token")) {
      const token = localStorage.getItem("token");
      const decodeToken = jwtDecode(token);
      const userId = decodeToken.id || decodeToken.sub;

      axios
        .get(`${process.env.REACT_APP_API_KEY}/v1/auth/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response);
          setUserDetails(response.data.userDetails);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isLoggedIn]);

  const handleCloseforMenu = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  return (
    <>
      <section>
        <nav className={styles.navbar}>
          {/* <div className="container"> */}
          <div className={styles.container}>
            <div
              onClick={() => {
                changeView(0);
              }}
              className={styles.navbarBrand}
            >
              <img src={longLogo} alt="logo" className="w-[145px]" />
            </div>

            <div className={styles.credContainer} id={styles.desktop}>
              <ul className={styles.navbarNav} id={styles.desktop}>
                <li className={styles.navItem}>
                  <div
                    className={styles.navLink}
                    onClick={() => {
                      changeView(0);
                    }}
                  >
                    Home
                  </div>
                </li>
                <li className={styles.navItem}>
                  <div
                    className={styles.navLink}
                    onClick={() => {
                      changeView(1);
                    }}
                  >
                    Products
                  </div>
                </li>
                <li className={styles.navItem}>
                  <div
                    className={styles.navLink}
                    onClick={() => {
                      changeView(2);
                    }}
                  >
                    About us
                  </div>
                </li>
                <li className={styles.navItem}>
                  <div
                    className={styles.navLink}
                    onClick={() => {
                      changeView(24);
                    }}
                  >
                    BBPS
                  </div>
                </li>
                <li className={styles.navItem}>
                  <div
                    className={styles.navLink}
                    onClick={() => {
                      changeView(3);
                    }}
                  >
                    Blogs
                  </div>
                </li>
                <li className={styles.navItem}>
                  <div
                    className={styles.navLink}
                    onClick={() => {
                      changeView(23);
                    }}
                  >
                    Why us
                  </div>
                </li>
                <li className={styles.navItem}>
                  <NavbarDrop ChangeView={changeView} />
                </li>
                <NavLink
                  to={
                    "https://play.google.com/store/apps/details?id=com.application.plentycredapp"
                  }
                  className="hidden xl:block"
                >
                  <li className={styles.navItem}>
                    <IoLogoGooglePlaystore color="#008dff" size={30} />
                  </li>
                </NavLink>
              </ul>
              <div>
                <div>
                  {isLoggedIn ? (
                    <div className="flex flex-row gap-4 ">
                      <NavMenu ChangeView={changeView} />
                    </div>
                  ) : (
                    <div className={"flex flex-row"} id={styles.desktop}>
                      <>
                        <div className={styles.cred}>
                          <div
                            onClick={() => {
                              changeView(10);
                            }}
                            className={styles.linkStyle}
                          >
                            <BootstrapButton variant="contained">
                              <StraightIcon /> Login
                            </BootstrapButton>
                          </div>
                        </div>
                        <div className={styles.cred}>
                          <div
                            onClick={() => {
                              changeView(11);
                            }}
                            className={styles.linkStyle}
                          >
                            <BootstrapButton variant="contained">
                              <FaceIcon /> Signup
                            </BootstrapButton>
                          </div>
                        </div>
                      </>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row xl:hidden items-center gap-4">
              <Link
                to={
                  "https://play.google.com/store/apps/details?id=com.application.plentycredapp"
                }
                className="flex flex-row lg:hidden"
              >
                <IoLogoGooglePlaystore size={30} />
              </Link>
              <div
                onClick={() => {
                  setIsNavExpanded(!isNavExpanded);
                }}
                className={styles.hamburger}
                id={styles.mobile}
              >
                <button className={styles.hamburger}>
                  <FontAwesomeIcon className={styles.icon} icon={faBars} />
                </button>
              </div>
            </div>
          </div>
          {isNavExpanded ? (
            <></>
          ) : (
            <>
              <div
                onClick={() => {
                  setIsNavExpanded(!isNavExpanded);
                }}
                className={styles.invsiblediv}
              ></div>
            </>
          )}
        </nav>

        <div
          id={styles.siderNavigation}
          className={
            isNavExpanded ? styles.mobileMenuHide : styles.mobileMenuShow
          }
        >
          <motion.div>
            <div>
              <div className={styles.hamburgerFlex} style={{ padding: "5px" }}>
                {isLoggedIn ? (
                  <>
                    <div className="p-1 flex-col gap-5 flex">
                      <div className="p-2">
                        <img src={longLogo} alt="logo" className="w-[105px]" />
                      </div>

                      <Button
                        onClick={() => {
                          localStorage.removeItem("token");
                          navigate("/");
                        }}
                        variant="contained"
                      >
                        LOG OUT
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col">
                    <div className="p-1">
                      <img src={longLogo} alt="logo" className="w-[145px]" />
                    </div>
                    <div>
                      <div>
                        <div className={"flex flex-row"}>
                          <div className={styles.cred}>
                            <div
                              onClick={() => {
                                changeView(10);
                                setIsNavExpanded(!isNavExpanded);
                              }}
                              className={styles.linkStyle}
                            >
                              <BootstrapButton variant="contained">
                                <StraightIcon /> Login
                              </BootstrapButton>
                            </div>
                          </div>
                          <div className={styles.cred}>
                            <div
                              onClick={() => {
                                changeView(11);
                                setIsNavExpanded(!isNavExpanded);
                              }}
                              className={styles.linkStyle}
                            >
                              <BootstrapButton variant="contained">
                                <FaceIcon /> Signup
                              </BootstrapButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div style={{ flex: "1" }}>
                  <button
                    onClick={() => {
                      setIsNavExpanded(!isNavExpanded);
                    }}
                    className={styles.hamburgerclose}
                  >
                    <FontAwesomeIcon className={styles.icon} icon={faTimes} />
                  </button>
                </div>
              </div>

              <div style={{ padding: "20px" }}>
                <hr />
                <div className="flex  flex-col  gap-4">
                  <div
                    className={"p-1 flex items-center text-sm  flex-row gap-3"}
                    onClick={() => {
                      changeView(0);
                      setIsNavExpanded(!isNavExpanded);
                    }}
                  >
                    <p>Home</p>
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                  </div>
                  <div
                    className={"p-1 flex items-center text-sm  flex-row gap-3"}
                    onClick={() => {
                      changeView(2);
                      setIsNavExpanded(!isNavExpanded);
                    }}
                  >
                    <p>About</p>{" "}
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                  </div>
                  <div
                    className={"p-1 flex items-center text-sm flex-row gap-3"}
                    onClick={() => {
                      changeView(3);
                      setIsNavExpanded(!isNavExpanded);
                    }}
                  >
                    <p>Blogs</p>{" "}
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                  </div>
                  <div
                    className={"p-1 flex items-center text-sm  flex-row gap-3"}
                    onClick={() => {
                      navigate("/payment")
                      setIsNavExpanded(!isNavExpanded);
                    }}
                  >
                    <p>Payment</p>
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                  </div>
                  <div
                    className={"p-1 flex items-center text-sm  flex-row gap-3"}
                    onClick={() => {
                      changeView(24);
                      setIsNavExpanded(!isNavExpanded);
                    }}
                  >
                    <p>BBPS</p>
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                  </div>
                  <div
                    onClick={() => {
                      changeView(8);
                      setIsNavExpanded(!isNavExpanded);
                    }}
                    className={"p-1 flex items-center text-sm  flex-row gap-3"}
                  >
                    <p>Investment</p>
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                  </div>
                  <div
                    className={"p-1 flex items-center text-sm  flex-row gap-3"}
                    onClick={() => {
                      changeView(1);
                      setIsNavExpanded(!isNavExpanded);
                    }}
                  >
                    <p>Products</p>
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                  </div>
                  {isLoggedIn ? (
                    <>
                      <NavLink
                        className={
                          "p-1 flex items-center text-sm  flex-row gap-3"
                        }
                        to={"/dashboard"}
                      >
                        <p>Dashboard</p>{" "}
                        <FontAwesomeIcon
                          icon={faChevronRight}
                        ></FontAwesomeIcon>
                      </NavLink>
                      <NavLink
                        className={
                          "p-1 flex items-center text-sm  flex-row gap-3"
                        }
                        to={"/payment"}
                      >
                        <p>Payments</p>{" "}
                        <FontAwesomeIcon
                          icon={faChevronRight}
                        ></FontAwesomeIcon>
                      </NavLink>
                    </>
                  ) : (
                    <></>
                  )}
                  <div>
                    <MobileNav
                      ChangeView={changeView}
                      closeNav={handleCloseforMenu}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.innerMobileMenu}></div>
          </motion.div>
        </div>
      </section>

      <div>
        <div>{views[currentViewIndex]}</div>
      </div>

      <FooterMain changeView={changeView} />
    </>
  );
};
export default Home;
