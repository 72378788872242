import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Test1 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!token) {
      toast.error("Token not found. Please login again.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/aa/initiate-aa`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "X-admin-auth": "1234567890",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Successfully completed!");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1400);
      } else {
        toast.error("Bank verification failed.");
      }
    } catch (error) {
      toast.error(
        `Error: ${error?.response?.data?.message || "An error occurred"}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-row gap-10">
        <button
          onClick={handleSubmit}
          disabled={isLoading}
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
            isLoading ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {isLoading ? "Processing..." : "Click"}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Test1;
