import React from "react";
import FailureGif from "../core/images/failure.gif"; // Assuming you have a failure gif
import { Link } from "react-router-dom";

const Failure = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="text-center">
        <img
          src={FailureGif}
          alt="Failure"
          className="mx-auto max-w-full mb-6"
        />
        <h2 className="text-2xl font-bold mb-2">Payment Failed</h2>
        <p>Oops! Payment failed. Please try again.</p>
        <Link
          to="/"
          className="mt-4 inline-block px-6 py-3 bg-gradient-to-r from-pink-500 to-yellow-500 text-white rounded-md hover:bg-gradient-to-r hover:from-pink-600 hover:to-yellow-600 transition duration-300"
        >
          Go Home
        </Link>
      </div>
    </div>
  );
};

export default Failure;
