import React, { useState } from "react";
import axios from "axios";
import { IoLockClosed } from "react-icons/io5";
import { toast } from "react-toastify";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const AutoPay = ({ isLocked }) => {
  const [iframeSrc, setIframeSrc] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const token = localStorage.getItem("token");

  const handleAutoPayClick = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/upi-autopay/initiate-upi-autopay
`,
        {},
        { headers }
      );
      setIframeSrc(response.data.result.data);
      setIsOpen(true);
      toast.success("AutoPay initiated", { theme: "colored" });
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error("Error initiating AutoPay", { theme: "colored" });
    }
  };

  return isLocked ? (
    <div
      className="transition flex flex-row gap-2 bg-red-500 hover:bg-red-700 text-white justify-center items-center font-semibold py-2 px-4 rounded-xl w-full mt-5"
      disabled
    >
      <div>Auto Pay</div>
      <div>
        <IoLockClosed size={20} />
      </div>
    </div>
  ) : (
    <div>
      <button
        className="transition bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-xl w-full mt-5"
        onClick={handleAutoPayClick}
      >
        Auto Pay <ArrowRightAltIcon />
      </button>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative w-[800px] h-[600px] bg-white rounded-lg shadow-lg">
            <iframe
              src={iframeSrc}
              title="Payment"
              className="w-full h-full rounded-lg"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AutoPay;
