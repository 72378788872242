import React, { useState } from "react";

const DisclaimerModal = ({ showModal, onClose }) => {
  const modalClass = showModal ? "block" : "hidden";

  return (
    <div className={`fixed inset-0 overflow-y-auto ${modalClass}`}>
      <div className="flex items-center justify-center min-h-screen p-4">
        <div className="fixed inset-0 bg-black opacity-50"></div>

        <div className="relative bg-white p-8 max-w-md mx-auto">
          <div className="absolute top-0 right-0 p-4">
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>

          <div className="text-center">
            <h1 className="text-2xl font-bold mb-4">Disclaimer</h1>
            <p>
              {" "}
              Owner for plentycred is a registered money lender with the state
              government of Maharashtra. WE ARE STRICTLY LIMITED ONLY TO NAGPUR
              (URBAN) JURISDICATION FOR ALL OUR BUSINESS ACTIVITIES. We are
              strictly abided to the laws of Maharashtra money lending act 2014
              for all lending activities.
            </p>
          </div>
        </div> 
      </div>
    </div>
  );
};

export default DisclaimerModal;
