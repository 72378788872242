import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { ArrowRightAlt, Done } from "@mui/icons-material";

const Itr1 = () => {
  const [panCardId, setPanCardId] = useState("");
  const [itrOtp, setItrOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleChangeVerify = (e) => {
    setPanCardId(e.target.value);
  };

  const handleChangeOTP = (e) => {
    setItrOtp(e.target.value);
  };

  const handleVerify = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/itr/create-client-id`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("OTP Sent Successfully", { theme: "colored" });
      console.log("Handle Verify Response:", response.data);
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error("Invalid PanCard Details", { theme: "colored" });
    } finally {
      setLoading(false);
    }
  };

  const handleOtp = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/itr/verify-itr-otp`,
        {
          otp: itrOtp,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("PanCard verified successfully", { theme: "colored" });
      console.log("OTP Response:", response.data);
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Incorrect PanCard OTP", { theme: "colored" });
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  return (
    <div className="w-[80%] m-auto pb-10">
      <div className="flex flex-wrap gap-10 flex-col justify-between lg:flex-row">
        <div className="flex-[2]">
          <center />
        </div>
        <div className="flex-[3]">
          <p className="font-semibold text-lg mt-5 text-white">
            Submit Your ITR Details
          </p>
          <span className="text-xs text-white">
            Enter 6 Digit OTP, Check Your SMS!
          </span>
          <br />
          <input
            type="text"
            placeholder="______"
            value={itrOtp}
            onChange={handleChangeOTP}
            maxLength={6}
            className="py-2 px-4 w-full bg-gray-950 focus:bg-gray-900 text-sm border-gray-950 border-[1px] text-white rounded-sm tracking-widest"
          />
          <div className="flex flex-wrap flex-row gap-5">
            <div className="flex-[1]">
              <button
                className="w-full py-2 px-4 text-sm mt-4 bg-blue-500 text-white rounded-md focus:outline-none"
                onClick={handleVerify}
                disabled={loading}
              >
                {loading ? "Loading..." : "Verify OTP"} <ArrowRightAlt />
              </button>
            </div>
            <div className="flex-[1]">
              <button
                className="w-full text-sm py-2 px-4 mt-4 bg-blue-500 text-white rounded-md focus:outline-none"
                onClick={handleOtp}
                disabled={loading}
              >
                {loading ? "Loading..." : "Finish"} <Done />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Itr1;
