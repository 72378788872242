import { CloseOutlined, ExitToApp } from "@mui/icons-material";
import { useState } from "react";
import { IoReorderThreeSharp } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { FaSignOutAlt, FaUserCog } from "react-icons/fa";
import { BsBox2, BsPersonFillExclamation } from "react-icons/bs";

const AdminNavMobile = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="relative">
      <div onClick={toggleDropdown}>
        <motion.div className="text-black">
          {isDropdownOpen ? (
            <div className="p-3">
              <CloseOutlined size={70} className="cursor-pointer" />
            </div>
          ) : (
            <IoReorderThreeSharp size={50} className="cursor-pointer" />
          )}
        </motion.div>
      </div>
      <AnimatePresence >
        <div className="p-3">
        {isDropdownOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="right-0 mt-2 bg-white w-64  p-5  flex flex-col shadow-lg z-10"
          >
            <ul className="flex flex-col gap-6">
              <li className="  cursor-pointer">
                <NavLink
                  to="/admin"
                  activeClassName="bg-gray-300"
                  className="flex items-center px-4 transition text-black  p-[6px] rounded-lg "
                >
                  <BsBox2 className="mr-2" />
                  Dashboard
                </NavLink>
              </li>
              <li className="  cursor-pointer">
                <NavLink
                  to="/admin/profiles"
                  activeClassName="bg-gray-300"
                  className="flex items-center px-4 transition text-black  p-[6px] rounded-lg "
                >
                  <FaUserCog className="mr-2" />
                  Users
                </NavLink>
              </li>
              <li className="  cursor-pointer">
                <NavLink
                  to="/"
                  activeClassName="bg-gray-300"
                  className="flex items-center px-4 transition text-black  p-[6px] rounded-lg "
                >
                  <FaSignOutAlt className="mr-2" />
                  Exit
                </NavLink>
              </li>
              {/* Add more menu items as needed */}
            </ul>
          </motion.div>
        )}
        </div>
      </AnimatePresence>
    </div>
  );
};

export default AdminNavMobile;
