import React from "react";
import Styles from "../../core/css/animation.module.css";
import { NavLink } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Home2 = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const duration = 1; // Customize the duration as needed

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 100 },
  };
  return (
    <motion.div className="font-sans flex flex-col items-center justify-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-12 place-content-center items-center lg:gap-8">
          <motion.div
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={variants}
            transition={{ duration: duration }}
            className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center"
          >
            <div>
              <NavLink
                className="inline-flex px-1 py-1 gap-x-2 rounded-xl border border-gray-400 border-2 hover:border-blue-500 items-center text-sm font-semibold text-gray-600 space-x-1"
                to={"/"}
              >
                <span className="bg-blue-100 flex items-center justify-center gap-2 text-blue-800 text-sm font-semibold px-2.5 py-0.5 rounded-lg dark:bg-blue-900 dark:text-blue-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="h-6 w-6 text-blue-400"
                  >
                    <path d="m3 11 18-5v12L3 14v-3z"></path>
                    <path d="M11.6 16.8a3 3 0 1 1-5.8-1.6"></path>
                  </svg>
                  New
                </span>
                <span>Some important improvements</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="h-4 w-4"
                >
                  <path d="M5 12h14"></path>
                  <path d="m12 5 7 7-7 7"></path>
                </svg>
              </NavLink>
              <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-gray-900 sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                <p className="sm:block">We take pride in being</p>
                <span className="text-zinc-500 md:block">a pioneer</span>
                <p className="text-blue-600 md:block">
                  in the micro-credit industry
                </p>
              </h1>
              <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                <NavLink to={"/signup"}>
                  <button className="inline-flex items-center text-white bg-blue-500 justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 w-full sm:w-auto">
                    Get started now
                  </button>
                </NavLink>
                <NavLink to={"/login"}>
                  <button className="inline-flex hover:ring-gray-400 hover:bg-gray-100 items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 mt-3 w-full sm:mt-0 sm:ml-3 sm:w-auto">
                    Login
                  </button>
                </NavLink>
              </div>
            </div>
          </motion.div>
          <motion.div
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={variants}
            transition={{ duration: duration }}
            className="mt-16 
             sm:mt-24 lg:mt-0 lg:col-span-5"
          >
            <motion.div className="shadow-2xl p-4  text-white  border-solid border-b-4 border--600   bg-black rounded-lg  font-semibold">
              Plenty cred stands out for its speed, affordability and
              user-friendliness, surpassing other financial institutions and
              offering the best service.
            </motion.div>
            <motion.div className="shadow-2xl p-4 mt-8 text-white flex items-center justify-center border-blue-600  bg-[#080f4a] border-solid border-b-4 rounded-lg  font-semibold">
              We are the 1st organization providing on-the-go one click transfer
              of microcredits starting from INR 100 and ranging upto INR 10,000,
              simply using the whatsapp chat.
            </motion.div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default Home2;
