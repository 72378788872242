import React from "react";
import Faq from "react-faq-component";
import fstyle from "../core/css/tandc.module.css";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import FAQImg from "../core/images/faq.png";

const data = {
  // title: "FAQ (How it works)",
  rows: [
    {
      title: "How can I reach customer support?",
      content: `For any assistance or grievances, please reach us at (Email id) or through the WhatsApp chat (for existing users) anytime 24x7. You can also contact us at (contact number) during office hours (9am - 6pm)`,
    },
    {
      title: "How can I apply for credit?",
      content:
        "You can check your eligibility and register on the website. Post which you can apply for credit through single click on whatsapp!",
    },
    {
      title: "How much interest will be charged?",
      content: `We charge a nominal 1.8% simple interest rate per month (other charges are applicable as per T&C)`,
    },
    {
      title: "Where will PlentyCred transfer my credit amount?",
      content: `It will be transferred directly into your bank account`,
    },

    {
      title: "How much credit can I take?",
      content: `You can take microcredits starting as low as INR 100 and upto INR 10,000`,
    },
    {
      title: "Am I eligible for a credit with PlentyCred?",
      content: `For taking a credit with us you should be a 18-60 years old Nagpur resident, with a minimum salary of INR 12,000 and minimum CIBIL score of 650`,
    },
    {
      title: "Which platforms can be used to apply for credit?",
      content: `The credit functionality can be availed through WhatsApp on Android & iOS devices`,
    },
    {
      title: "Do I have to provide any security, collateral or guarantors?",
      content: `No, our application process doesn't require you to provide any of these`,
    },
    {
      title: "I've recently started a new job. Can I still get a credit?",
      content: `Certainly, you can secure a credit after beginning a new job. Your credit will be approved if you meet our lender's eligibility criteria`,
    },
    {
      title: "Can I choose the credit repayment tenure?",
      content: `Yes, you can select a credit tenure that suits your needs`,
    },
    {
      title: "What is EMI?",
      content: `EMI refers to Equated Monthly Instalment. It simply refers to a fixed amount paid by you (borrower) to lender at a specific date every month. EMI enables you to pay a portion of your total outstanding loan interest every month so that by end of the loan tenure your due interest is fully repaid.`,
    },
    {
      title:
        "What happens if I don't pay back or miss/skip EMI repayments on the credit?",
      content: (
        <ol>
          We strongly urge customers to avoid missing their repayments.
          <li>
            <br /> 1. We will remind you through multiple channels to make your
            repayment immediately if you have missed. A legal recourse may also
            be taken.
          </li>
          <li>
            <br />
            2. You will be charged additional interest for Non - payment, which
            will increase your repayment balance.Till you pay the additional
            balance, your loan will not be considered closed, and will lower
            your Credit Score.
          </li>
          <li>
            <br />
            3. We may not be able to provide additional loan offers in future.
          </li>
          <li>
            <br />
            4. Credit Score such as CIBIL, Experian will drastically reduce
            which will restrict any bank or organization from giving you a
            Credit Card or any loans(such as 2- Wheeler or House loans) to you
            in future.
          </li>
        </ol>
      ),
    },
    {
      title: "What is a CIBIL/credit score?",
      content: `A credit score is a number that reflects your creditworthiness and repayment capability. It helps lenders assess your repayment capability when considering credits/loans`,
    },
    {
      title: "Why do you need my bank account details?",
      content: `We need your bank account details to transfer the credit amount and to set up mandate/auto pay so that you do not have to pay the EMI manually. It will be auto debited from your bank account every month automatically`,
    },
    {
      title: "I am not able to add my bank account. Why?",
      content: (
        <p>
          Please check for the following -
          <br />
          1) Ensure you're the account holder
          <br />
          2) Verify correct account number and IFSC
          <br />
          3) Confirm if bank supports eNACH mandate facility
        </p>
      ),
    },
    {
      title:
        "I have entered the details for Mandate setup but bank server is not responding. What to do now?",
      content: `Please try after some time or try with a different bank account.`,
    },
    {
      title: "How long does it take for credit to be disbursed?",
      content: `Once your profile is approved (in 24 hours), you are just one click away on whatsapp to avail credit in real time`,
    },
    {
      title:
        "I applied for credit, but I didn't receive the amount immediately. Why?",
      content: `Please reach out to customer support if your credit is not disbursed to your bank account, and we will help you resolve the issue at the earliest.`,
    },
    {
      title: "Can I apply for two or more credits simultaneously?",
      content: `You can only avail one credit at a time.`,
    },
    {
      title: "Can I change my credit terms?",
      content: `Once a credit is approved and disbursed, the credit terms such as amount and repayment date cannot be changed.`,
    },
    {
      title: "Can I pay through cash?",
      content: `As of now, we support online modes of repayment only.`,
    },
    {
      title:
        "Is there a way in which I can make a partial payment on the due date, and pay the remaining amount later?",
      content: `No. We offer loans at minimal interest rates, ensuring affordable repayment, and we only charge the interest amounts on EMI dues, so that needs to be paid timely. You have the flexibility to settle the entire principal amount anytime before the loan's ultimate deadline`,
    },
    {
      title: "Can I reschedule or postpone my EMI date?",
      content: `No. PlentyCred does not provide any option to reschedule or postpone your EMI date`,
    },
    {
      title: "Can I pay before the EMI date?",
      content: `Yes, you can make advance payments`,
    },
    {
      title: "Can I get amount disbursed to someone else's account?",
      content: `No, you can only get the credit amount disbursed to your registered personal bank account`,
    },
    {
      title:
        "Why choose a credit through PlentyCred instead of a loan directly from a bank?",
      content: `Personal Loan from a bank may involve extensive documentation and multiple branch visits. PlentyCred's 100% digital & transparent process takes just around 5 minutes based on your details. The amount is directly deposited into your account, immediate usability and convenience.`,
    },
    // {
    //     title: "Where will InstaCred transfer my credit amount?",
    //     content: `It will be transferred directly into your bank account`,
    // },
  ],
};

const data1 = {
  // title: "FAQ (How it works)",
  rows: [
    {
      title: "Why do I need to share my PAN card details?",
      content: `Your PAN card details are needed for identification and verification of financial history.`,
    },
    {
      title:
        "What happens if my savings account doesn't have the minimum balance due at the time of due date?",
      content:
        "One should always keep enough balance on due date for payment. Low balance may lead to bounce charges. It can also attract penalties and negatively impact your credit score, which will restrict any bank or organization from giving you a Credit Card or any loans (such as 2-Wheeler or House loans) to you in future.",
    },
    {
      title:
        "Money got deducted from bank but EMI is still due. What should I do?",
      content: `Please reach out to our support team immediately, and we will resolve the issue within 24 hours`,
    },
    {
      title: "I am stuck at bank details page. Why?",
      content: `Please make sure that KYC name and bank name match. You can also try again after some time and If you are still unable to move ahead, you can connect with us to get resolution.`,
    },
    {
      title:
        "I have cancelled/ foreclosed my credit but my mandate is still active. Will my money be deducted?",
      content: `No, once your credit is closed/ cancelled, money will not be deducted from your account. You can cancel your mandate directly from your bank.`,
    },
    {
      title: "I don't own a smartphone. Can I apply for microcredit?",
      content: `No. Currently, You can only apply for microcredit if you have a smartphone.`,
    },
    {
      title: "Can the credits increase my CIBIL score?",
      content: `Yes. A credit can help you enhance your CIBIL score. This strategy can be helpful when you are planning to avail a bigger loan amount or have a limited credit history.`,
    },
    {
      title: "Can I apply for credit on someone else's behalf?",
      content: `No, you cannot apply for credit on someone else's behalf as per our policies.`,
    },
    {
      title:
        "I received a call from PlentyCred asking for personal/KYC information or OTP. Should I share?",
      content: `No. In any situation, PlentyCred never calls asking for any information. In any such incident please immediately raise a ticket or reach out to us to report this fraud.`,
    },
    {
      title:
        "If I do not possess Aadhar/PAN Card and have never applied for the same, can I still get credit through PlentyCred?",
      content: `Unfortunately, we need to validate your Aadhar Card/PAN number to process the application further. You are required to hold a valid Aadhar/PAN card number in order to complete our credit application process.`,
    },
    {
      title: "Do we also have investment feature?",
      content: `Yes, you can invest with us to earn with higher interest rates!`,
    },
    {
      title: "What is the minimum amount I can invest?",
      content: `You can invest starting as low as INR 50,000!`,
    },
    {
      title: "What return/ROI can I expect on my investment?",
      content: `You can get a staggering return starting from 12% p.a. and ranging upto 60% p.a., depending upon the invested amount and the tenure.`,
    },
    {
      title: "Will my money be secure if I invest with you?",
      content: `Yes, be assured PlentyCred uses both equity and debt options, making your investment secure.`,
    },
    {
      title: "IWhat is the minimum block-in period for my investment",
      content: `Your money shall be invested with us for at least 6 months.`,
    },
    {
      title: "How will I know where is my money invested?",
      content: `You will receive monthly dashboard reports of your investments.`,
    },
    {
      title: "How is my credit limit decided?",
      content: `It depends on multiple factors such as social media footprint, KYC verification, CIBIL score and other financial parameters.`,
    },
    {
      title:
        "Why is the website asking for my location, when I am registering myself?",
      content: `To prevent any fraudulent activities, we capture a real time location of your device before approving your profile. We advise you to allow location to the website as this is a mandatory step.`,
    },
    {
      title:
        "Will there be a credit report enquiry when I apply for the credit?",
      content: `No, we do not report enquiry for any loan or microcredit requests. You can rest assured and apply multiple times for any credit service.`,
    },
    {
      title: "What are the fees? Why should I pay them? Are there hidden fees?",
      content: (
        <p>
          Standard charges that are applicable for any credit are as follows:
          <br />
          1. Processing Fee + Convenience charges + KYC verification fee
          <br />
          2. Late Payment Fee - Only in cases of delayed EMI payments
          <br />
          3. Bounce Charges - Only in cases of EMI instalment auto-debit bounce
          from linked Bank Account All the Charges and Fees are shown clearly in
          the Loan Agreement
        </p>
      ),
    },
    {
      title: "Are credit interest rates fixed or floating?",
      content: `We charge credits at fixed rate of interest rate. Your interest amount therefore remains fixed every month during the credit tenure. However, we do not collect any principal amount in the EMIs, only the interest amount. You can choose to foreclose the credit with the principal amount anytime before the last due date.`,
    },
    {
      title: "Why is mandate amount higher than my EMI amount?",
      content: `Please be assured that PlentyCred will never charge more than due amount. The amount mentioned in the NASH mandate is applicable for multiple credits in future, and you would not have to apply for the mandate each time.`,
    },
    {
      title: "Why is mandate expiry longer than my credit tenure?",
      content: `This is to ensure we can reuse the mandate for best offers in future. Please be assured PlentyCred will never charge more than due amount.`,
    },
    {
      title:
        "How can I get my credit agreement/ repayment schedule/ interest certificates/statement of accounts/NOC?",
      content: `You will receive all credit-related documents through email. Or you can also access them through our website, by logging into your profile/account.`,
    },
    {
      title:
        "Do I need to verify my profile again and again while taking a credit everytime?",
      content: `No, you do not need to verify your profile again. Once your profile is approved, you become our user for lifetime.`,
    },
  ],
};

const styles = {
  // bgColor: 'white',
  titleTextColor: "#06BCF1",
  rowTitleColor: "#06BCF1",
  rowContentColor: "grey",
  rowContentPaddingBottom: "10px",
  // arrowColor: "red",
};

const config = {
  animate: true,
  // arrowIcon: "V",
  tabFocus: true,
};

const FaQ = () => {
  return (
    <>
      <div className={fstyle.container} style={{ paddingBottom: "5em" }}>
        <div className=" rounded-[20px] bg-[rgb(220,255,245)] bg-[linear-gradient(90deg,_rgba(220,255,245,1)_0%,_rgba(255,255,255,1)_24%,_rgba(255,254,189,1)_48%,_rgba(255,232,172,1)_73%,_rgba(255,188,188,1)_100%)] p-10 flex flex-wrap justify-end flex-col gap-10 lg:flex-row items-center">
          <div className="flex-[1]">
            <h1 className="text-4xl font-semibold">
              Frequently Asked Questions
            </h1>
            <p className="mt-5 text-sm lg:text-2xl">
              Here are few of the most frequently asked questions by our
              valueable customers
            </p>
            <br />
            <div to="/" className="font-semibold block mt-10">
              Find Out More <FontAwesomeIcon icon={faArrowDown} />
            </div>
          </div>
          <div className="flex-[1]">
            <img src={FAQImg} alt="educateFinance" className="m-auto" />
          </div>
        </div>
        {/* <div className="p-6 flex flex-col gap-2">
          <h2 className="font-semibold  lg:text-4xl text-3xl lg:leading-9 md:leading-7 leading-9 text-gray-800">
            Frequently Asked Questions
          </h2>
          <p className="font-normal dark:text-gray-400 text-base leading-6 text-gray-600 lg:w-8/12 md:w-9/12">
            Here are few of the most frequently asked questions by our valueable
            customers
          </p>
        </div> */}
        <div
          id="wrapper"
          className="flex gap-12 flex-col lg:flex-row items-center justify-center p-3"
        >
          <div className={fstyle.flex}>
            <Faq data={data} styles={styles} config={config} />
          </div>
          <div className={fstyle.flex}>
            <Faq data={data1} styles={styles} config={config} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FaQ;
