import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import axios from "axios";
import ED from "../core/images/ED.gif";
import EmployDetailsPhoto from "./EmployDetailsPhoto";

const EmployDetails = () => {
  const [verifying, setVerifying] = useState(false);
  const [data, setData] = useState(null);
  const [ed, setEd] = useState({
    companyName: "",
    corporateEmail: "",
    netSalary: "",
    designation: "",
    idCardPicLivePath: "willBeAdded",
    uanNo: "",
  });

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/v1/ed/get-employ`,
        {},
        config
      )
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching employ details:", error);
      });
  }, [config]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEd((prevData) => ({
      ...prevData,
      [name]:
        name === "netSalary"
          ? parseInt(value, 10)
          : name === "uanNo"
          ? value.replace(/\D/g, "")
          : value,
    }));
  };

  const handleNetSalaryChange = (e) => {
    const value = e.target.value;
    handleChange(e);

    if (value && parseInt(value, 10) < 13000) {
      setEd((prevData) => ({
        ...prevData,
        netSalary: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !ed.companyName ||
      !ed.corporateEmail ||
      !ed.netSalary ||
      !ed.designation ||
      !ed.uanNo
    ) {
      toast.error("All fields are required", { theme: "colored" });
      return;
    }

    try {
      setVerifying(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/ed/add-employ`,
        ed,
        config
      );
      toast.success("Successfully Submitted", { theme: "colored" });
      window.location.reload();
    } catch (error) {
      toast.error(
        `Error: ${error.response?.data?.message || "Submission failed"}`,
        { theme: "colored" }
      );
    } finally {
      setVerifying(false);
    }
  };

  return (
    <div className="p-10 w-full">
      <ToastContainer />
      <div className="m-auto max-w-[1000px] mt-[50px]">
        <h1 className="font-semibold text-5xl text-white">Employ Details</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="max-w-[1000px] flex flex-col gap-5 mb-6 m-auto mt-10">
          {data ? (
            <div className="bg-white p-10 rounded-lg shadow-lg">
              <table className="min-w-full bg-white border border-gray-300">
                <tbody>
                  {data.companyName && (
                    <tr className="border-b border-gray-300">
                      <td className="py-2 px-4 font-semibold">Company Name:</td>
                      <td className="py-2 px-4">{data.companyName}</td>
                    </tr>
                  )}
                  {data.corporateEmail && (
                    <tr className="border-b border-gray-300">
                      <td className="py-2 px-4 font-semibold">
                        Corporate Email:
                      </td>
                      <td className="py-2 px-4">{data.corporateEmail}</td>
                    </tr>
                  )}
                  {data.designation && (
                    <tr className="border-b border-gray-300">
                      <td className="py-2 px-4 font-semibold">Designation:</td>
                      <td className="py-2 px-4">{data.designation}</td>
                    </tr>
                  )}
                  {data.netSalary && (
                    <tr className="border-b border-gray-300">
                      <td className="py-2 px-4 font-semibold">Net Salary:</td>
                      <td className="py-2 px-4">{data.netSalary}</td>
                    </tr>
                  )}
                  {data.uanNo && (
                    <tr className="border-b border-gray-300">
                      <td className="py-2 px-4 font-semibold">UAN Number:</td>
                      <td className="py-2 px-4">{data.uanNo}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="p-10 flex flex-col items-center rounded-lg shadow-lg bg-gray-100">
              <div className="flex flex-col xl:flex-row items-center">
                <div className="flex flex-col gap-7">
                  <div className="flex flex-col lg:flex-row gap-6">
                    <div>
                      <label htmlFor="companyName" className="text-gray-700">
                        Company Name:
                      </label>
                      <input
                        value={ed.companyName}
                        onChange={handleChange}
                        name="companyName"
                        type="text"
                        maxLength={20}
                        className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 bg-white mt-2 leading-tight focus:outline-none focus:shadow-outline"
                        id="companyName"
                      />
                    </div>
                    <div>
                      <label htmlFor="corporateEmail" className="text-gray-700">
                        Corporate Email:
                      </label>
                      <input
                        value={ed.corporateEmail}
                        onChange={handleChange}
                        name="corporateEmail"
                        type="email"
                        maxLength={40}
                        id="corporateEmail"
                        className="shadow-lg appearance-none border rounded w-full py-4 px-3 text-gray-700 bg-white mt-2 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row gap-6">
                    <div>
                      <label htmlFor="designation" className="text-gray-700">
                        Designation:
                      </label>
                      <input
                        value={ed.designation}
                        onChange={handleChange}
                        name="designation"
                        maxLength={30}
                        type="text"
                        id="designation"
                        className="shadow-lg appearance-none border rounded w-full py-4 px-3 text-gray-700 bg-white mt-2 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div>
                      <label htmlFor="uanNo" className="text-gray-700">
                        UAN Number:
                      </label>
                      <input
                        value={ed.uanNo}
                        onChange={handleChange}
                        name="uanNo"
                        type="text"
                        maxLength={20}
                        className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 bg-white mt-2 leading-tight focus:outline-none focus:shadow-outline"
                        id="uanNo"
                      />
                    </div>
                    <div>
                      <label htmlFor="netSalary" className="text-gray-700">
                        Net Salary:
                      </label>
                      <input
                        name="netSalary"
                        type="number"
                        id="netSalary"
                        className="shadow-lg appearance-none border rounded w-full py-4 px-3 text-gray-700 bg-white mt-2 leading-tight focus:outline-none focus:shadow-outline"
                        onChange={handleNetSalaryChange}
                        value={ed.netSalary}
                        min="13000"
                        placeholder="Enter net salary (min 13,000)"
                      />
                      {ed.netSalary && ed.netSalary < 13000 && (
                        <p className="text-red-500 text-sm mt-2">
                          Net salary must be at least 13,000.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row gap-6">
                    <div>
                      <Button variant="contained" type="Submit">
                        {verifying ? "loading" : "Verify"}
                      </Button>
                    </div>
                  </div>
                </div>
                <div>
                  <img src={ED} width={400} height={300} alt="Employ Details" />
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
      <div className="m-auto max-w-[1000px] mt-10">
        <EmployDetailsPhoto />
      </div>
      <div
        className="fixed w-full h-full top-0 left-0 z-[-10]"
        id="DASHbackground"
      ></div>
    </div>
  );
};

export default EmployDetails;
