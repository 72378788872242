import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';


export default function InvestingTable() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div className="mt-12 mb-10 flex flex-col xl:flex-row justify-center gap-5 overflow-x-auto">
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: -100 },
        }}
        transition={{ duration: 1.3 }}
        className="xl:p-0 p-4 table-fixed w-full border-collapse border rounded-lg shadow-lg"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="w-1/4 px-4 py-2 font-semibold border text-center whitespace-no-wrap">Feature</TableCell>
              <TableCell className="w-1/6 px-4 py-2 font-semibold border text-center whitespace-no-wrap">Plentycred</TableCell>
              <TableCell className="w-1/6 px-4 py-2 font-semibold border text-center whitespace-no-wrap">Savings Account</TableCell>
              <TableCell className="w-1/6 px-4 py-2 font-semibold border text-center whitespace-no-wrap">Fixed Deposits</TableCell>
              <TableCell className="w-1/6 px-4 py-2 font-semibold border text-center whitespace-no-wrap">Mutual Funds</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="px-4 py-2 font-semibold border text-center whitespace-no-wrap">Returns/Interest rate</TableCell>
              <TableCell className="px-4 py-2 border text-center whitespace-no-wrap">Upto 60% (based on amount and tenure)</TableCell>
              <TableCell className="px-4 py-2 border text-center whitespace-no-wrap">Quite low interest rates (around 4-6%)</TableCell>
              <TableCell className="px-4 py-2 border text-center whitespace-no-wrap">Fixed interest rates (around 6-8%)</TableCell>
              <TableCell className="px-4 py-2 border text-center whitespace-no-wrap">Variable returns based on market performance</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="px-4 py-2 font-semibold border text-center whitespace-no-wrap">Risk Level</TableCell>
              <TableCell className="px-4 py-2 border text-center whitespace-no-wrap">0 risk</TableCell>
              <TableCell className="px-4 py-2 border text-center whitespace-no-wrap">Low risk; Inflation risk</TableCell>
              <TableCell className="px-4 py-2 border text-center whitespace-no-wrap">Low to moderate risk; Liquidity risk</TableCell>
              <TableCell className="px-4 py-2 border text-center whitespace-no-wrap">Moderate to high risk; Market risks</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="px-4 py-2 font-semibold border text-center whitespace-no-wrap">Interest Payout Frequency</TableCell>
              <TableCell className="px-4 py-2 border text-center whitespace-no-wrap">Monthly</TableCell>
              <TableCell className="px-4 py-2 border text-center whitespace-no-wrap">Quarterly or Annual</TableCell>
              <TableCell className="px-4 py-2 border text-center whitespace-no-wrap">Quarterly or at maturity</TableCell>
              <TableCell className="px-4 py-2 border text-center whitespace-no-wrap">Varies (dividends, capital gains)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="px-4 py-2 font-semibold border text-center whitespace-no-wrap">Transparency</TableCell>
              <TableCell className="px-4 py-2 border text-center whitespace-no-wrap">100% transparency and monthly dashboard reports</TableCell>
              <TableCell className="px-4 py-2 border text-center whitespace-no-wrap">Limited</TableCell>
              <TableCell className="px-4 py-2 border text-center whitespace-no-wrap">Limited</TableCell>
              <TableCell className="px-4 py-2 border text-center whitespace-no-wrap">Moderate (Transparent only if managed personally)</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </motion.div>
    </div>
  );
}
