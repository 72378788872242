import React, { useEffect, useState } from "react";

const AdminEDPhoto = ({ id }) => {
  const [imageUrl, setImageUrl] = useState("");

  // useEffect(() => {
  //   const getImageUrl = async () => {
  //     try {
  //       // Get a reference to the image
  //       const imageRef = firebase.storage().ref(`${id}`); // Use dynamic ID

  //       // Get the download URL
  //       const url = await imageRef.getDownloadURL();
  //       setImageUrl(url);
  //     } catch (error) {
  //       console.error("Error getting image URL from Firebase Storage:", error);
  //     }
  //   };

  //   getImageUrl(); // Call the function to fetch the image URL
  // }, [id]); // Include id in the dependency array to re-run effect when id changes

  return (
    <div className="p-4">
      Image ED Photo firebase need to be added
      {/* {imageUrl ? <img src={imageUrl} alt="Your Image" /> : <p>Image loading...</p>} */}
    </div>
  );
};

export default AdminEDPhoto;
