import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CourtCase = () => {
  const [formData, setFormData] = useState({
    address: "",
    fatherName: ""
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    if (!token) {
      toast.error("Token not found. Please login again.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v2/courtCase/get-courtcase`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.status === 200) {
        const apiResponse = await axios
          .post(
            `${process.env.REACT_APP_API_KEY}/v1/aa/initiate-aa`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          )
          .catch(() => {
            toast.error("Bank verification failed.");
          });

        toast.success("Successfully completed!");
        window.location.href = apiResponse.data.url;
      } else {
        toast.error("Failed to process court case.");
      }
    } catch (error) {
      toast.error(
        `Error: ${error.response?.data?.message || "An error occurred"}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="p-10">
        <div className="max-w-[600px] mt-[80px]"></div>
        {/* Section Area */}
        <div className="max-w-[600px] m-auto mt-2 mb-32">
          <h1 className="font-semibold text-5xl text-white">
            Legal Check & Account Aggregator
          </h1>
          <br />
          <div className="p-10 text-[#222] bg-white flex flex-col rounded-lg shadow-lg">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block mb-2">Address</label>
                <textarea
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="resize-none w-full p-2 border rounded"
                  required
                />
              </div>
              <div>
                <label className="block mb-2">Father's Name</label>
                <input
                  type="text"
                  name="fatherName"
                  value={formData.fatherName}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <button
                type="submit"
                className="bg-blue-500 w-full text-white py-2 px-4 rounded hover:bg-blue-600"
              >
                Verify
              </button>
            </form>
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <p className="text-lg font-semibold">Please wait...</p>
          </div>
        </div>
      )}

      <div
        className="fixed w-full h-full top-0 left-0 z-[-10]"
        id="DASHbackground"
      ></div>
    </>
  );
};

export default CourtCase;
