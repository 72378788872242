import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AdminPage from "./AdminMainPage/AdminPage";
import Disclaimer from "../../extras/Disclaimer";
import Error from "../Error";

const Admin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(null);
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!username || !password) {
      alert("Both fields are required");
      return;
    }

    setLoading(true);
    const id = username;

    const headers = {
      Authorization: `Bearer ${token}`,
      "X-admin-auth": "1234567890",
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/admin/admin`,
        { id, password },
        { headers }
      );
      const isAdmin = response.data.isAdmin;

      if (isAdmin) {
        window.location.reload();
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/v2/contracting/verify-all`,
        {},
        { headers }
      )
      .then((response) => {
        setShow(response.data.VerifyAllData);
      })
      .catch((error) => {
        console.error("Error sending POST request:", error);
      });
  }, [token]);

  if (!show?.isAdmin) {
    return <Error />;
  }

  return (
    <div>
      <AdminPage />
    </div>
  );
};

export default Admin;
