import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import regStyle from "../core/css/register.module.css";
import Header from "../components/Header";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import logoIC from "../core/images/logoPC.png";
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { Player } from "@lottiefiles/react-lottie-player";
import Footer from "../components/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import DisclaimerModal from "../extras/DisclaimerModel";

function generateRandomString() {
  const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
  const numbers = "0123456789";
  const specialCharacters = "!@#$%^&*()_-+=<>?";

  const getRandomChar = (characters) =>
    characters[Math.floor(Math.random() * characters.length)];

  // Ensure at least one character from each set
  const randomUppercase = getRandomChar(uppercaseLetters);
  const randomLowercase = getRandomChar(lowercaseLetters);
  const randomNumber = getRandomChar(numbers);
  const randomSpecialChar = getRandomChar(specialCharacters);

  // Generate the remaining characters
  let remainingChars = "";
  for (let i = 0; i < 8; i++) {
    const charSet =
      i % 4 === 0
        ? uppercaseLetters
        : i % 4 === 1
        ? lowercaseLetters
        : i % 4 === 2
        ? numbers
        : specialCharacters;
    remainingChars += getRandomChar(charSet);
  }

  // Concatenate all parts and shuffle the string
  const randomString =
    randomUppercase +
    randomLowercase +
    randomNumber +
    randomSpecialChar +
    remainingChars;

  const shuffledString = randomString
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");

  return shuffledString;
}

const RegisterApp = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const generatedString = generateRandomString();
  console.log("randomID>>>> ", generatedString);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [data, setData] = useState({
    email: "",
    mobileNumber: "",
    dob: "",
    gender: "m",
    panCardNo: "KKKKK0000K",
    userSecret: generatedString,
    ip_address: "12.312.351.443",
    device: "mobile",
  });
  console.log("here is data", data);

  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token"));

  const navigate = useNavigate();

  useEffect(() => {
    const handleTokenChange = () => {
      setIsLoggedIn(!!localStorage.getItem("token"));
    };
    window.addEventListener("storage", handleTokenChange);
    return () => {
      window.removeEventListener("storage", handleTokenChange);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  }, [isLoggedIn]);

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "middleName") {
      setMiddleName(value);
    } else if (name === "lastName") {
      setLastName(value);
    } else {
      setData({ ...data, [name]: value });
    }
  };

  //Disclaimer model
  const [showModal, setShowModal] = useState(false);
  const handleCheckboxChange = (e) => {
    setShowModal(e.target.checked);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChangeMobile = (e) => {
    const inputValue = e.target.value;

    // Use regex to keep only digits
    const digitsOnly = inputValue.replace(/\D/g, "");

    // Check if the remaining value has exactly 10 digits
    if (/^\d{10}$/.test(digitsOnly)) {
      // If it's a 10-digit number, update the state with the formatted number
      const formattedNumber = `91${digitsOnly}`;
      // Update your state or do anything else with the formatted number
      // For example, if you are using React state:
      // setData({ ...data, mobileNumber: formattedNumber });
    }
    // If the input doesn't have 10 digits, you may want to handle it accordingly
  };

  const onChangeCap = () => {};

  // console.log("firstname", firstName);
  // console.log("lastname", lastName);
  // console.log("middlename", middleName);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const fullName = `${firstName} ${middleName}  ${lastName}`;
    console.log(fullName);

    if (password === confirmPassword) {
      // Passwords match, perform further actions here
      console.log("Passwords match!");
      setPasswordsMatch(true);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v1/auth/register`,
          {
            fullName: fullName,
            email: data.email,
            mobileNumber: data.mobileNumber,
            dob: data.dob,
            gender: data.gender,
            panCardNo: data.panCardNo,
            userSecret: data.userSecret,
            password: data.password,
            ip_address: data.ip_address,
            device: data.device,
          }
        );
        console.log("Registration response:", response.data);
        setData({
          email: "",
          mobileNumber: "",
          dob: "",
          gender: "",
          panCardNo: "",
          userSecret: "",
          password: "",
          ip_address: "",
          device: "",
        });
        setFirstName("");
        setMiddleName("");
        setLastName("");
        toast.success("OTP Sent Successfully", { theme: "colored" });
      } catch (error) {
        console.error("Error Registrating User", error);
        toast.error(`Error:${error.response.data.message}`);
      }
    } else {
      console.log("Passwords do not match!");
      setPasswordsMatch(false);
      toast.error(`Error: Passwords do not match`);
    }
  };

  return (
    <div id="rtlanimation">
      <Header />
      <br />
      <br />
      <br /> <br />
      <div className="xl:hidden">
        <br />
        <br />
        <br />
      </div>
      <div
        className="
        justify-center relative min-h-screen  xl:bg-transparent
      flex flex-col xl:flex-row items-center lg:min-h-screen"
      >
        <div>
          <ToastContainer />
          <section className="bg-transparent flex flex-row ">
            <div className="flex flex-col  items-center justify-center md:px-6 md:py-4  lg:mx-auto md:h-screen lg:py-0">
              {/* <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img className="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo">
      </img> 
      </a> */}
              <div
                className="w-full  shadow border md:mt-0 max-w-xl xl:p-0"
                id="glassMorph"
              >
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight  md:text-2xl text-white">
                    Register Your Account
                  </h1>
                  <form
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                    className="space-y-4 md:space-y-6"
                  >
                    {/* FirstName and last and middle */}
                    <div className="flex flex-col md:flex-row justify-between gap-5">
                      <div>
                        <label
                          for="firstName"
                          className="block mb-2 text-sm font-medium  text-white"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          value={firstName}
                          onChange={handleChange}
                          className=" border  sm:text-sm rounded-lg  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                          id="glassMorph"
                        />
                      </div>

                      <div>
                        <label
                          for="middleName"
                          className="block mb-2 text-sm font-medium  text-white"
                        >
                          Middle Name
                        </label>
                        <input
                          type="text"
                          name="middleName"
                          placeholder="Middle Name"
                          value={middleName}
                          onChange={handleChange}
                          className=" border  sm:text-sm rounded-lg  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                          id="glassMorph"
                        />
                      </div>
                      <div>
                        <label
                          for="lastName"
                          className="block mb-2 text-sm font-medium  text-white"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={handleChange}
                          className=" border  sm:text-sm rounded-lg  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                          id="glassMorph"
                        />
                      </div>
                    </div>
                    {/* FirstName and last and middle and Mobile Number */}
                    {/* Email and Password */}
                    <div className="flex flex-row w-full  gap-5">
                      <div className="flex-[1]">
                        <label
                          for="email"
                          className="block mb-2 text-sm font-medium  text-white"
                        >
                          Your email
                        </label>
                        <input
                          type="email"
                          name="email"
                          placeholder="someone@something.com"
                          value={data.email}
                          onChange={handleChange}
                          className=" border  sm:text-sm rounded-lg  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                          id="glassMorph"
                        />
                      </div>
                      <div className="flex-[1]">
                        <label
                          for="password"
                          className="block mb-2 text-sm font-medium  text-white"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          placeholder="**************"
                          value={password}
                          onChange={handlePasswordChange}
                          className=" border  sm:text-sm rounded-lg  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                          id="glassMorph"
                        />
                      </div>
                      <div className="hidden lg:block flex-[1]">
                        <label
                          for="confirmPassword"
                          className="block mb-2 text-sm font-medium  text-white"
                        >
                          Comfirm Password
                        </label>
                        <input
                          type="password"
                          name="confirmPassword"
                          placeholder="**************"
                          value={confirmPassword}
                          onChange={handlePasswordChange}
                          className={`border  sm:text-sm rounded-lg  block w-full p-2.5 bg-gray-700 ${
                            !passwordsMatch && "border-red-500"
                          } border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500`}
                          id="glassMorph"
                        />
                      </div>
                    </div>
                    {/* Email and Password */}
                    {/* DOB and Gender and MobileNumber */}
                    <div className="flex  flex-col md:flex-row  justify-evenly gap-5">
                      <div className="flex-[2] lg:hidden">
                        <label
                          for="password"
                          className="block mb-2 text-sm font-medium  text-white"
                        >
                          Comfirm Password
                        </label>
                        <input
                          type="password"
                          name="confirmPassword"
                          placeholder="**************"
                          value={confirmPassword}
                          onChange={handlePasswordChange}
                          className={`border  sm:text-sm rounded-lg  block w-full p-2.5 bg-gray-700 ${
                            !passwordsMatch && "border-red-500"
                          } border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500`}
                          id="glassMorph"
                        />
                      </div>

                      <div className=" lg:hidden">
                        <label
                          for="gender"
                          className="block mb-2 text-sm font-medium  text-white"
                        >
                          Gender
                        </label>
                        <select
                          value={data.gender}
                          onChange={handleChange}
                          placeholder="Choose one…"
                          className="bg-slate-700 text-white border-none shadow-md"
                          id="glassMorph"
                        >
                          <option value="m">Male</option>
                          <option value="f">Female</option>
                        </select>
                      </div>
                      <div className="flex-[2]">
                        <label
                          htmlFor="mobileNumber"
                          className="block mb-2 text-sm font-medium text-white"
                        >
                          Mobile Number
                        </label>
                        <div className="flex flex-row gap-2 items-center">
                          <input
                            type="tel"
                            name="mobileNumber"
                            placeholder="ex : 7712771277"
                            value={data.mobileNumber}
                            onChange={handleChange}
                            className="border rounded-lg w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                            id="glassMorph"
                          />
                        </div>
                      </div>
                      <div className="hidden lg:block flex-[1]">
                        <label
                          for="gender"
                          className="block mb-2 text-sm font-medium  text-white"
                        >
                          Gender
                        </label>
                        <select
                          value={data.gender}
                          onChange={handleChange}
                          placeholder="Choose one…"
                          className="bg-slate-700 text-white border-none shadow-md"
                          id="glassMorph"
                        >
                          <option value="m">Male</option>
                          <option value="f">Female</option>
                        </select>
                      </div>
                      <div>
                        <label
                          for="dob"
                          className="block mb-2 text-sm font-medium  text-white"
                        >
                          DOB
                        </label>
                        <input
                          type="date"
                          name="dob"
                          value={data.dob}
                          onChange={handleChange}
                          className=" border  sm:text-sm rounded-lg  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                          id="glassMorph"
                        />
                      </div>
                    </div>
                    {/* DOB and MobileNumber and terms */}
                    {/* terms */}
                    <div className="flex-col flex gap-3">
                      <div className="ml-3 flex flex-row gap-3 text-sm">
                        <div className="flex items-center h-5">
                          <input
                            id="terms glassMorph"
                            aria-describedby="terms"
                            type="checkbox"
                            onChange={handleCheckboxChange}
                            className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                            required
                          />
                        </div>
                        <label
                          for="terms"
                          className="font-light  text-gray-300"
                        >
                          By continuing, you agree to our
                          <NavLink
                            to="/privacypolicy"
                            className="font-medium text-blue-600  hover:underline text-sm text-primary-500"
                          >
                            {" "}
                            Privacy Policy{" "}
                          </NavLink>
                          and
                          <NavLink
                            to="/termsandconditions"
                            className="font-medium text-blue-600 hover:underline text-sm text-primary-500"
                          >
                            {" "}
                            Terms & Conditions
                          </NavLink>
                          <span>, </span>
                          <NavLink
                            to="/disclaimer"
                            className="font-medium text-blue-600  hover:underline text-sm text-primary-500"
                          >
                            disclaimer{" "}
                          </NavLink>
                          & communicate with you via phone, e-mails, WhatsApp,
                          etc.
                          <div className="flex items-center"></div>
                        </label>
                      </div>
                    </div>

                    {/* terms */}
                    <div className="flex flex-col gap-2">
                      {/* 6LfxfmYpAAAAAC7uT0TIwkbNDWNiaswXEppsEKpo */}
                      <div className="">
                        <ReCAPTCHA
                          sitekey="6LfxfmYpAAAAAC7uT0TIwkbNDWNiaswXEppsEKpo"
                          onChange={onChangeCap}
                        />
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          type="submit"
                          className="w-[100%]"
                        >
                          Create Your Account
                        </Button>
                      </div>
                    </div>
                    <p className="text-sm font-light text-white">
                      Already have an account?{" "}
                      <NavLink
                        to={"/app/login"}
                        className="font-medium text-white hover:underline dark:text-primary-500"
                      >
                        Login here
                      </NavLink>
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <div>
              <div className=" flex items-center justify-center h-screen">
                <div className="">
                  <DisclaimerModal
                    showModal={showModal}
                    onClose={handleCloseModal}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <svg
        className="z-[1]   bottom-[0] left-0 "
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 200"
      >
        <path
          fill="#030712"
          fillOpacity="1"
          d="M0,0L40,42.7C80,85,160,171,240,197.3C320,224,400,192,480,154.7C560,117,640,75,720,74.7C800,75,880,117,960,154.7C1040,192,1120,224,1200,213.3C1280,203,1360,149,1400,122.7L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        ></path>
      </svg>
      <Footer />
    </div>
  );
};

export default RegisterApp;
