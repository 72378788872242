import React, { useEffect, useState } from "react";
import routeStyle from "../../core/css/routes.module.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Player } from "@lottiefiles/react-lottie-player";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import iconette from "../../core/images/icon2.png";
import {
  faWallet,
  faUser,
  faTag,
  faCalendar,
  faMarsAndVenus,
  faUpload,
  faSquareCheck,
  faGauge,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Signzy from "../../components/Signzy";
import CibilScoreView from "../../Dashboard/CibilScoreView";
import UserLivePhoto from "../../components/Props/UserLivePhoto";
import VerifyMobile from "../../components/Props/VerifyMobile";
import ExperianCibil from "../../Dashboard/ExperianCibil";

Modal.setAppElement("#root");

const Profile = () => {
  const [percentage, setPercentage] = useState(0);
  const [imageSrc, setImage] = useState("");
  const [data, setData] = useState("");
  const [userDetails, setUserDetails] = useState(null);

  const [showMobileModal, setShowMobileModal] = useState(false);
  const handleMobileCloseModal = () => {
    setShowMobileModal(false);
  };
  const handleMobileOpen = (e) => {
    setShowMobileModal(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    // console.log("here is tokenProfile", token);
    const decodeToken = jwtDecode(token);
    // console.log("here is decodedtokenprofile", decodeToken);
    const userId = decodeToken.sub;
    console.log("here is userIdprofile", userId);
    // setUserId(userId);
    // eslint-disable-next-line no-undef
    axios
      .get(`${process.env.REACT_APP_API_KEY}/v1/auth/me`, config)
      .then((response) => {
        console.log(response);
        setUserDetails(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const webcamRef = React.useRef(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const BootstrapButton = styled(Button)({
    backgroundColor: "#0076ff",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#025fca",
    },
  });

  // const Verify = () => {
  //   console.log("send data for verification");
  // };

  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    // Handle the captured image (e.g., upload it to a server)
    setImage(imageSrc);
    // console.log('Captured Image:', imageSrc);
    closeModal();
  };

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(`${process.env.REACT_APP_API_KEY}/v1/kyc/get-kyc`, {}, { headers })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.error("Error sending POST request:", error);
      });
  }, []);
  const cibilScoreView = data?.cibilScore;

  console.log("CibilResponse>>>>", cibilScoreView);

  const Verify = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      if (imageSrc) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v1/profile/upload-live-selfie`,
          {
            selfie: imageSrc,
          },
          config
        );

        console.log("Upload successful", response.data);
      } else {
        console.log("No image uploaded");
      }
    } catch (error) {
      console.error("Error uploading selfie", error);
    }
  };

  const DOB = userDetails?.dob;
  let formattedDate = "";
  if (DOB && !isNaN(DOB)) {
    try {
      formattedDate = DOB.split("T")[0];
    } catch (error) {
      console.error("Error formatting date:", error);
    }
  } else {
    console.warn("Invalid or null date:", userDetails?.dob);
  }
  console.log("DOB>>>>", formattedDate);
  console.log("USER DETAILS >>>>>>>>>>", userDetails);

  return (
    <div className="p-10">
      <div className=" max-w-[1000px] mt-[80px]"></div>
      {/* End Of Page Title */}
      {/* Section Area */}
      <div className="max-w-[1000px] m-auto mt-2 mb-32">
        <h1 className="font-semibold text-5xl text-white">Dashboard</h1>
        <br />
        <div className=" p-10 text-[#222] bg-white  flex flex-wrap flex-col items-center rounded-lg shadow-lg mb-2 gap-4 lg:flex-row">
          <div className="flex-[1]">
            <UserLivePhoto />
          </div>
          <div className="flex-[4]">
            <h1 className="text-lg font-semibold text-[#111]">
              {userDetails?.fullName}
            </h1>
            <br />

            <div className="flex gap-2 flex-col lg:flex-row lg:gap-0">
              <div className="flex-[1] flex-row flex items-center">
                <FontAwesomeIcon icon={faMarsAndVenus} className="pr-4" />
                <div>
                  {userDetails?.gender === "m" ? (
                    <p>Male</p>
                  ) : userDetails?.gender === "f" ? (
                    <p>Female</p>
                  ) : (
                    <p>Gender not specified</p>
                  )}
                </div>
              </div>
              {!userDetails?.isMobileVerified && (
                <div className="flex-[1] flex-row flex items-center">
                  <button
                    onClick={handleMobileOpen}
                    className=" bg-blue-600 p-2 text-white rounded-md"
                  >
                    Verify Your Number
                  </button>
                </div>
              )}
            </div>

            <div className="flex flex-col mt-2 gap-2 lg:flex-row lg:gap-0">
              <div className="flex-[1] flex flex-row items-center ">
                <FontAwesomeIcon icon={faTag} className="pr-4" />{" "}
                <p>{userDetails?.email}</p>
              </div>
              <div className="flex-[1]">
                <FontAwesomeIcon icon={faUser} className="pr-4" /> +91{" "}
                {userDetails?.mobileNumber}
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="absolute flex items-center justify-center h-screen">
            <VerifyMobile
              showModal={showMobileModal}
              onClose={handleMobileCloseModal}
            />
          </div>
        </section>
        <section>
          {data.aadharVerify &&
            data.cibilVerify &&
            data.itrVerify &&
            !data.signzySent && (
              <div className=" p-10 flex flex-col items-center rounded-lg shadow-lg mb-2 gap-4">
                <Signzy />
              </div>
            )}
          <div>
            {cibilScoreView !== null ? (
              <CibilScoreView score={cibilScoreView} />
            ) : (
              <>
                {data.cibilVerify ? null : (
                  <div className="text-black bg-white p-10 flex flex-wrap flex-row items-center rounded-lg shadow-lg">
                    <div className="flex-[2]">
                      <div className="border-[1px] border-solid border-gray-200 rounded-lg">
                        <div className="p-2">
                          <div className="flex flex-wrap flex-col md:flex-row items-center gap-5">
                            <div className="flex-[1]">
                              <img src={iconette} alt="icons" />
                            </div>
                            <ExperianCibil />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hidden  bg-transparent lg:flex-[1] lg:block">
                      <Player
                        autoplay
                        loop
                        src="https://lottie.host/a2f95337-00a3-4e88-b926-766b2b983405/l05ONvC62X.json"
                        style={{ height: "auto", width: "100%" }}
                      ></Player>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </section>
      </div>
      {/* <img
        src={iconette}
        alt="icons"
        className="absolute bottom-5 right-5 opacity-25 z-[-1]"
      /> */}
      <div
        className="fixed w-full h-full top-0 left-0 z-[-10]"
        id="DASHbackground"
      ></div>
    </div>
  );
};

export default Profile;
