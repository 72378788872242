import React from "react";
import axios from "axios";
import AdminPercentage from "./AdminPercentage";

const AdminAutoPay = ({ userID, show }) => {
  console.log("Show>>>", show);

  const grantAutoPayAccess = () => {

    const token = localStorage.getItem("token");

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/v1/admin/autopay-access`,
        {
          id: userID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Handle response if needed
        console.log("AutoPay access granted successfully");
      })
      .catch((error) => {
        // Handle error if needed
        console.error("Error granting AutoPay access:", error);
      });
  };

  return (
    <div className="p-5 flex flex-col gap-4  bg-slate-900 rounded-lg">
      <div className="">
        {show?.AutoPayAccess ? (
          <p className="text-green-500">User got their autopay</p>
        ) : (
          <button
            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2`}
            onClick={grantAutoPayAccess}
          >
            Grant AutoPay Access
          </button>
        )}
      </div>
    </div>
  );
};

export default AdminAutoPay;
