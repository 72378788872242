import React, { useState, useRef, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const NavbarDrop = ({ ChangeView }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate()
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const dropdownVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="relative group">
      {/* Navbar Link */}
      <div className="text-black no-underline text-base font-semibold px-3 transition duration-500 cursor-pointer hover:border-b-3 hover:border-current" onClick={toggleDropdown}>
        More
        <ExpandMoreIcon />
      </div>

      {/* Dropdown Content */}
      <AnimatePresence>
        {isDropdownOpen && (
          <motion.div
            ref={dropdownRef}
            className="absolute z-10 w-[200px] mt-2"
            variants={dropdownVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <div className="bg-white flex flex-col gap-3 text-gray-800 rounded-md shadow-lg p-2">
              {/* Dropdown Items */}
              <div
                onClick={() => {
                  ChangeView(5);
                  setIsDropdownOpen(!isDropdownOpen);
                }}
                className="px-3 py-2 hover:bg-gray-200"
              >
                Faq
              </div>
              <div
                onClick={() => {
                  ChangeView(6);
                  setIsDropdownOpen(!isDropdownOpen);
                }}
                className="px-3 py-2 hover:bg-gray-200"
              >
                Eligibility
              </div>
              <div
                onClick={() => {
                  ChangeView(7);
                  setIsDropdownOpen(!isDropdownOpen);
                }}
                className="px-3 py-2 text-sm hover:bg-gray-200"
              >
                Financial Dictionary
              </div>
              <div
                onClick={() => {
                  ChangeView(8);
                  setIsDropdownOpen(!isDropdownOpen);
                }}
                className="px-3 py-2 text-sm hover:bg-gray-200"
              >
                Investment
              </div>
              <div
                onClick={() => {
                  ChangeView(9);
                  setIsDropdownOpen(!isDropdownOpen);
                }}
                className="px-3 text-sm py-2 hover:bg-gray-200"
              >
                Contact
              </div>
              <div
                onClick={() => {
                  navigate("/payment")
                  setIsDropdownOpen(!isDropdownOpen);
                }}
                className="px-3 text-sm py-2 hover:bg-gray-200"
              >
                Payments
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NavbarDrop;
