import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, NavLink, useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPass, setLoadingPass] = useState(false);
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [showPasswordInput, setShowPasswordInput] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  console.log("Tokem>>>>", token);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/auth/forgot-password`,
        { email: email }
      );
      console.log(response.data);
      toast.success("Check your email for instructions");
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Error sending email. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getTokenFromURL = () => {
      const searchParams = new URLSearchParams(location.search);
      const tokenFromURL = searchParams.get("token");
      setToken(tokenFromURL || "");
      setShowPasswordInput(!!tokenFromURL); // Show password input only if token is present
    };

    getTokenFromURL();
  }, [location.search]);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleResetPassword = async () => {
    setLoadingPass(true);
    try {
      // Send axios post request with token in params and password in body
      await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/auth/reset-password?token=${token}`,
        { password }
      );

      console.log("Password reset successful!");
    } catch (error) {
      // Handle error
      console.error("Error resetting password:", error.message);
    } finally {
      setPassword(" ");
      setLoadingPass(false);
    }
  };

  return (
    <div className="flex justify-center flex-col items-center h-screen">
      <div className="bg-gray-200 p-4 rounded shadow-md w-96">
        <div className="flex flex-row items-center  gap-2">
          <h2 className="text-2xl font-bold mb-4">Forgot Password</h2>
          <NavLink
            to={"/"}
            className={"font-semibold mb-4 underline text-blue-500"}
          >
            Login?
          </NavLink>
        </div>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-600"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            className="mt-1 p-2 w-full border rounded-md"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          onClick={handleSubmit}
        >
          {loading ? "Sending" : "Send Reset Link"}
        </button>
      </div>
      <div className="bg-gray-200 p-4 rounded shadow-md w-96">
        {showPasswordInput && (
          <>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-600"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="mt-1 p-2 w-full border rounded-md"
              value={password}
              onChange={handlePasswordChange}
            />
            <button
              className="bg-blue-500 mt-3 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              onClick={handleResetPassword}
              disabled={loadingPass}
            >
              {loadingPass ? "Resetting..." : "Reset Password"}
            </button>
          </>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}