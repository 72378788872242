
import React from "react";
import SuccessGif from "./../../core/images/success.gif";
import { Link } from "react-router-dom";

const SuccessPage = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="text-center">
        <img
          src={SuccessGif}
          alt="Success"
          className="mx-auto max-w-full mb-6"
        />
        <h2 className="text-2xl font-bold mb-2">
          Nach Completed Successfully
        </h2>
        <p>Enjoy your creds with PlentyCred! 🎉</p>
        <Link
          to="/"
          className="mt-4 inline-block px-6 py-3 bg-gradient-to-r from-pink-500 to-yellow-500 text-white rounded-md hover:bg-gradient-to-r hover:from-pink-600 hover:to-yellow-600 transition duration-300"
        >
          Go Home
        </Link>
      </div>
    </div>
  );
};

export default SuccessPage;
