import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsEyeglasses } from "react-icons/bs";
import { NavLink } from "react-router-dom";

const AdminUsersTable = ({ data }) => {
  let rows = [];

  if (data) {
    rows = data.map((item) => {
      return {
        id: item.id,
        name: item.fullName,
        email: item.email,
        mobileNumber: item.mobileNumber,
        gender: item.gender,
        role: item.role,
        ip_address: item.ip_address,
        device: item.device,
      };
    });
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
      renderCell: (params) => {
        return (
          <div className="font-regular text-slate-6    00">
            {params.row.id}
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Full Name",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="font-regular text-slate-800">{params.row.name}</div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 290,
      renderCell: (params) => {
        return (
          <div className="font-regular text-slate-800">{params.row.email}</div>
        );
      },
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="font-regular text-slate-800">
            {params.row.mobileNumber}
          </div>
        );
      },
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 90,
      renderCell: (params) => {
        return (
          <div className="font-regular text-slate-800">{params.row.gender}</div>
        );
      },
    },
    {
      field: "ip_address",
      headerName: "ip address",
      width: 190,
      renderCell: (params) => {
        return (
          <div className="font-regular text-slate-800">
            {params.row.ip_address}
          </div>
        );
      },
    },
    {
      field: "view",
      headerName: "View",
      width: 70,
      renderCell: (params) => {
        return (
          <div className="font-bold text-slate-800">
            <NavLink to={`/admin/${params.row.id}`}>
              <BsEyeglasses size={30} />
            </NavLink>
          </div>
        );
      },
    },
  ];

  console.log("AdMinTAbleee", data);

  return (
    <div>
      <div className=" bg-white shadow-lg mt-10 overflow-hidden p-10 rounded-lg">
        <div className="p-1">
          <h1 className="text-2xl text-gray-700 xl:text-3xl font-semibold mb-5">
            <span className="font-regular">All</span> Users
          </h1>
        </div>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 20]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </div>
    </div>
  );
};

export default AdminUsersTable;
