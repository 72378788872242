import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Signzy() {
  const [userDetails, setUserDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json", // Set content type to form data
      Authorization: `Bearer ${token}`, // Include the Bearer token in the headers
    },
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload()
      return;
    }

    const token = localStorage.getItem("token");
    console.log("here is token", token);
    const decodeToken = jwtDecode(token);
    console.log("here is decodedtoken", decodeToken);
    const userId = decodeToken.id || decodeToken.sub;
    console.log("here is userId", userId);
    // eslint-disable-next-line no-undef
    axios
      .get(`${process.env.REACT_APP_API_KEY}/v1/auth/getUser/${userId}`)
      .then((response) => {
        console.log(response);
        setUserDetails(response.data.userDetails);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const mobileNumber = userDetails.mobileNumber;
  
  const handleAgree = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/contract/create-contract`,
        { userMobileNumber: mobileNumber },
        config
      );
      console.log("Response:", response);

      await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/contract/frontend-popup`,
        {},
        config
      );

      toast.success("Agreed Successfully", { theme: "colored" });
    } catch (error) {
      toast.error("Error....", { theme: "colored" });
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  return (
    <div>
      <div className="flex justify-start flex-col gap-4">
        <h1 className="text-lg font-semibold">Complete Verfication</h1>
        <div className="text-white">
          DISCLAIMER: This contract disclaimer asserts that both parties
          acknowledge and accept the terms mutually agreed upon. It is not a
          substitute for legal advice. Verify details independently. Proceeding
          indicates understanding and consent.
        </div>
        <div className="">
          <Button onClick={handleAgree} variant="contained">
            {loading ? "Sending" : "Agree"}
          </Button>
        </div>
      </div>
    </div>
  );
}
