import React, { useEffect, useState } from "react";
import AdminContacts from "./AdminContacts";
import { useParams } from "react-router-dom";
import { useNavigate as useNavigation } from "react-router-dom";
import axios from "axios";
import AdminMobileMedia from "./AdminMobileMedia";

const AdminUserContactMedia = () => {
  // const { id } = useParams();
  // const userId = parseInt(id);
  // const token = localStorage.getItem("token");
  // const navigation = useNavigation();
  // const [contacts, setContacts] = useState([]);
  // const [media, setMedia] = useState([]);
  // const [userDetails, setUserDetails] = useState(null);
  // const mobileNumber = "6504992804";

  // if (!token) {
  //   navigation("/");
  // }

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const userDetailsResponse = await axios.post(
  //         `${process.env.REACT_APP_API_KEY}/v1/admin/userid`,
  //         { id: userId },
  //         {
  //           headers: {
  //             "X-admin-auth": "1234567890",
  //           },
  //         }
  //       );
  //       setUserDetails(userDetailsResponse.data.data);
  //     } catch (error) {
  //       console.error("Error fetching user details:", error);
  //     }
  //   };

  //   fetchData();
  // }, [userId]);

  // useEffect(() => {
  //   const fetchContacts = async () => {
  //     if (userDetails && userDetails.mobileNumber) {
  //       // const mobileNumber = userDetails.mobileNumber;
  //       const contactsRef = firebase.database().ref(mobileNumber);

  //       contactsRef.on("value", (snapshot) => {
  //         const fetchedContacts = [];
  //         snapshot.forEach((childSnapshot) => {
  //           const contact = childSnapshot.val();
  //           fetchedContacts.push(contact);
  //         });
  //         setContacts(fetchedContacts);
  //       });

  //       return () => contactsRef.off("value");
  //     }
  //   };

  //   fetchContacts();
  // }, [userDetails]);

  return (
    <div>
      {/* UserContact Start */}
      <div>
        {/* <AdminContacts contacts={contacts} /> */}
      </div>
      {/* UserContact END */}

      <div>
        {/* <AdminMobileMedia folderId={mobileNumber} /> */}
      </div>
    </div>
  );
};

export default AdminUserContactMedia;
