import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DoneRounded, SmsFailed } from "@mui/icons-material";

const AdminOK = ({ userId, show }) => {
  const [loading, setLoading] = useState(false);

  const acceptUser = async () => {
    const token = localStorage.getItem("token");

    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/admin/accept`,
        {
          id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("User accepted successfully", { theme: "colored" });
      console.log("User accepted successfully");
    } catch (error) {
      toast.error("Error ", { theme: "colored" });

      console.error("Error accepting user:", error);
    } finally {
      setLoading(false);
    }
  };

  const declineUser = async () => {
    const token = localStorage.getItem("token");

    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_API_KEY}/v1/admin/reject`,
        { id: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("User declined successfully", { theme: "colored" });
      console.log("User declined successfully");
    } catch (error) {
      toast.error("Error ", { theme: "colored" });
      console.error("Error declining user:", error);
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  return (
    <>
      <div className=" text-white">
        {show?.PaymentAccess && (
          <div className="flex flex-row items-center gap-2 justify-center">
            <div>
              <DoneRounded color="success" />
            </div>
            <h1 className="text-green-600">Profile Accepted</h1>
          </div>
        )}
        {show?.RejectedProfile && (
          <div className="flex flex-row items-center gap-2 justify-center">
            <div>
              <SmsFailed color="error" />
            </div>
            <h1 className="text-red-600">Profile Rejected</h1>
          </div>
        )}
        {!show?.PaymentAccess && !show?.RejectedProfile && (
          <>
            <button
              className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2 ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={acceptUser}
              disabled={loading}
            >
              {loading ? "Accepting..." : "Accept User"}
            </button>
            <button
              className={`bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={declineUser}
              disabled={loading}
            >
              {loading ? "Declining..." : "Decline User"}
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default AdminOK;
