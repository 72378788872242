import React from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminCancelMandate = ({ userId }) => {
  const cancelMandate = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Token is missing!");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v2/nach/cancel-mandate`,
        { userId: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Mandate canceled successfully!");
    } catch (error) {
      toast.error("An error occurred while canceling the mandate.");
      console.error(error);
    }
  };

  return (
    <div>
      <button
        onClick={cancelMandate}
        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
      >
        Cancel Mandate
      </button>
      <ToastContainer />
    </div>
  );
};

export default AdminCancelMandate;
