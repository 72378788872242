import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
export default function AboutTable() {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div className="flex flex-col xl:flex-row justify-center gap-5">
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: -100 },
        }}
        transition={{ duration: 1.3 }}
        className="bg-white p-6 rounded-md shadow-lg flex-[1]"
      >
        <ol>
          <li className="mb-6">
            <span className="font-bold text-blue-500">Location:</span> You
            should be residing in Nagpur, India. <br />
            <span className="text-sm">
              (we are expanding to other cities soon)
            </span>
          </li>
          <li className="mb-6">
            <span className="font-bold text-blue-500">Age:</span> Your age
            should be between 18-60 years old
          </li>
          <li className="mb-6">
            <span className="font-bold text-blue-500">Criminal record:</span>{" "}
            You should have a clean record
          </li>
          <li className="mb-6">
            <span className="font-bold text-blue-500">Profession:</span> You can
            be a student, working professional, or self-employed individual
          </li>
          <li className="mb-6">
            <span className="font-bold text-blue-500">Salary:</span> You should
            have a minimum salary of INR 12,000
          </li>
          <li>
            <span className="font-bold text-blue-500">CIBIL:</span> You should
            have a minimum CIBIL score of 650
          </li>
        </ol>
      </motion.div>
      <motion.table
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: -100 },
        }}
        transition={{ duration: 1.3 }}
        className="xl:p-0 p-4 table-fixed w-full border-collapse border flex-[1] rounded-lg
        shadow-lg"
      >
        <thead>
          <tr className="bg-gray-200">
            <th className="w-1/3 px-4 py-2 font-semibold border text-center">
              Criteria
            </th>
            <th className="w-1/3 px-4 py-2 font-semibold border text-center">
              Student
            </th>
            <th className="w-1/3 px-4 py-2 font-semibold border text-center">
              Parent
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-2 font-semibold border text-center">Age</td>
            <td className="px-4 py-2 border text-center">Above 18</td>
            <td className="px-4 py-2 border text-center">18-60</td>
          </tr>

          <tr>
            <td className="px-4 py-2 font-semibold border text-center">
              CIBIL
            </td>
            <td className="px-4 py-2 border text-center">NA</td>
            <td className="px-4 py-2 border text-center">Above 650</td>
          </tr>
          <tr>
            <td className="px-4 py-2 font-semibold border text-center">
              Salary
            </td>
            <td className="px-4 py-2 border text-center">NA</td>
            <td className="px-4 py-2 border text-center">Above INR 12,000</td>
          </tr>
          <tr>
            <td className="px-4 py-2 font-semibold border text-center">
              Location
            </td>
            <td className="px-4 py-2 border text-center">Nagpur</td>
            <td className="px-4 py-2 border text-center">Nagpur</td>
          </tr>
          <tr>
            <td className="px-4 py-2 font-semibold border text-center">
              Criminal record
            </td>
            <td className="px-4 py-2 border text-center">Clean</td>
            <td className="px-4 py-2 border text-center">Clean</td>
          </tr>
        </tbody>
      </motion.table>
    </div>
  );
}
