import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import axios from "axios";

export default function ChartsOverviewDemo() {
  const [chartData, setChartData] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_KEY}/v1/counter/getCounter`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data.data.Counter;
        const groupedData = groupDataByDate(data);
        setChartData(groupedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const groupDataByDate = (data) => {
    const groupedData = {};
    const today = new Date();
    const lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 7
    );

    data.forEach((item) => {
      const itemDate = new Date(item.created_at);

      if (itemDate >= lastWeek) {
        const date = itemDate.toISOString().split("T")[0]; // Format date as yyyy-mm-dd

        if (groupedData[date]) {
          groupedData[date]++;
        } else {
          groupedData[date] = 1;
        }
      }
    });

    return groupedData;
  };

  const formatDataForChart = (data) => {
    return Object.keys(data).map((date) => ({
      data: [data[date]],
      date: date,
    }));
  };

  return (
    <div className="overflow-x-auto">
      <BarChart
        series={formatDataForChart(chartData)}
        height={380}
        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
      />
    </div>
  );
}
