// import React, { useState, useEffect } from "react";
// import logoIC from "../core/images/logoPC.png";
// import { Link, NavLink, useNavigate } from "react-router-dom";
// import { animateScroll as scroll } from "react-scroll";
// import styles from "../core/css/header.module.css";
// import Avatar from "@mui/material/Avatar";
// import { styled } from "@mui/material/styles";
// import Button from "@mui/material/Button";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import longLogo from "../core/images/logo-noncopyright.png";
// import FaceIcon from "@mui/icons-material/Face";
// import StraightIcon from "@mui/icons-material/Straight";
// import {
//   faBars,
//   faTimes,
//   faUser,
//   faChevronRight,
//   faArrowRight,
//   faTruckFast,
//   faForward,
// } from "@fortawesome/free-solid-svg-icons";
// import NavMenu from "./Header/NavMenu";
// import MobileNav from "./Header/MobileNav";
// import { motion } from "framer-motion";
// import { jwtDecode } from "jwt-decode";
// import NavbarDrop from "./Header/NavbarDrop";
// import axios from "axios";
// import useDeviceType from "./Props/DeviceType";
// import { IoLogoGooglePlaystore } from "react-icons/io5";
// import HeaderComponent from "./NavCompRoutes/HeaderComponent";
// import NavbarComponent from "./NavCompRoutes/NavbarComponent";
// import { useViewManager } from "./NavCompRoutes/UseViewManger";
// // import { useAuth } from "../Context/AuthProvider";

// const BootstrapButton = styled(Button)({
//   fontWeight: "600",
//   border: "2px solid #0083ec",
//   backgroundColor: "transparent",
//   textTransform: "Capitalize",
//   borderRadius: "10px",
//   boxShadow: "none",
//   color: "#0083ec",
//   "&:hover": {
//     backgroundColor: "#025fca",
//     color: "#fff",
//   },
// });

// const Header = () => {
//   const { currentViewIndex, changeView } = useViewManager();
//   const [isLoggedIn, setIsLoggedIn] = useState(
// {!!localStorage.getItem("token") }   //remove {}!!
//   );
//   const navigate = useNavigate();
//   const deviceType = useDeviceType();

//   useEffect(() => {
//     const handleTokenChange = () => {
//       setIsLoggedIn(!!localStorage.getItem("token"));
//     };
//     window.addEventListener("storage", handleTokenChange);
//     return () => {
//       window.removeEventListener("storage", handleTokenChange);
//     };
//   }, []);

//   const [isNavExpanded, setIsNavExpanded] = useState(true);
//   const [userDetails, setUserDetails] = useState("");
//   useEffect(() => {
//     // Check if token is present in local storage
//     if (isLoggedIn && localStorage.getItem("token")) {
//       const token = localStorage.getItem("token");
//       const decodeToken = jwtDecode(token);
//       const userId = decodeToken.id || decodeToken.sub;

//       axios
//         .get(`${process.env.REACT_APP_API_KEY}/v1/auth/getUser/${userId}`)
//         .then((response) => {
//           console.log(response);
//           setUserDetails(response.data.userDetails);
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     }
//   }, [isLoggedIn]);

//   return (
//     <div>
//       <nav className={styles.navbar}>
//         {/* <div className="container"> */}
//         <div className={styles.container}>
//           <NavLink to="/" className={styles.navbarBrand}>
//             <img src={longLogo} alt="logo" className="w-[145px]" />
//           </NavLink>

//           <div className={styles.credContainer} id={styles.desktop}>
//             <ul className={styles.navbarNav} id={styles.desktop}>
//               <li className={styles.navItem}>
//                 <NavLink
//                   className={styles.navLink}
//                   to="/"
//                   onClick={() => changeView(0)}
//                 >
//                   Home
//                 </NavLink>
//               </li>
//               <li className={styles.navItem}>
//                 <NavLink
//                   className={styles.navLink}
//                   to="/"
//                   onClick={() => changeView(1)}
//                 >
//                   Products
//                 </NavLink>
//               </li>
//               <li className={styles.navItem}>
//                 <NavLink
//                   onClick={() => changeView(2)}
//                   className={styles.navLink}
//                   to="/"
//                 >
//                   About us
//                 </NavLink>
//               </li>
//               <li className={styles.navItem}>
//                 <NavLink
//                   className={styles.navLink}
//                   to="/"
//                   onClick={() => changeView(3)}
//                 >
//                   Blogs
//                 </NavLink>
//               </li>
//               {/* <HeaderComponent /> */}
//               <li className={styles.navItem}>
//                 <NavLink className={styles.navLink} to="/payment">
//                   Payments
//                 </NavLink>
//               </li>
//               <li className={styles.navItem}>
//                 <NavbarDrop />
//               </li>
//               <div className="hidden xl:block">
//                 <li className={styles.navItem}>
//                   <IoLogoGooglePlaystore color="#008dff" size={30} />
//                 </li>
//               </div>

//               {/* <li className={styles.navItem}>
//                 <NavLink to="/faq" className={styles.navLink}>
//                   FAQ
//                 </NavLink>
//               </li> */}
//             </ul>
//             <div>
//               <div>
//                 {isLoggedIn ? (
//                   <div className="flex flex-row gap-4 ">
//                     <NavMenu />
//                   </div>
//                 ) : (
//                   <div className={"flex flex-row"} id={styles.desktop}>
//                     {deviceType === "desktop" ? (
//                       <>
//                         <div className={styles.cred}>
//                           <NavLink to="/login" className={styles.linkStyle}>
//                             <BootstrapButton variant="contained">
//                               <StraightIcon /> Login
//                             </BootstrapButton>
//                           </NavLink>
//                         </div>
//                         <div className={styles.cred}>
//                           <NavLink to="/signup" className={styles.linkStyle}>
//                             <BootstrapButton variant="contained">
//                               <FaceIcon /> Signup
//                             </BootstrapButton>
//                           </NavLink>
//                         </div>
//                       </>
//                     ) : (
//                       <></>
//                     )}
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//           <div className="flex flex-row xl:hidden items-center gap-4">
//             <div className="flex flex-row lg:hidden">
//               <IoLogoGooglePlaystore size={30} />
//             </div>
//             <div
//               onClick={() => {
//                 setIsNavExpanded(!isNavExpanded);
//               }}
//               className={styles.hamburger}
//               id={styles.mobile}
//             >
//               <button className={styles.hamburger}>
//                 <FontAwesomeIcon className={styles.icon} icon={faBars} />
//               </button>
//             </div>
//           </div>
//         </div>
//         {isNavExpanded ? (
//           <></>
//         ) : (
//           <>
//             <div
//               onClick={() => {
//                 setIsNavExpanded(!isNavExpanded);
//               }}
//               className={styles.invsiblediv}
//             ></div>
//           </>
//         )}
//       </nav>

//       <div
//         id={styles.siderNavigation}
//         className={
//           isNavExpanded ? styles.mobileMenuHide : styles.mobileMenuShow
//         }
//       >
//         <motion.div>
//           <div>
//             <div className={styles.hamburgerFlex} style={{ padding: "5px" }}>
//               {isLoggedIn ? (
//                 <>
//                   <div className="p-2 flex-col gap-5 flex">
//                     <div className="p-2">
//                       <img src={longLogo} alt="logo" className="w-[105px]" />
//                     </div>

//                     <Button
//                       onClick={() => {
//                         localStorage.removeItem("token");
//                           window.location.reload() // Assuming you have a navigate function
//                       }}
//                       variant="contained"
//                     >
//                       LOG OUT
//                     </Button>
//                   </div>
//                 </>
//               ) : (
//                 <>
//                   <div className="p-2">
//                     <img src={longLogo} alt="logo" className="w-[145px]" />
//                   </div>
//                 </>
//               )}

//               <div style={{ flex: "1" }}>
//                 <button
//                   onClick={() => {
//                     setIsNavExpanded(!isNavExpanded);
//                   }}
//                   className={styles.hamburgerclose}
//                 >
//                   <FontAwesomeIcon className={styles.icon} icon={faTimes} />
//                 </button>
//               </div>
//             </div>
//             <div style={{ padding: "20px" }}>
//               <hr />
//               <div className="flex  flex-col  gap-5">
//                 <div
//                   className={"p-1 flex items-center  flex-row gap-3"}
//                   onClick={() => changeView(0)}
//                 >
//                   <p>Home</p>
//                   <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
//                 </div>
//                 <NavLink
//                   className={"p-1 flex items-center  flex-row gap-3"}
//                   to="/"
//                 >
//                   <p>About</p>{" "}
//                   <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
//                 </NavLink>
//                 <NavLink
//                   className={"p-1 flex items-center  flex-row gap-3"}
//                   to="/"
//                 >
//                   <p>Blogs</p>{" "}
//                   <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
//                 </NavLink>
//                 <NavLink
//                   className={"p-1 flex items-center  flex-row gap-3"}
//                   to={"/"}
//                 >
//                   <p>Payment</p>{" "}
//                   <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
//                 </NavLink>
//                 <NavLink
//                   className={"p-1 flex items-center  flex-row gap-3"}
//                   to={"/"}
//                 >
//                   <p>Investment</p>{" "}
//                   <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
//                 </NavLink>
//                 <NavLink
//                   className={"p-1 flex items-center  flex-row gap-3"}
//                   to={"/products"}
//                 >
//                   <p>Products</p>
//                   <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
//                 </NavLink>
//                 {isLoggedIn ? (
//                   <>
//                     <NavLink
//                       className={"p-1 flex items-center  flex-row gap-3"}
//                       to={"/dashboard"}
//                     >
//                       <p>Dashboard</p>{" "}
//                       <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
//                     </NavLink>
//                     <NavLink
//                       className={"p-1 flex items-center  flex-row gap-3"}
//                       to={"/payment"}
//                     >
//                       <p>Payments</p>{" "}
//                       <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
//                     </NavLink>
//                   </>
//                 ) : (
//                   <></>
//                 )}
//                 <div>
//                   <MobileNav />
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className={styles.innerMobileMenu}></div>
//         </motion.div>
//       </div>
//     </div>
//   );
// };
// export default Header;


import React from 'react'

export default function Header() {
  return (
    <div></div>
  )
}
