import React, { useEffect } from "react";
import { VerifiedUser } from "@mui/icons-material";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const About3 = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <>
      <section className="mt-20 flex justify-center items-center flex-col">
        <div className="max-w-[600px]">
          <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: -100 },
            }}
            transition={{ duration: 1.3 }}
            className="flex flex-col text-center gap-4"
          >
            <h2 className="text-blue-600 font-semibold">WHAT WE OFFER</h2>
            <h1 className="font-semibold text-2xl">
              Everything you need to start
            </h1>
            <p className="font-light text-xl">
              We pride ourselves on providing an unparalleled experience,
              ensuring every customer's financial journey is secure, smooth, and
              satisfying. Based in Nagpur, Maharashtra
            </p>
          </motion.div>
        </div>
        <div className="mt-24 max-w-[900px]">
          <div className="flex flex-col xl:flex-row gap-32 justify-center items-center ">
            {/* Content */}
            <motion.div
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: -150 },
              }}
              transition={{ duration: 1.3 }}
              className="p-4 flex items-start flex-col gap-20"
            >
              <div className="max-w-[500px] flex flex-row gap-3">
                <div className=" hidden xl:block text-blue-400 mt-1">
                  <VerifiedUser />
                </div>
                <div>
                  <h1 className="text-2xl font-medium">Eligibility</h1>
                  <p className="text-sm">
                    Ensure eligibility criteria (listed below) are met for
                    availing our microfinance services
                  </p>
                </div>
              </div>
              <div className="max-w-[500px] flex flex-row gap-3">
                <div className=" hidden xl:block text-blue-400 mt-1">
                  <VerifiedUser />
                </div>
                <div>
                  <h1 className="text-2xl font-medium">Registration</h1>
                  <p className="text-sm">
                    Visit the registration webpage to initiate the application
                    process with only the PAN number
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 150 },
              }}
              transition={{ duration: 1.3 }}
              className="p-4 flex items-start flex-col gap-20"
            >
              <div className="max-w-[500px] flex flex-row gap-3">
                <div className=" hidden xl:block text-blue-400 mt-1">
                  <VerifiedUser />
                </div>
                <div>
                  <h1 className="text-2xl font-medium">
                    Paperless Application
                  </h1>
                  <p className="text-sm">
                    Once PAN is verified, you need to provide your basic KYC
                    details, with 0% paper trail
                  </p>
                </div>
              </div>
              <div className="max-w-[500px] flex flex-row gap-3">
                <div className=" hidden xl:block text-blue-400 mt-1">
                  <VerifiedUser />
                </div>
                <div>
                  <h1 className="text-2xl font-medium">Profile Activation</h1>
                  <p className="text-sm">
                    After the one-time registration, your profile will be
                    activated within 24 hours
                  </p>
                </div>
              </div>
            </motion.div>
            {/* Content */}
          </div>
        </div>
      </section>
    </>
  );
};

export default About3;
