import React from "react";
import Payment from "./Payment";
import Header from "../Header";
import Footer from "../Footer";
import FooterMain from "../FooterMain";

const IndexPayment = () => {
  return (
    <div>
      {/* <Header /> */}
      <Payment />
      <FooterMain />
    </div>
  );
};

export default IndexPayment;
