import React, { useState, useEffect } from "react";
import axios from "axios";

const AdminCourtCase = ({ userId }) => {
  const [courtCase, setCourtCase] = useState(null);

  const fetchCourtCase = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/v1/admin/get-courtcase/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCourtCase(response.data);
    } catch (error) {
      console.error("Error fetching court case data:", error);
    }
  };

  return (
    <div className="p-6 bg-gray-100 flex flex-col items-center justify-center">
      <div
        onClick={fetchCourtCase}
        className="mb-4 px-4 py-2 bg-blue-500 text-white font-semibold rounded-md cursor-pointer hover:bg-blue-600 transition"
      >
        Fetch Court Case
      </div>
      <div className="w-full max-w-4xl bg-white shadow-md rounded-lg p-6">
        {courtCase ? (
          <pre className="text-sm text-gray-800 whitespace-pre-wrap">
            {JSON.stringify(courtCase, null, 2)}
          </pre>
        ) : (
          <p className="text-gray-500 text-center">No data available...</p>
        )}
      </div>
    </div>
  );
};

export default AdminCourtCase;
