import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/v2/contact/post-contact`,
        formData
      );
      toast.success("Your message has been sent successfully!");
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      toast.error("Failed to send your message. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-md border-blue-700 border-b-[10px]">
      <h2 className="text-4xl font-semibold mb-4 text-[#222]">Ask Us?</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <input
            type="text"
            id="name"
            name="name"
            placeholder="What's Your Name?"
            value={formData.name}
            onChange={handleInputChange}
            required
            className="border border-[#eee] rounded px-4 py-2 w-full focus:outline-none focus:border-[#eee]"
            disabled={loading}
          />
        </div>
        <div className="mb-4">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter Your Email?"
            value={formData.email}
            onChange={handleInputChange}
            required
            className="border border-[#eee] rounded px-4 py-2 w-full focus:outline-none focus:border-[#eee]"
            disabled={loading}
          />
        </div>
        <div className="mb-4">
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            required
            placeholder="Your Query"
            className="border border-[#eee] rounded px-4 py-2 w-full h-24 resize-none focus:outline-none focus:border-[#eee]"
            disabled={loading}
          />
        </div>
        <button
          type="submit"
          className={`bg-blue-600 text-white font-semibold px-6 py-2 rounded focus:outline-none ${
            loading
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-blue-700 focus:bg-blue-700"
          }`}
          disabled={loading}
        >
          {loading ? "Sending..." : "Send"}
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ContactForm;
