import React, { useState, useEffect } from "react";
import { MdCancel } from "react-icons/md";
import { FcOk } from "react-icons/fc";

const CibilScoreView = ({ score }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating a fetch or calculation delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="loader text-white">Loading...</div>
      </div>
    );
  }

  return (
    <div className="max-w-lg p-4 bg-white mx-auto rounded-xl shadow-md overflow-hidden md:max-w-[1000px]">
      <div className={`px-4 py-4 md:px-6 md:py-6`}>
        <div>
          <div className="flex flex-col lg:flex-row justify-evenly items-center">
            <div
              className="rounded-full  w-[200px] h-[200px] flex flex-wrap justify-center items-center border-[8px] border-b-[#f1c232] border-r-[#ff5c5c] border-l-[#11c4be] border-t-blue-600 shadow-xl"
              id="rtlanimation"
            >
              <span className="font-bold p-10 text-4xl text-white">{score}</span>
            </div>
            <div>
              {score > 649 ? (
                <div className="bg-white">
                  <div className="text-green-600 py-2 border-none px-3 rounded-md  flex flex-row gap-1 items-center text-xl font-semibold">
                    <h1>Accepted</h1>
                    <div className="text-green-600">
                      <FcOk />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-white p-4 flex flex-col gap-2 justify-center items-center rounded-md ">
                  <div className="text-red-600  border-none rounded-md  text-xl flex flex-row gap-1 items-center font-semibold">
                    <h1> Rejected </h1>
                    <div className="text-red-600">
                      <MdCancel />
                    </div>
                  </div>

                  <div className="w-60">
                    <h2>
                      Ohh sorry! You are not eligible for the PlentyCred
                      website.
                    </h2>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CibilScoreView;
