import React from "react";
import { Player } from '@lottiefiles/react-lottie-player';
import eStyle from '../core/css/error.module.css';
import { NavLink } from "react-router-dom";

const Error = () => {
    return (
        <>
            <div className={eStyle.container}>
                <div className={eStyle.left_container} style={{ flex: "1" }}>
                    <h1>
                        Oooppss!!!
                    </h1>
                    <br />
                    <p>
                        The page you are looking for does not exists
                        or an other error occurred
                    </p>
                    <br />
                    <br />
                    <NavLink to="/" className={eStyle.navButton}>
                        Go Back to Home
                    </NavLink>
                </div>
                <div className={eStyle.right_container} style={{ flex: "1" }}>
                    <Player
                        autoplay
                        loop
                        src="https://lottie.host/7e92dc41-5b0f-4d48-b685-8236dac8f572/Cl6ouBvYbp.json"
                        style={{ height: '200px' }}>
                    </Player>
                </div>
            </div>
        </>
    );
}

export default Error;