import React, { useEffect, useState } from "react";
import axios from "axios";
export default function Aadhar0() {
  const [data, setData] = useState("");

  const token = localStorage.getItem("token");

  const kycConfig = {
    headers: {
      "Content-Type": "application/json", // Set content type to form data
      Authorization: `Bearer ${token}`, // Include the Bearer token in the headers
    },
  };

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/v2/kyc/get-kyc`,
        {},
        { headers }
      )
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.error("Error sending POST request:", error);
      });
  }, []);
  console.log("response>>>>", data);

  return (
    <div className="mt-10">
      <table className="min-w-full bg-white text-black border border-gray-300">
        <tbody>
          {data.aadharNo && (
            <tr className="border-b border-gray-300">
              <td className="py-2 px-4 font-semibold">Aadhar Number:</td>
              <td className="py-2 px-4">{data.aadharNo}</td>
            </tr>
          )}
          {data.panCardNo && (
            <tr className="border-b border-gray-300">
              <td className="py-2 px-4 font-semibold">PAN Card Number:</td>
              <td className="py-2 px-4">{data.panCardNo}</td>
            </tr>
          )}
          {data.ifscCode && (
            <tr className="border-b border-gray-300">
              <td className="py-2 px-4 font-semibold">IFSC Code:</td>
              <td className="py-2 px-4">{data.ifscCode}</td>
            </tr>
          )}
          {data.bankAccountNo && (
            <tr className="border-b border-gray-300">
              <td className="py-2 px-4 font-semibold">Bank Account Number:</td>
              <td className="py-2 px-4">{data.bankAccountNo}</td>
            </tr>
          )}
          {data.consumerId && (
            <tr className="border-b border-gray-300">
              <td className="py-2 px-4 font-semibold">EL ConsumerId:</td>
              <td className="py-2 px-4">{data.consumerId}</td>
            </tr>
          )}
          {data.drivingLicense && (
            <tr className="border-b border-gray-300">
              <td className="py-2 px-4 font-semibold">Driving License:</td>
              <td className="py-2 px-4">{data.drivingLicense}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
