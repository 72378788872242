"use client";
import React, { useState, useEffect } from "react";
import {
  faBriefcase,
  faChartLine,
  faLock,
  faMoneyBillTransfer,
  faMoneyBills,
  faPercent,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TAGS = [
  "Empowerment",
  "Integrity",
  "Customer Centricity",
  "Transparency",
  "Creativity",
];

const SKILLS_CONFIG = {
  DURATION: 15000,
  ROWS: 1, // Change to 2 rows
  TAGS_PER_ROW: 8, // Change to 6 tags per row
};

const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;

const shuffle = (arr) => {
  let array = [...arr];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const InfiniteLoopSlider = ({ children, duration, reverse = false }) => {
  return (
    <div
      className="loop-slider"
      style={{
        "--duration": `${duration}ms`,
        "--direction": reverse ? "reverse" : "normal",
      }}
    >
      <div className="inner">
        {children}
        {children}
      </div>
    </div>
  );
};

const randomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const Tag = ({ text, icon }) => (
  <div
    className={`tag border-[2px] rounded-lg p-2 m-1`}
    style={{ borderColor: randomColor() }}
  >
    <div>{text}</div>
  </div>
);

const HeroT = ({ duration, reverse }) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const tagsSubset = shuffle(TAGS).slice(0, SKILLS_CONFIG.TAGS_PER_ROW);
    setTags(tagsSubset);
  }, []);

  const getRandomIcon = () => {
    const icons = [
      faBriefcase,
      faChartLine,
      faLock,
      faMoneyBillTransfer,
      faMoneyBills,
      faPercent,
    ];
    return icons[random(0, icons.length)];
  };

  return (
    <InfiniteLoopSlider duration={duration} reverse={reverse}>
      {tags.map((tag) => (
        <Tag text={tag} icon={getRandomIcon()} key={tag} />
      ))}
    </InfiniteLoopSlider>
  );
};

const AboutAll = () => (
  <div className="app">
    <div className="tag-list">
      {[...new Array(SKILLS_CONFIG.ROWS)].map((_, i) => (
        <HeroT
          key={i}
          duration={random(
            SKILLS_CONFIG.DURATION - 5000,
            SKILLS_CONFIG.DURATION + 5000
          )}
          reverse={i % 2 === 1}
        />
      ))}
      <div className="fade" />
    </div>
  </div>
);

export default AboutAll;
