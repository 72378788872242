import React, { useEffect, useState } from "react";

import { Link, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Logo from "../core/images/logo-b.png";
import { jwtDecode } from "jwt-decode";
import {
  faRightToBracket,
  faHouse,
  faFile,
  faBuildingColumns,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import DashMobileNav from "../components/DashboardMobileNav";
import longLogo from "../core/images/longLogo.png";
import { History } from "@mui/icons-material";
import AvatarLivePhoto from "../components/Props/AvatarLivePhot";
import CourtCase from "../Dashboard/CourtCase";

const Dashboard = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [show, setShow] = useState("");

  const token = localStorage.getItem("token");

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${process.env.REACT_APP_API_KEY}/v1/auth/me`, { headers })
      .then((response) => {
        setShow(response.data.VerifyAll);
        setUserDetails(response.data);
      })
      .catch((error) => {
        console.error("Error sending POST request:", error);
      });
  }, []);

  console.log(show);

  return (
    <>
      {/* Sidebar Nav  */}
      {/* Mobile SlideNav */}
      {/* Mobile SlideNav */}
      <div className="float-left relative hidden xl:block top-0 left-0 min-h-screen w-[300px] bg-white shadow-lg rounded-tr-[60px] rounded-br-[60px] mr-[20px]">
        <div className="p-4">
          <div>
            <img src={longLogo} alt="longLogo" className="w-[140px] mb-5" />
            <div className="flex flex-row gap-5">
              {/* <div>
                <AvatarLivePhoto />
              </div> */}
              {userDetails && (
                <div>
                  <p className="text-sm font-semibold">
                    {userDetails.fullName}
                  </p>
                  <p className="text-xs font-medium">
                    @{`${userDetails.fullName}`}{" "}
                  </p>
                </div>
              )}
            </div>
          </div>
          <br />
          <div className="flex flex-col gap-4 mt-4 pl-2">
            <NavLink
              to={"/dashboard"}
              className="transition font-semibold block bg-transparent text-[#444] hover:bg-gray-300 p-4 rounded-lg"
            >
              <FontAwesomeIcon icon={faHouse} className="pr-2 text-[#111]" />
              Dashboard
            </NavLink>
            {show?.PaymentAccess ? (
              <>
                <NavLink
                  to={"/dashboard/transaction"}
                  className="transition font-semibold block bg-transparent text-[#444] hover:bg-gray-300 p-4 rounded-lg"
                >
                  <History icon={faHouse} className="pr-2 text-[#111]" />
                  Transaction History
                </NavLink>
              </>
            ) : (
              <></>
            )}

            {show.cibilVerify ? (
              <>
                {!show.itrVerify ? (
                  <div>
                    <NavLink
                      to={"/dashboard/kyc"}
                      className="transition font-semibold block bg-transparent text-[#444] hover:bg-gray-300 p-4 rounded-lg"
                    >
                      <FontAwesomeIcon
                        icon={faFile}
                        className="pr-2 text-[#111]"
                      />
                      KYC
                    </NavLink>
                  </div>
                ) : null}
                {show?.LivePhotoVerify ? (
                  <></>
                ) : (
                  <>
                    <NavLink
                      to={"/dashboard/photo"}
                      className="transition font-semibold block bg-transparent text-[#444] hover:bg-gray-300 p-4 rounded-lg"
                    >
                      <FontAwesomeIcon
                        icon={faFile}
                        className="pr-2 text-[#111]"
                      />
                      Live Photo
                    </NavLink>
                  </>
                )}
                {
                  show?.courtCase ? (
                    <div></div>
                  ) : (
                    <>
                      <NavLink
                        to={"/dashboard/legalcheck"}
                        className="transition font-semibold block bg-transparent text-[#444] hover:bg-gray-300 p-4 rounded-lg"
                      >
                        <FontAwesomeIcon
                          icon={faFile}
                          className="pr-2 text-[#111]"
                        />
                        Legal Check
                      </NavLink>
                    </>
                  )
                }
                {show?.edVerify ? (
                  <></>
                ) : (
                  <>
                    <NavLink
                      to={"/dashboard/employmentdetails"}
                      className="transition font-semibold block bg-transparent text-[#444] hover:bg-gray-300 p-4 rounded-lg"
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        className="pr-2 text-[#111]"
                      />
                      Employ Details
                    </NavLink>
                  </>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
      {/* End Of Sidebar Nav  */}
      <NavLink to={"/"}>
        <button
          id="glassMorph"
          className="transition-transform duration-300 ease-in-out absolute top-5 right-10 py-3 px-4 rounded-lg text-sm font-semibold text-white bg-gray-800 hover:bg-gray-900 hover:text-white transform hover:translate-y-1"
        >
          Exit <FontAwesomeIcon icon={faRightToBracket}></FontAwesomeIcon>
        </button>
      </NavLink>
      {/* Page Title Here */}
      <div className="absolute xl:pl-60 w-full h-full z-[-9]">
        <div className="xl:hidden bg-transparent">
          <DashMobileNav />
        </div>
        <div className="">
          <Outlet />
        </div>
      </div>
      {/* End Of Section  */}
    </>
  );
};

export default Dashboard;
