// HeaderTransaction.js
import React, { useState } from 'react';

const HeaderTransaction = ({ setActiveTab }) => {
  const [activeTab, setActive] = useState(1);

  const handleTabClick = (tabIndex) => {
    setActive(tabIndex);
    setActiveTab(tabIndex);
  };

  return (
    <div className="flex justify-center">
      <button
        className={`${
          activeTab === 1 ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
        } px-4 py-2 mx-2 rounded-tl-lg focus:outline-none`}
        onClick={() => handleTabClick(1)}
      >
        Pay In
      </button>
      <button
        className={`${
          activeTab === 2 ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
        } px-4 py-2 mx-2 focus:outline-none`}
        onClick={() => handleTabClick(2)}
      >
         Pay Out
      </button>
      <button
        className={`${
          activeTab === 3 ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
        } px-4 py-2 mx-2 rounded-tr-lg focus:outline-none`}
        onClick={() => handleTabClick(3)}
      >
        Debit Presentation
      </button>
    </div>
  );
};

export default HeaderTransaction;
