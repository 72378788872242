import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MainPayment from "./PaymentRoutes";
import Footer from "../Footer";
import Contracting from "./Contracting";

export default function Payment() {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [userVerfiyAll, setUserVerfiyAll] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token);
  }, []);

  useEffect(() => {
    if (token) {
      try {
        axios
          .get(`${process.env.REACT_APP_API_KEY}/v1/auth/me`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log("Payment , ", response);
            setUserDetails(response.data);
            setUserVerfiyAll(response.data.VerifyAll);
          })
          .catch((error) => {
            console.error("Error fetching user details:", error);
            setUserDetails(null);
          });
      } catch (error) {
        console.error("Error decoding token:", error);
        setUserDetails(null);
      }
    }
  }, [token, navigate]);

  if (!token) {
    return (
      <div className="bg-blue-950">
        <div className="p-6 bg-slate-950">
          <div className="p-7">
            <h1 className="text-3xl xl:text-5xl text-white font-medium">
              Active Credit
            </h1>
            <p className="text-white p-1">
              Congratulations! You're one step closer to financial freedom.
              Complete your payment credit and enjoy the peace of mind that
              comes with being debt-free.
            </p>
          </div>
        </div>
        <button
          onClick={() => navigate("/")}
          className="mt-4 px-4 py-2 bg-orange-500 text-white rounded"
        >
          Go to Home
        </button>
        <svg
          className="z-[1] bottom-0 left-0"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 230"
        >
          <path
            fill="#030712"
            fillOpacity="1"
            d="M0,0L40,42.7C80,85,160,171,240,197.3C320,224,400,192,480,154.7C560,117,640,75,720,74.7C800,75,880,117,960,154.7C1040,192,1120,224,1200,213.3C1280,203,1360,149,1400,122.7L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
          ></path>
        </svg>
        <Footer />
      </div>
    );
  }

  if (userDetails) {
    const { PaymentAccess, RejectedProfile, signzySent } =
      userDetails.VerifyAll || {};

    if (PaymentAccess) {
      if (!signzySent) {
        return (
          <div className="bg-blue-950">
            <div className="p-6 h-[50vh] flex items-center justify-center bg-slate-950">
              <div className="p-7">
                <Contracting />
              </div>
              <button
                onClick={() => navigate("/")}
                className="mt-4 px-4 py-2 bg-orange-500 text-white rounded"
              >
                Go to Home
              </button>
            </div>
            <Footer />
          </div>
        );
      }
    }

    if (!PaymentAccess && !RejectedProfile) {
      return (
        <div className="bg-blue-950">
          <div className="p-6 h-[50vh] flex items-center justify-center bg-slate-950">
            <div className="p-7">
              <h1 className="text-3xl xl:text-5xl text-white font-medium">
                Payment Access Pending
              </h1>
              <p className="text-white p-1">
                Your payment access is currently pending. Please wait for the
                approval.
              </p>
            </div>
            <button
              onClick={() => navigate("/")}
              className="mt-4 px-4 py-2 bg-orange-500 text-white rounded"
            >
              Go to Home
            </button>
          </div>
          <Footer />
        </div>
      );
    }

    if (RejectedProfile) {
      return (
        <div className="bg-blue-950">
          <div className="p-6 bg-slate-950">
            <div className="p-7">
              <h1 className="text-3xl xl:text-5xl text-red-600  font-bold">
                Account Rejected
              </h1>
              <p className="text-white p-1">
                Unfortunately, your account has been rejected. Please contact
                support for more information.
              </p>
            </div>
            <button
              onClick={() => navigate("/")}
              className="mt-4 px-4 py-2 bg-orange-500 text-white rounded"
            >
              Go to Home
            </button>
          </div>
          <Footer />
        </div>
      );
    }

    if (PaymentAccess && signzySent) {
      return (
        <div className="bg-blue-950">
          <div className="p-6 bg-slate-950">
            <div className="p-7">
              <h1 className="text-3xl xl:text-5xl text-white font-medium">
                Payment Access Granted
              </h1>
              <p className="text-white p-1">
                You have been granted access to complete your payment.
              </p>
            </div>
          </div>
          <div id="wrapper">
            <MainPayment
              token={token}
              userAccount={userDetails}
              userVerfiyAll={userVerfiyAll}
            />
          </div>
          <svg
            className="z-[1] bottom-0 left-0"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 230"
          >
            <path
              fill="#030712"
              fillOpacity="1"
              d="M0,0L40,42.7C80,85,160,171,240,197.3C320,224,400,192,480,154.7C560,117,640,75,720,74.7C800,75,880,117,960,154.7C1040,192,1120,224,1200,213.3C1280,203,1360,149,1400,122.7L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
            ></path>
          </svg>
          <Footer />
        </div>
      );
    }
  }

  return null;
}
