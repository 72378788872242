import { useCallback, useState, useEffect } from "react";
import { BsFillCaretDownFill } from "react-icons/bs";
import BlurBack from "../Props/BlurBack";
import { motion } from "framer-motion";
import Avatar from "@mui/material/Avatar";
import { NavLink, useNavigate } from "react-router-dom";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { CreditCard } from "@mui/icons-material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import axios from "axios";
import profilePic from "../../core/images/profileWithoutLive.png";
import AvatarLivePhoto from "../Props/AvatarLivePhot";
const NavMenu = ({ ChangeView }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState("");
  const navigate = useNavigate();
  const toggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const token = localStorage.getItem("token");

  const dropdownVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };

  const iconVariants = {
    initial: { opacity: 0, y: -50 },
    animate: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeOut" },
    },
  };

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/v2/contracting/verify-all`,
        {},
        { headers }
      )
      .then((response) => {
        setShow(response.data.VerifyAllData);
        // console.logo("Data>>>>>>>>>>>>" , response);
      })
      .catch((error) => {
        console.error("Error sending POST request:", error);
      });
  }, []);

  return (
    <>
      <div className="relative bg-brand rounded-2xl z-30">
        <motion.div
          whileHover={{ scale: 1.05 }}
          variants={iconVariants}
          initial="initial"
          animate="animate"
          className="p-2 border-none bg-transparent flex flex-row items-center gap-1 rounded-full cursor-pointer text-white"
          onClick={toggleOpen}
        >
          <div>
            <AvatarLivePhoto />
          </div>
          <BsFillCaretDownFill className="text-slate-800" />
        </motion.div>
        {isOpen && (
          <motion.div
            variants={dropdownVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="absolute bg-white rounded-md shadow-xl border-[1px] border-solid border-[#eee] w-[170px] overflow-hidden right-0 top-[55px] text-sm flex flex-col cursor-pointer"
          >
            <div className="p-4">
              {show?.isAdmin ? (
                <>
                  <NavLink
                    to="/admin"
                    className="transition mb-2 p-2 rounded-lg block text-gray-800 font-semibold hover:bg-[#0083ec] hover:text-white"
                  >
                    <LockOpenIcon /> Admin
                  </NavLink>
                </>
              ) : (
                <></>
              )}
              <NavLink
                to="/dashboard"
                className="transition mb-2 p-2 rounded-lg block text-gray-800 font-semibold hover:bg-[#0083ec] hover:text-white"
              >
                <PersonOutlinedIcon /> Dashboard
              </NavLink>
              <div
                onClick={() => {
                  navigate("/payment");
                }}
                className="transition mb-2 p-2 rounded-lg block text-gray-800 font-semibold hover:bg-[#0083ec] hover:text-white"
              >
                <CreditCard /> Payments
              </div>
              <hr />
              <button
                onClick={() => {
                  localStorage.removeItem("token");
                  window.location.reload();
                  navigate("/");
                  // Assuming you have a navigate function
                }}
                className="transition mt-2 p-2 rounded-lg w-[100%] text-left font-semibold text-white bg-[#0083ec] hover:text-white
              hover:bg-gray-900"
              >
                <LogoutOutlinedIcon /> Logout
              </button>
            </div>
          </motion.div>
        )}
      </div>
      {isOpen ? <BlurBack onClick={toggleOpen} /> : null}
    </>
  );
};

export default NavMenu;
